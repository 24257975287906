import {
  ActionTriggersServer,
  Rule as ActionTriggersRule,
} from '@wix/ambassador-action-triggers-server/http'
import { WixContactsWebapp } from '@wix/ambassador-wix-contacts-webapp/http'
import {
  ExternalFieldMapping,
  MigrateFieldsRequest,
  MigrateFieldsResponse,
} from '@wix/ambassador-wix-contacts-webapp/types'
import Experiments from '@wix/wix-experiments'
import _ from 'lodash'
import { FORMS_APP_DEF_ID } from '../../constants'
import { CustomField, CustomFieldResponse } from '../../constants/field-types'
import { DEFAULT_RESTRICTIONS, GetRestrictions, PremiumRestriction } from '../../constants/premium'
import { ConvertEmailRequest, ConvertEmailResponse } from '../../types/domain-types'
import { parseInstance } from '../../utils/utils'
import { ASCEND_PLAN, MAP_PRODUCT_ID_TO_ASCEND_PLAN } from './constants/ascend'
import { FORMS_SERVERLESS, PREMIUM_STORE_URL } from './constants/external-endpoints'
import { FormsServiceAmbassadorClient } from './formsServiceAmbassadorClient'
import {
  EmailResponse,
  PublishSiteRequest,
  UserEmail,
} from '@wix/ambassador-wix-form-builder-web/types'
import { findOrCreateLabel, listLabels, updateLabel } from '@wix/ambassador-contacts-v4-label/http'
import {
  findOrCreateExtendedField,
  listExtendedFields,
  updateExtendedField,
} from '@wix/ambassador-contacts-v4-extended-field/http'
import { list } from '@wix/ambassador-cashier-pay-v2-payment-method/http'
import { ExtendedField, FieldType } from '@wix/ambassador-contacts-v4-extended-field/types'
import { FedopsLogger } from '@wix/fedops-logger'
import { withFedops } from '../../editor-app/core/decorators'
import { mapRestrictions } from '../../utils/form-restrictions-utils'
import { getRestrictions } from '@wix/ambassador-forms-v2-restrictions/http'
import { getSitesPremiumStatus } from '@wix/ambassador-premium-v1-asset/http'
import { PremiumStatus } from '@wix/ambassador-premium-v1-asset/types'

export const Method = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
}

export const FEATURES = {
  CUSTOM_STEPS: 'custom_steps',
  SUBMISSIONS_PER_MONTH: 'submissions_per_month',
  CUSTOM_FIELDS: 'custom_fields',
  CUSTOMIZABLE_FORMS: 'customizable_forms',
  ACCEPT_PAYMENTS: 'accept_payments_on_form',
  UPLOAD_FIELD: 'file_upload_field',
  DOWNLOAD_FILE: 'file_downloads',
  SIGNATURE_FIELD: 'signature_field',
  EMAILS: 'max_emails_on_form',
  RULES: 'forms_conditional_forms',
}

const ASCEND_PRODUCT_ID = '73988963-5f5f-4f61-b6a1-fd004df31b00'
const callerId = { 'X-Wix-Client-Artifact-Id': 'wix-form-builder' }
export const CONTACT_FORM_ACTIVITY = 'contact/contact-form'
export const SUBSCRIPTION_FORM_ACTIVITY = 'contact/subscription-form'
const WIX_FORMS_ACTIVITY = 'form/form'

const getService = (appInstance) => ({
  contacts: () => {
    const contactsServices = WixContactsWebapp('/wix-contacts-webapp')
    return {
      schema: contactsServices.ContactsSchemaService()({ Authorization: appInstance, ...callerId }),
    }
  },
  actionTriggers: () => {
    const url = '/_api/action-triggers-server'
    return {
      triggers: ActionTriggersServer(url).ActionTriggersIntegratorService()({
        Authorization: appInstance,
        ...callerId,
      }),
      backoffice: ActionTriggersServer(url).AutomationsBackofficeService()({
        Authorization: appInstance,
        ...callerId,
      }),
    }
  },
})

export default class RemoteApi {
  private boundEditorSDK: BoundEditorSDK
  private experiments: Experiments
  private ravenInstance
  private httpClient: IHttpClient
  private fedopsLogger: FedopsLogger
  public formServiceClient: FormsServiceAmbassadorClient

  constructor({
    boundEditorSDK,
    experiments,
    ravenInstance,
    formServiceClient,
    httpClient,
    fedopsLogger,
  }) {
    this.boundEditorSDK = boundEditorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.formServiceClient = formServiceClient
    this.httpClient = httpClient
    this.fedopsLogger = fedopsLogger
  }

  private async _getAppInstance() {
    return this.boundEditorSDK.info.getAppInstance()
  }

  public async getFormsRulesAndExternalRules(externalEvent) {
    const hasConditions = (rule) => {
      const conditions = _.get(rule, 'trigger.condition.allCondition.conditions')
      return conditions && conditions.length
    }
    const appInstance = await this._getAppInstance()

    const res = await getService(appInstance)
      .actionTriggers()
      .triggers.searchRules({ eventType: [externalEvent, WIX_FORMS_ACTIVITY] })

    const rules = res.rules
    const externalRules = rules.filter(
      (role) => _.get(role, 'trigger.activityType') === externalEvent,
    )
    const wixFormsRules = rules.filter(
      (role) => _.get(role, 'trigger.activityType') === WIX_FORMS_ACTIVITY,
    )
    const wixFormsAllForms = wixFormsRules.filter((rule) => !hasConditions(rule))
    const wixFormsSpecificForms = wixFormsRules.filter((rule) => hasConditions(rule))
    return { externalRules, wixFormsAllForms, wixFormsSpecificForms }
  }

  public async getWixFormsRules() {
    const appInstance = await this._getAppInstance()

    const { rules } = await getService(appInstance)
      .actionTriggers()
      .triggers.searchRules({ eventType: ['form/form_specific', WIX_FORMS_ACTIVITY] })

    return rules
  }

  public async migrateRule(ruleId: string, formIds?: string[], schema?: string) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .actionTriggers()
      .backoffice.migrateForms({ formIds, ruleId, schema })
  }

  public async disableRule(rule: ActionTriggersRule) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .actionTriggers()
      .triggers.updateRule({ rule: { ...rule, status: 'suspended' } })
  }

  public async getEmailsById(emailIds = []): Promise<EmailResponse[]> {
    const emailsResponse = await this.formServiceClient.getEmailsByEmailIds(emailIds)

    return emailIds.map((emailId) => {
      const emailFromResponse = _.find(emailsResponse.emails, { emailId })
      return emailFromResponse || { emailId, email: '' }
    })
  }

  public async getSiteUsersData(): Promise<UserEmail[]> {
    return (await this.formServiceClient.getSiteUsers()).emails
  }

  public async insertEmail(email) {
    return this.formServiceClient.addEmail(email)
  }

  private convertIntoDomainLabel(label) {
    return {
      key: label.key,
      type: label.labelType,
      name: label.displayName,
    }
  }

  @withFedops('create-tag')
  public async createTag(tagName): Promise<DomainContactLabel> {
    return this.httpClient
      .request(findOrCreateLabel({ displayName: tagName }))
      .then(({ data: { label } }) => this.convertIntoDomainLabel(label))
  }

  public async updateTag(key, newName): Promise<DomainContactLabel> {
    return this.httpClient
      .request(updateLabel({ label: { key, displayName: newName } }))
      .then(({ data: { label } }) => this.convertIntoDomainLabel(label))
  }

  public async getLabels(): Promise<DomainContactLabel[]> {
    return this.httpClient
      .request(listLabels({}))
      .then(({ data }) => _.map(data.labels, (label) => this.convertIntoDomainLabel(label)))
  }

  private _customFieldToDomain = (field: ExtendedField): CustomFieldResponse => ({
    key: field.key,
    name: field.displayName,
    fieldType: field.dataType,
  })

  public async getCustomFields(): Promise<CustomFieldResponse[]> {
    return this.httpClient
      .request(listExtendedFields({}))
      .then(({ data: { fields } }) =>
        fields
          .filter((field) => field.fieldType === FieldType.USER_DEFINED)
          .map<CustomFieldResponse>((field) => {
            return this._customFieldToDomain(field)
          }),
      )
      .catch(() => [])
  }

  public async migrateFields(request: MigrateFieldsRequest): Promise<ExternalFieldMapping[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.migrateFields(request)
      .then((res: MigrateFieldsResponse) => {
        return res.migrated
      })
      .catch(() => [])
  }

  public async createCustomField(field: CustomField) {
    return this.httpClient
      .request(
        findOrCreateExtendedField({
          displayName: field.name,
          dataType: field.fieldType,
        }),
      )
      .then(({ data: res }) => this._customFieldToDomain(res.field))
  }

  public async updateCustomFieldName(key: string, newName: string): Promise<void> {
    await this.httpClient.request(updateExtendedField({ field: { key, displayName: newName } }))
  }

  public publishSite(data: PublishSiteRequest) {
    return this.formServiceClient.publishSite(data)
  }

  public addContactFormMapping(data) {
    return this.formServiceClient.addContactFormMapping(data)
  }

  public async editDraft(form) {
    try {
      return await this.formServiceClient.editDraft(form)
    } catch {
      return null
    }
  }

  public async getRestrictions(): Promise<{ restrictions: PremiumRestriction }> {
    try {
      const { data } = await this.httpClient.request(getRestrictions({}))
      const restrictions = mapRestrictions(data)
      return { restrictions }
    } catch {
      return { restrictions: DEFAULT_RESTRICTIONS }
    }
  }

  public async getPremiumRestrictions(): Promise<GetRestrictions> {
    const res = await this._platformizedRequest<GetRestrictions>({
      url: FORMS_SERVERLESS.URL,
      endpoint: FORMS_SERVERLESS.ENDPOINT.RESTRICTIONS,
      method: Method.GET,
    })
    return res || null
  }

  public async getCurrentAscendPlan(): Promise<{ ascendPlan: string; isTopPremium: boolean }> {
    const msid = await this.boundEditorSDK.info.getMetaSiteId()
    const endpoint = `offering/${ASCEND_PRODUCT_ID}?msid=${msid}`
    let currentSubscriptionInfo

    try {
      const request = await this._platformizedRequest({
        url: PREMIUM_STORE_URL,
        endpoint,
        method: Method.GET,
      })
      currentSubscriptionInfo = _.get(request, 'currentSubscriptionInfo')
    } catch {
      currentSubscriptionInfo = {}
    }

    const productId = _.get(currentSubscriptionInfo, 'productId')

    const currentPlan = MAP_PRODUCT_ID_TO_ASCEND_PLAN[productId] || ASCEND_PLAN.FREE

    return { ascendPlan: currentPlan, isTopPremium: currentPlan === ASCEND_PLAN.UNLIMITED }
  }

  public async getCurrentPremiumPlan(): Promise<PremiumStatus> {
    const msid = await this.boundEditorSDK.info.getMetaSiteId()
    try {
      const { data } = await this.httpClient.request(getSitesPremiumStatus({ metaSiteIds: [msid] }))
      return data.sitesPremiumStatus.find(({ metaSiteId }) => metaSiteId === msid).premiumStatus
    } catch {
      return PremiumStatus.FREE
    }
  }

  public async getConnectedPayments() {
    const appInstance = await this._getAppInstance()
    const instanceId = parseInstance(appInstance).instanceId
    return this.httpClient
      .request(list({ accountId: `${FORMS_APP_DEF_ID}:${instanceId}` }))
      .then((res) => res?.data)
  }

  public async convertContactFormEmails(
    convertContactFormEmailsRequest: ConvertEmailRequest,
  ): Promise<ConvertEmailResponse> {
    return this.formServiceClient.convertContactFormEmails(convertContactFormEmailsRequest)
  }

  private async _platformizedRequest<T>({
    url,
    endpoint,
    method,
    data = undefined,
  }: {
    url: string
    endpoint: string
    method: string
    data?: any
  }) {
    try {
      const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())

      if (isTemplate) {
        return Promise.resolve()
      }

      const appInstance = await this._getAppInstance()

      const response = await this.httpClient.request<T>({
        headers: { Authorization: appInstance, ...callerId },
        method: method as any,
        url: `${url}/${endpoint}`,
        data,
      })

      return response?.data
    } catch (err) {
      this.ravenInstance.setExtraContext({ url, endpoint, method, data })
      throw err
    }
  }
}
