import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import LoginButton from '../login-button';
import styles from './login-screen.scss';

const LoginScreen = ({
  t,
  children,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  ...props
}) => {
  const className = classNames(
    styles.page,
    'login-screen',
    'blog-card-background-color',
    'blog-card-border-color',
    'blog-text-color',
    contentFontClassName,
  );
  return (
    <div className={className} style={{ borderWidth }}>
      <div className={styles.closeButton}>{children}</div>
      <div className={titleFontClassName}>
        <h1 className={classNames(styles.title, 'login-screen__title')}>
          {t('login-screen.login-required')}
        </h1>
      </div>
      <p
        className={classNames(styles.description, 'login-screen__description')}
      >
        {t('login-screen.login-description')}
      </p>
      <div
        className={classNames(
          styles.loginButtonContainer,
          'login-screen__login-button-container',
        )}
      >
        <LoginButton autoLogin {...props} />
      </div>
    </div>
  );
};

LoginScreen.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

export default withTranslate(
  withCardBorderWidth(withFontClassName(LoginScreen)),
);
