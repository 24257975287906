import CoreApi from '../core-api'
import Experiments from '@wix/wix-experiments'
import _ from 'lodash'
import { fieldsStore } from '../preset/fields/fields-store'
import RemoteApi from '../../../panels/commons/remote-api'

export default class PanelsDataApi {
  private biLogger
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private experiments: Experiments
  private remoteApi: RemoteApi

  constructor(
    boundEditorSDK: BoundEditorSDK,
    coreApi: CoreApi,
    remoteApi: RemoteApi,
    { experiments, biLogger },
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
    this.remoteApi = remoteApi
  }

  public loadSettingsPanelMainTab() {
    return Promise.resolve({
      payload: {},
      ok: true,
    })
  }

  public loadBaseAdiPanelData() {
    return Promise.all([
      this.coreApi.getLocale(),
      this.coreApi.getMetaSiteId(),
      this.coreApi.getUserId(),
      this.coreApi.originEditorType(),
      this.coreApi.getEditorSessionId(),
      this.coreApi.getExperiments(),
    ]).then(([locale, msid, userId, editorType, esi, experiments]) => {
      return {
        locale,
        msid,
        userId,
        editorType,
        esi,
        experiments,
      }
    })
  }

  public async loadAdiPanelData({ componentRef, componentConnection }) {
    return Promise.all<any>([
      this.coreApi.style.getFieldsCommonStylesGlobalDesign(componentRef),
      this.coreApi.fields.getFieldsSortByXY(componentRef),
      this.coreApi.settings.getSubmitButtonLabel(componentRef),
      this.coreApi.settings.getMessage(componentRef),
      this.coreApi.settings.getSubmitOptionsData(componentRef, componentConnection),
      this.coreApi.premium.getRestrictions(),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.coreApi.getLabels(),
      this.coreApi.settings.getForm(componentRef, componentConnection),
      this.coreApi.hasConnectedPayment(),
      this.coreApi.getCurrency(),
    ]).then(
      async ([
        commonStyles,
        fields,
        submitButtonLabel,
        { text: successMessage },
        links,
        { restrictions, currentPlan },
        isCollectionExists,
        labels,
        form,
        hasConnectedPayment,
        currency,
      ]) => {
        const {
          config: { successActionType, preset, plugins, selectedPaymentOption },
        } = componentConnection

        const titleTranslationKeys = _.reduce(
          fieldsStore.all(),
          (acc, fieldPreset) => ({
            ...acc,
            [fieldPreset.fieldType]: _.get(fieldPreset, 'metadata.ADI_titleTranslationKey'),
          }),
          {},
        )

        const formName = form.name

        const formInfo = { formPublicId: form.id }

        const updatedFields = fields.map((field) => ({
          ...field,
          label: field.showLabel ? field.label : '',
        }))

        const paymentData = await this.coreApi.settings.loadPaymentTabData(
          componentRef,
          componentConnection,
          {
            restrictions,
            fieldsOnStage: fields,
          },
        )

        return {
          ...paymentData,
          commonStyles,
          selectedPaymentOption,
          plugins,
          hasConnectedPayment,
          currency,
          fields: updatedFields,
          submitButtonLabel,
          successActionType,
          successMessage,
          links,
          restrictions,
          formName,
          preset,
          isCollectionExists,
          currentPlan,
          titleTranslationKeys,
          labels,
          formPublicId: form.id,
          formRevision: form.revision,
          existingFormNames: await this.remoteApi.formServiceClient.getOtherFormNames({
            publicId: form.id,
          }),
          ...formInfo,
        }
      },
    )
  }
}
