import { mapKeys, startsWith, assign, get } from 'lodash'
import { Maybe } from '@wix/wix-code-adt'
import { FieldType } from '@wix/wix-data-schema-types'
import type {
  ConnectionConfig,
  DynamicProp,
  LiteralProp,
  FieldBindingConfig,
} from '../types'

type GetFieldTypeFn = (fieldName: string) => Maybe<FieldType>

const { richText } = FieldType

const dynamicPropertiesHandlers: Record<
  DynamicProp,
  (fieldType: Maybe<FieldType>) => LiteralProp
> = {
  $text: fieldType =>
    fieldType.map(ft => (ft === richText ? 'html' : 'text')).getOrElse('text'),
}

const resolveDynamicProperty = (
  propName: DynamicProp,
  binding: FieldBindingConfig,
  getFieldType: GetFieldTypeFn,
) =>
  Maybe.fromNullable(dynamicPropertiesHandlers[propName])
    .map(propHandler => propHandler(getFieldType(binding.fieldName)))
    .getOrElse(propName)

const resolveDynamicProperties = (
  connectionConfig: ConnectionConfig,
  getFieldType: GetFieldTypeFn,
): ConnectionConfig =>
  Maybe.fromNullable(get(connectionConfig, 'properties'))
    .map(properties =>
      mapKeys(properties, (property, propName) =>
        startsWith(propName, '$')
          ? resolveDynamicProperty(
              propName as DynamicProp,
              property,
              getFieldType,
            )
          : propName,
      ),
    )
    .map(
      properties =>
        assign({}, connectionConfig, { properties }) as ConnectionConfig,
    )
    .getOrElse(connectionConfig)

export { resolveDynamicProperties }
