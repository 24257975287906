import { flowRight } from 'lodash';
import {
  SECTION_HOMEPAGE,
  isLayoutNameTextOnImage,
  getLayoutName,
} from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';
import { getAppSettingsNumber } from '../selectors/app-settings-base-selectors';
import { getUseMobileDesignSettings } from '../selectors/app-settings-selectors';
import { getLayoutType } from '../selectors/layout-selectors';
import { getSection } from '../selectors/section-selectors';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import withCardBorderWidth from './with-card-border-width';
import withHocName from './with-hoc-name';
import withIsFeedDesignEnabled from './with-is-feed-design-enabled';

const mapRuntimeToProps = (
  state,
  { borderWidth: cardBorderWidth, applyFeedDesign },
) => {
  const section = getSection(state) || SECTION_HOMEPAGE;
  const layoutName = getLayoutName(
    getLayoutType(state, section, getIsMobile(state)),
  );
  const useMobileStyle = getUseMobileDesignSettings(state);
  const feedBorderWidth = isLayoutNameTextOnImage(layoutName)
    ? 0
    : getAppSettingsNumber(
        state,
        useMobileStyle
          ? `post-${section}-mobile-borderWidth`
          : `post-${section}-borderWidth`,
      );

  return { borderWidth: applyFeedDesign ? feedBorderWidth : cardBorderWidth };
};

export default flowRight(
  withCardBorderWidth,
  withIsFeedDesignEnabled,
  connect(mapRuntimeToProps),
  withHocName('WithFeedBorderWidth'),
);
