import { getDateLocale, getDateFormat, getFullDateFormat, } from '../constants/time';
import dateLib from '../helpers/date-lib-wrapper';
import createFormatNumber from './create-format-number';
var I18N_NESTING_KEY_SEPARATOR = '$';
var getI18Config = function () { return ({
    keySeparator: I18N_NESTING_KEY_SEPARATOR,
    interpolation: {
        escapeValue: false,
        format: function (value, format, lng) {
            if (lng === void 0) { lng = 'en'; }
            try {
                switch (format) {
                    case 'uppercase':
                        return value.toUpperCase();
                    case 'lowercase':
                        return value.toLowerCase();
                    case 'number':
                        return createFormatNumber(lng)(value);
                    case 'timeRelative':
                        return dateLib(value).locale(getDateLocale(lng)).fromNow();
                    case 'date':
                        return dateLib(value)
                            .locale(getDateLocale(lng))
                            .format(getDateFormat(lng));
                    case 'fullDate':
                        return dateLib(value)
                            .locale(getDateLocale(lng))
                            .format(getFullDateFormat(lng));
                    case 'hoursAndMinutes':
                        return dateLib(value).locale(getDateLocale(lng)).format('LT');
                    case 'monthAndYearUtc':
                        return dateLib(value)
                            .utc()
                            .locale(getDateLocale(lng))
                            .format('MMMM YYYY');
                    default:
                        return value;
                }
            }
            catch (err) {
                console.error("Translation Error: Failed to format value ".concat(value, " to format ").concat(format, " in ").concat(lng, " language. This might occur because of typo in translation variable"));
                return value;
            }
        },
    },
}); };
export default getI18Config;
