import { FedopsLogger } from '@wix/fedops-logger'
import {
  BASE_DESIGN_GROUPS,
  BillingPanelReferrer,
  COMPONENT_TYPES,
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FormsFieldPreset,
  getCurrencyByKey,
  OptionType,
  paymentMappingToRadioOptions,
  UpgradeAlertType,
} from '@wix/forms-common'
import { GridItemPosition } from '@wix/platform-editor-sdk'
import Experiments from '@wix/wix-experiments'
import _ from 'lodash'
import hiddenLimitMessageStructure from '../../../assets/presets/hidden-limit-message.json'
import * as hiddenMessageStructure from '../../../assets/presets/hidden-message.json'
import * as registrationLoginLinkStructure from '../../../assets/presets/login-link.json'
import * as signupButtonStructure from '../../../assets/presets/signup-button.json'
import * as submitButtonStructure from '../../../assets/presets/submit-button.json'
import { FORMS_APP_DEF_ID } from '../../../constants'
import { EVENTS } from '../../../constants/bi'
import { CRM_TYPES, CUSTOM_FIELD } from '../../../constants/crm-types-tags'
import {
  CRM_LABEL_MAX_LENGTH,
  CustomField,
  CustomFieldResponse,
  Field,
} from '../../../constants/field-types'
import { FormPreset } from '../../../constants/form-types'
import { PanelName } from '../../../constants/panel-names'
import {
  AUTOFILL_MEMBER_EMAIL_ROLE,
  FIELDS,
  FIELDS_ROLES,
  FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD,
  LIMIT_SUBMISSIONS_STEP_ROLE,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_LIMIT_MESSAGE,
  ROLE_MESSAGE,
  ROLE_NEXT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_SUBMIT_BUTTON,
  THANK_YOU_STEP_ROLE,
} from '../../../constants/roles'
import { DEFAULT_VISUAL_CATEGORIES_V2 } from '../../../panels/add-field-panel/constants/order'
import { getFieldName } from '../../../panels/adi-panel/utils'
import { EXPERIMENTS } from '../../../panels/commons/constants/experiments'
import { ConnectFieldPanelState } from '../../../panels/connect-field-panel/reducer'
import { getFieldWithNonPrimaryRole } from '../../../panels/form-settings-panel/components/settings/autofill-form-info/utils'
import {
  CATEGORIES,
  REGISTRATION_FORM_CATEGORY,
} from '../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { PAYMENT_OPTIONS } from '../../../panels/payment-wizard-panel/constants'
import translations from '../../../utils/translations'
import { createSuffixedName } from '../../../utils/utils'
import { filterFieldsToCollection } from '../collections/utils'
import {
  isCountriesCodesKeys,
  isInnerComplexPhoneField,
  getCountryCodeValueByGEO,
} from '../complex-phone/utils'
import CoreApi from '../core-api'
import { absorbException, undoable, withBi, withFedops, withSync } from '../decorators'
import {
  calcUpdatesForStackFieldsByNewOrder,
  createItemLayout,
  getItemLayoutData,
  getItemLayoutType,
  requestEmptyCellInGrid,
} from '../layout/responsive-utils'
import { convertPluginsToFormsPlugins } from '../plugins/utils'
import { getCountryCodeByGEO } from '../preset/fields/complex-fields/complex-phone/utils'
import {
  allowCollectionSync,
  FieldExtraData,
  FieldProperties,
  getFieldRenderConfigFields,
} from '../preset/fields/field-types-data'
import { fieldsStore, fieldsTypes } from '../preset/fields/fields-store'
import { makeGeneralRadioGroupOption } from '../preset/fields/general-fields/definitions/general-radio-group'
import { getFormPreset } from '../preset/preset-service'
import {
  connectComponent,
  createAndConnectInnerComplexField,
  createComplexField,
  createField,
  fetchHiddenMessage,
  fetchHiddenMessageAndReplaceRole,
  fetchLoginLinkSchema,
  fetchSubmitButtonSchema,
  getMessageSchema,
} from '../services/form-service'
import { CommonStyles, htmlTextFromStyle } from '../services/form-style-service'
import {
  getPrimaryConnection,
  getPrimaryConnectionFromStructure,
  isAnyField,
  isComplexComponent,
  isComplexController,
  isComplexInnerField,
  isInputField,
} from '../utils'
import { ADD_FIELD_FLOW } from './constants'
import { GROUP_COMPONENT, MOBILE_CONTAINER } from './constants/container-types'
import {
  createFieldsDataForCollectionActions,
  getAllCrmLabels,
  getAutofillMemberEmailConnection,
  getConfigFromStructure,
  getDefaultFieldName,
  getDefaultLabel,
  getDuplicatedFieldsConfig,
  getFieldMainRole,
  getFieldsLeft,
} from './utils'

// TODO: Move to registration plugin api
const ROLE_LINK_TO_LOGIN = FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG

const CRUCIAL_ROLES = [
  ROLE_SUBMIT_BUTTON,
  ROLE_LINK_TO_LOGIN,
  ROLE_MESSAGE,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_LIMIT_MESSAGE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
]

const COMPONENTS_TO_REPOSITION_AFTER_ADD_FIELD = [
  FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
  ROLE_SUBMIT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  ROLE_MESSAGE,
  ROLE_LIMIT_MESSAGE,
]

export const SPACE_BETWEEN_FIELDS = 32

export default class FieldSettingsApi {
  private biLogger: any
  private fedopsLogger: FedopsLogger
  private boundEditorSDK: BoundEditorSDK
  private remoteApi: any
  private experiments: Experiments

  protected ravenInstance
  protected coreApi: CoreApi

  constructor(
    boundEditorSDK,
    coreApi: CoreApi,
    remoteApi,
    { biLogger, ravenInstance, experiments, fedopsLogger },
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.fedopsLogger = fedopsLogger
    this.remoteApi = remoteApi
    this.ravenInstance = ravenInstance
    this.experiments = experiments
  }

  private async _getCategories(plugins: FormPlugin[]): Promise<string[]> {
    const categories: string[] = DEFAULT_VISUAL_CATEGORIES_V2
    const isRegistrationForm = _.includes(plugins, FormPlugin.REGISTRATION_FORM)
    return isRegistrationForm ? [REGISTRATION_FORM_CATEGORY, ...categories] : categories
  }

  private _getCustomFields(customFields: CustomFieldResponse[]) {
    return _.map(customFields, ({ key, name, fieldType }) => ({
      value: FormsFieldPreset[`CRM_${fieldType}`],
      name,
      customFieldKey: key,
      category: CATEGORIES.contact,
      crmType: CUSTOM_FIELD,
    }))
  }

  private _getGeneralFields(plugins: FormPlugin[]): Field[] {
    const fields = _.map(fieldsTypes(fieldsStore.generalFields), (fieldType: FieldPreset) => {
      const {
        isPremium,
        dependsOn,
        hideTranslationPostfix,
        showTooltip,
        category,
        subCategory,
        isNew,
      } = getFieldRenderConfigFields(plugins, fieldType).addFieldPanelData

      const mappedField: Field = {
        value: fieldType,
        name: hideTranslationPostfix
          ? translations.t(`fieldTypes.${fieldType}`)
          : translations.t(`fieldTypes.generalField`, {
              name: translations.t(`fieldTypes.${fieldType}`),
            }),
        isPremium,
        dependsOn,
        subCategory,
        category,
        isNew,
      }

      if (showTooltip) {
        mappedField.tooltip = translations.t(`fieldTypes.${fieldType}.tooltip`)
      }

      return mappedField
    })

    // console.log('fieldsStore.recommendedFields', fieldsStore.recommendedFields)
    const validRecommendedFields = _.filter(fieldsStore.recommendedFields, (field) => {
      if (field.deprecated) {
        return false
      }
      return true
    })
    // console.log('validRecommendedFields', validRecommendedFields)

    const recommendedFields: Field[] = fieldsTypes(validRecommendedFields).map(
      (fieldType: FieldPreset) => {
        const { isNew } = getFieldRenderConfigFields(plugins, fieldType).addFieldPanelData
        return {
          value: fieldType,
          name: translations.t(`fieldTypes.${fieldType}`),
          category: CATEGORIES.contact,
          customFieldKey: undefined,
          crmType: fieldsStore.get(fieldType).crmType,
          isNew,
        }
      },
    )

    return [...fields, ...recommendedFields]
  }

  private async _getNewFields(
    formComponentRef: ComponentRef,
    customFields,
    plugins: FormPlugin[],
  ): Promise<Field[]> {
    const newGeneralFields = this._getGeneralFields(plugins)
    const newCustomFields = this._getCustomFields(customFields)

    const newExtraFields = await this.coreApi.formsExtendApi({
      formComponentRef,
      apiPath: 'fields.getNewFields',
    })

    const fields: Field[] = [...newGeneralFields, ...newCustomFields, ...newExtraFields.add]

    return _.filter(fields, (field) => !_.includes(newExtraFields.remove, field.value))
  }

  private _getFilteredFieldsAndCategories(fields: Field[], allCategories: string[]) {
    if (!this.coreApi.isResponsive()) {
      return { filteredFields: fields, filteredCategories: allCategories }
    }

    const categoriesWithFields = {}

    const filteredFields = _.filter(fields, (field) => {
      if (fieldsStore.get(field.value).supportedInResponsive) {
        categoriesWithFields[field.category] = true

        if (field.subCategory) {
          categoriesWithFields[field.subCategory] = true
        }

        return true
      }
    })

    const categoriesNames = _.keys(categoriesWithFields)
    const filteredCategories = _.intersection(allCategories, categoriesNames)

    return { filteredFields, filteredCategories }
  }

  private _getLayoutOverrides(formComponentRef: ComponentRef) {
    return this.coreApi.formsExtendApi({
      formComponentRef,
      apiPath: 'fields.getAddFieldLayoutOverrides',
    })
  }

  public async loadInitialPanelData({
    componentRef,
    preset,
    plugins,
    displayedTab,
  }: {
    componentRef: ComponentRef
    preset: string
    plugins: FormPlugin[]
    displayedTab?: string
  }) {
    return Promise.all([
      this._getCategories(plugins),
      this.getCustomFields(),
      this.getFieldsSortByXY(componentRef), // TODO: We don't need XY data, we just need getFields (less SDK calls) - FYI: addField returns FormField structure (getField) and not XY data (although different type, we don't use it). Change when this panel will be changed by product (talk to Matvey about this change)
      this.coreApi.style.getFieldsCommonStylesGlobalDesign(componentRef),
      this.coreApi.premium.getRestrictions(),
      this.coreApi.getComponentConnection(componentRef),
      this._getLayoutOverrides(componentRef),
    ]).then(
      ([
        allCategories,
        customFields,
        fieldsOnStage,
        commonStyles,
        { restrictions, currentPlan },
        formComponentConnection,
        layoutOverrides,
      ]) =>
        this._getNewFields(componentRef, customFields, plugins).then(async (fields) => {
          const isRegistrationForm = _.includes(plugins, FormPlugin.REGISTRATION_FORM)
          const selectedTab = isRegistrationForm ? REGISTRATION_FORM_CATEGORY : CATEGORIES.contact

          const { filteredFields, filteredCategories } = this._getFilteredFieldsAndCategories(
            fields,
            allCategories,
          )

          return {
            preset,
            fieldsOnStage,
            commonStyles,
            restrictions,
            currentPlan,
            plugins,
            formComponentConfig: formComponentConnection.config,
            appDefId: FORMS_APP_DEF_ID,
            categories: filteredCategories,
            fields: filteredFields,
            showIntroForCategories: [
              CATEGORIES.recommended,
              CATEGORIES.contact,
              REGISTRATION_FORM_CATEGORY,
            ],
            layoutOverrides,
            selectedTab: _.includes(filteredCategories, displayedTab) ? displayedTab : selectedTab,
          }
        }),
    )
  }

  public async loadInitialConnectFieldData(
    fieldComponentRef: ComponentRef,
    componentConnection: ComponentConnection,
  ): Promise<Partial<ConnectFieldPanelState>> {
    return Promise.all([
      this.getCustomFields(),
      this.getFieldsSortByXY(fieldComponentRef),
      this.coreApi.getFormConfigData(fieldComponentRef),
      this.coreApi.premium.getRestrictions(),
      this.coreApi.fetchAppConfig({
        formComponentRef: await this.coreApi.findComponentByRole(fieldComponentRef, ROLE_FORM),
      }),
      this.coreApi.get(componentConnection.controllerRef, 'rules'),
    ]).then((payload: any) => {
      const [
        customFields,
        fields,
        { plugins, preset },
        { restrictions },
        appConfig,
        rules,
      ] = payload

      const field = fields.find((f) => f.componentRef.id === fieldComponentRef.id)
      return {
        componentRef: fieldComponentRef,
        fields,
        customFields: customFields || [],
        plugins,
        preset,
        fieldType: field.fieldType,
        crmLabel: field.crmLabel,
        lastValidCrmLabel: field.crmLabel,
        crmType: field.crmType,
        crmTag: field.crmTag,
        customFieldKey: field.customFieldKey,
        isCustomField: field.crmType === CUSTOM_FIELD,
        otherFieldsNames: _.pull(getAllCrmLabels(fields), field.crmLabel),
        // contact sync new panel
        customFieldName: field.customFieldName,
        componentType: field.componentType,
        appConfig,
        rules: rules || [],
        restrictions,
      }
    })
  }

  private _sumOffsetsWithMap(containers: ComponentRef[], componentsLayoutMap) {
    const containersOffset = containers.reduce(
      (offsetAccumulator, currentValue) => {
        const containerLayout = componentsLayoutMap[currentValue.id].layout

        return {
          x: offsetAccumulator.x + containerLayout.x,
          y: offsetAccumulator.y + containerLayout.y,
        }
      },
      { x: 0, y: 0 },
    )

    return containersOffset
  }

  public async getRawFields(componentRef: ComponentRef): Promise<ComponentRef[]> {
    const controllerRef = await this.coreApi.getFormControllerRef(componentRef)

    return this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
  }

  private _enrichFieldsData(field, allFieldsWithAncestorsById, allComponentsById) {
    const fieldData = allFieldsWithAncestorsById[field.componentRef.id]
    let ancestors = []
    if (fieldData) {
      ancestors = fieldData.ancestors
    }

    const parentContainers = ancestors.filter((ancestor) =>
      [MOBILE_CONTAINER, GROUP_COMPONENT].some((type) => type === ancestor.componentType),
    )

    const fieldLayout = _.clone(allComponentsById[field.componentRef.id].layout) || {
      x: 0,
      y: 0,
      height: 0,
      width: 0,
    }

    if (parentContainers.length > 0) {
      const containersOffset = this._sumOffsetsWithMap(
        parentContainers.map((container) => container.componentRef),
        allComponentsById,
      )

      fieldLayout.x += containersOffset.x
      fieldLayout.y += containersOffset.y
    }

    const { x, y, height, width } = fieldLayout
    const layoutResponsive = allComponentsById[field.componentRef.id].layoutResponsive || {}

    return _.merge(
      { x, y, height, width },
      field,
      { layoutResponsive },
      {
        parentComponentRef: _.get(
          allFieldsWithAncestorsById[field.componentRef.id],
          'ancestors[0].componentRef',
        ),
      },
    )
  }

  private async _getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false },
  ): Promise<FormField[]> {
    const childrenRefs = await this.getRawFields(componentRef)

    const fields = await this._getFields(
      childrenRefs.filter((x) => !!x),
      allFieldsTypes,
    )

    const fieldsRefs = fields.map((f) => f.componentRef)

    const fieldsWithAncestors: {
      componentRef: ComponentRef
      ancestors: ComponentRef[]
    }[] = await Promise.all(
      fieldsRefs.map(async (compRef) => {
        const ancestors = await this.boundEditorSDK.components.getAncestors({
          componentRef: compRef,
        })
        return {
          componentRef: compRef,
          ancestors,
        }
      }),
    )

    const flattenAncestorsRefs = _.flatten(fieldsWithAncestors.map(({ ancestors }) => ancestors))
    const uniqueAncestorsRefs = _.uniqBy(flattenAncestorsRefs, ({ id }) => id)
    const childComponentsRefs = _.flatMap(fields, 'childFields').map((f) => f.componentRef)
    const allComponentsRefs = [...fieldsRefs, ...uniqueAncestorsRefs, ...childComponentsRefs]

    const allComponentsLayout = await this.boundEditorSDK.components.get({
      componentRefs: allComponentsRefs,
      properties: ['layout', 'layoutResponsive'],
    })

    const uniqueAncestorsWithTypes = await this.boundEditorSDK.components.get({
      componentRefs: uniqueAncestorsRefs,
      properties: ['componentType'],
    })

    const fieldsWithAncestorsWithTypes = fieldsWithAncestors.map(
      ({ componentRef: compRef, ancestors }) => ({
        componentRef: compRef,
        ancestors: ancestors.map((ancestorComponentRef) =>
          uniqueAncestorsWithTypes.find(
            (ancestorWithType) => ancestorWithType.componentRef.id === ancestorComponentRef.id,
          ),
        ),
      }),
    )

    const allComponentsById = _.keyBy(allComponentsLayout, 'componentRef.id')

    const allFieldsWithAncestorsById = _.keyBy(fieldsWithAncestorsWithTypes, 'componentRef.id')

    const enrichedFields = fields.map((field) => {
      return this._enrichFieldsData(field, allFieldsWithAncestorsById, allComponentsById)
    })

    enrichedFields
      .filter((f) => f.childFields.length)
      .forEach((fieldWithChild) => {
        fieldWithChild.childFields = fieldWithChild.childFields.map((child) => {
          return this._enrichFieldsData(child, allFieldsWithAncestorsById, allComponentsById)
        })
        fieldWithChild.childFields = _.sortBy(fieldWithChild.childFields, ['y', 'x'])
      })

    const sortedFields = await this.sortFields({
      componentRef,
      fields: enrichedFields,
      ancestorsMap: allFieldsWithAncestorsById,
    })

    return sortedFields.map((field) => {
      const fieldLayout = allComponentsById[field.componentRef.id].layout || {
        x: 0,
        y: 0,
      }
      return { ...field, x: fieldLayout.x, y: fieldLayout.y }
    })
  }

  public async sortFields({ componentRef, fields, ancestorsMap }) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins = _.get(componentConnection, 'config.plugins')
    const isMultiStepForm = !!_.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })

    if (this.coreApi.isResponsive()) {
      return _.sortBy(fields, (field) => {
        const itemData = getItemLayoutData(field.layoutResponsive)
        return !_.isUndefined(itemData.order)
          ? itemData.order
          : [itemData.gridArea.rowEnd, itemData.gridArea.columnEnd]
      })
    } else if (isMultiStepForm) {
      const stepsData: StepData[] = await this.coreApi.steps.getSteps(componentRef)

      const mapFieldContainerToIndex = (ancestors) => {
        const stepContainer = _.find(
          ancestors,
          (ancestor) => ancestor.componentType === COMPONENT_TYPES.FORM_STATE,
        )

        if (!stepContainer) {
          return 0
        }

        return _.findIndex(stepsData, (stepData) =>
          _.isEqual(stepData.componentRef, stepContainer.componentRef),
        )
      }

      const fieldsWithContainerIndex = fields.map((field) => {
        const fieldContainerIndex = mapFieldContainerToIndex(
          ancestorsMap[field.componentRef.id].ancestors,
        )
        return _.merge({}, { fieldContainerIndex }, field)
      })

      return _.sortBy(fieldsWithContainerIndex, ['fieldContainerIndex', 'y', 'x'])
    } else {
      return _.sortBy(fields, ['y', 'x'])
    }
  }

  public getContainerFields(componentRef: ComponentRef): Promise<ComponentRef[]> {
    return this.coreApi.findChildComponentsByRole(componentRef, FIELDS_ROLES)
  }

  public async getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false },
  ): Promise<FormField[]> {
    return this._getFieldsSortByXY(componentRef, { allFieldsTypes })
  }

  private async _normalizeCrmLabel(
    currentFieldRef: ComponentRef,
    crmLabel: string,
    crmLabelOverrides: { componentRef: string; crmLabel: string }[] = [],
  ): Promise<string> {
    const allFieldRefs = await this.getRawFields(currentFieldRef)
    const otherFieldRefs = _.filter(
      allFieldRefs,
      (fieldRef) => !_.isEqual(fieldRef, currentFieldRef),
    )

    const otherCrmLabels = await Promise.all(
      otherFieldRefs.map(async (fieldRef) => {
        const crmLabelOverride = _.find(crmLabelOverrides, (field) =>
          _.isEqual(field.componentRef, fieldRef),
        )
        if (crmLabelOverride) {
          return crmLabelOverride.crmLabel
        }

        const fieldConnection = await this.coreApi.getComponentConnection(fieldRef)
        if (isInnerComplexPhoneField(_.get(fieldConnection, 'role'))) {
          return undefined
        }
        return _.get(fieldConnection, 'config.crmLabel')
      }),
    )

    return createSuffixedName(otherCrmLabels, crmLabel)
  }

  public async getMainRoleField(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<ComponentRef> {
    const connection = await this.coreApi.getComponentConnection(componentRef, componentConnection)
    const fieldType = _.get(connection, 'config.fieldType')
    const mainRole = getFieldMainRole(fieldType)
    const field = await this.coreApi.findConnectedComponent(componentRef, mainRole)
    return field?.ref
  }

  private async _getCrmLabel(
    componentRef: ComponentRef,
    fieldType: FormsFieldPreset,
    { previousFieldData, currentFieldData },
    crmLabelOverrides?: { componentRef: string; crmLabel: string }[],
  ): Promise<string | void> {
    const previousDefaultLabel = fieldType && getDefaultLabel({ ...previousFieldData, fieldType })
    const currentDefaultLabel = fieldType && getDefaultLabel({ ...currentFieldData, fieldType })
    const newCrmLabel = currentDefaultLabel || currentFieldData.type

    if (_.isEqual(previousDefaultLabel, currentDefaultLabel) || !newCrmLabel) {
      return Promise.resolve()
    }

    return this._normalizeCrmLabel(componentRef, newCrmLabel, crmLabelOverrides)
  }

  private async _getFieldInfo(componentRef: ComponentRef): Promise<{
    fieldType: FormsFieldPreset
    currentFieldData: ComponentData
    currentFieldProps: any
  }> {
    const [connection, { data: currentFieldData, props: currentFieldProps }] = await Promise.all([
      this.coreApi.getComponentConnection(componentRef),
      this._getFieldPropertiesAndData(componentRef),
    ])
    const fieldType = _.get(connection, 'config.fieldType')
    return { fieldType, currentFieldData, currentFieldProps }
  }

  public async getAndUpdateInnerCrmLabels(
    previousFields: { componentRef: ComponentRef; data: ComponentData }[],
  ): Promise<void> {
    const allFieldsInfo = await Promise.all(
      previousFields.map(async ({ componentRef, data }) => {
        const { fieldType, currentFieldData } = await this._getFieldInfo(componentRef)
        return {
          componentRef,
          previousFieldData: data,
          currentFieldData,
          fieldType,
        }
      }),
    )

    const newCrmLabels = []
    await allFieldsInfo.reduce(async (previousPromise, fieldInfo) => {
      await previousPromise
      const { componentRef, previousFieldData, currentFieldData, fieldType } = fieldInfo

      const crmLabel = (
        (await this._getCrmLabel(
          componentRef,
          fieldType,
          {
            previousFieldData,
            currentFieldData,
          },
          newCrmLabels,
        )) || ''
      ).substring(0, CRM_LABEL_MAX_LENGTH)

      if (crmLabel) {
        newCrmLabels.push({
          componentRef,
          crmLabel,
        })
      }
    }, Promise.resolve())

    if (!newCrmLabels.length) {
      return Promise.resolve()
    }

    const updatedFields = await Promise.all(
      newCrmLabels.map(async ({ crmLabel, componentRef }) => {
        const [
          {
            config: { collectionFieldKey },
            controllerRef,
          },
        ] = await Promise.all([
          this.coreApi.getComponentConnection(componentRef),
          this.coreApi.setComponentConnection(componentRef, { crmLabel }),
        ])

        return {
          collectionFieldKey,
          controllerRef,
          componentRef,
          crmLabel,
        }
      }),
    )

    const updateCollection = async () => {
      const collectionId = await this._getCollectionId(_.first(updatedFields).controllerRef)
      if (!collectionId) {
        return
      }
      return this.coreApi.collectionsApi.updateFields(collectionId, updatedFields)
    }
    return updateCollection()
  }

  public async getAndUpdateCrmLabel(componentRef: ComponentRef, previousFieldData): Promise<void> {
    const { fieldType, currentFieldData } = await this._getFieldInfo(componentRef)

    const mainRole = getFieldMainRole(fieldType)
    if (mainRole) {
      const innerComponent = await this.coreApi.findConnectedComponent(componentRef, mainRole)
      if (!innerComponent) {
        return
      }
      const { fieldType: innerFieldType, currentFieldData: innerFieldData, } =
        await this._getFieldInfo(innerComponent.ref)
      return this._getAndUpdateCrmLabel(componentRef, innerFieldType, {
        previousFieldData,
        currentFieldData: innerFieldData,
      })
    } else {
      return this._getAndUpdateCrmLabel(componentRef, fieldType, {
        previousFieldData,
        currentFieldData,
      })
    }
  }

  private async _getAndUpdateCrmLabel(
    componentRef: ComponentRef,
    fieldType: FormsFieldPreset,
    { previousFieldData, currentFieldData },
  ) {
    const suffixedNewCrmLabel = await this._getCrmLabel(componentRef, fieldType, {
      previousFieldData,
      currentFieldData,
    })
    if (!suffixedNewCrmLabel) {
      return Promise.resolve()
    }
    return this.updateCrmLabel(componentRef, suffixedNewCrmLabel)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async updateCrmLabel(componentRef: ComponentRef, crmLabel: string, _biData = {}) {
    return this._updateCrmLabel(componentRef, crmLabel)
  }

  private async _updateCrmLabel(componentRef: ComponentRef, crmLabel: string) {
    crmLabel = crmLabel.substring(0, CRM_LABEL_MAX_LENGTH)
    const {
      config: { collectionFieldKey },
      controllerRef,
    } = await this.coreApi.getComponentConnection(componentRef)
    await this.coreApi.setComponentConnection(componentRef, { crmLabel })

    const updateCollection = async () => {
      const collectionId = await this._getCollectionId(controllerRef)
      if (!collectionId) {
        return
      }
      return this.coreApi.collectionsApi.updateField(collectionId, collectionFieldKey, crmLabel)
    }
    return updateCollection()
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.adiEditFieldPanel.CHANGE_FIELD_TITLE })
  public async changeLabelADI(
    componentRef: ComponentRef,
    label: string,
    newName: string,
    _biData = {},
  ) {
    await this._changeLabel(componentRef, label)
    return this._updateCrmLabel(componentRef, newName)
  }

  @undoable()
  public async changeLabel(componentRef: ComponentRef, label: string) {
    return this._changeLabel(componentRef, label)
  }

  @undoable()
  public showLabelsChanged(componentRefs: ComponentRef[], showLabel: boolean, _biData = {}) {
    return componentRefs.map((componentRef) => this._showLabelChanged(componentRef, showLabel))
  }

  private async _showLabelChanged(
    componentRef: ComponentRef,
    showLabel: boolean,
    fieldLabel?: string,
  ) {
    if (showLabel) {
      let label = fieldLabel
      if (!fieldLabel) {
        const {
          config: { label: labelFromConnection },
        } = await this.coreApi.getComponentConnection(componentRef)
        label = labelFromConnection
      }

      return this.boundEditorSDK.components.data.update({
        componentRef,
        data: { label },
      })
    } else {
      return this.boundEditorSDK.components.data.update({
        componentRef,
        data: { label: '' },
      })
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async showLabelChangedForAllFields(
    componentRef: ComponentRef,
    fields: {
      name: string
      componentRef: ComponentRef
    }[],
    showTitles: boolean,
    _biData = {},
  ) {
    const labelUpdates = fields.map(async (field) => {
      let label = ''
      if (showTitles) {
        label = _.get(
          await this.coreApi.getComponentConnection(field.componentRef),
          'config.label',
          '',
        )
      }
      await this._showLabelChanged(field.componentRef, showTitles, label)
      return label
    })

    const namesUpdates = fields.map((field) => this._updateCrmLabel(field.componentRef, field.name))
    await Promise.all([...labelUpdates, ...namesUpdates])
    await this.coreApi.layout.updateFieldsLayoutADI(componentRef, { showTitles })
    return Promise.all(labelUpdates)
  }

  @undoable()
  public async changeUploadFileLabelADI(
    componentRef: ComponentRef,
    buttonLabel: string,
    newName: string,
  ) {
    await this._changeUploadFileLabel(componentRef, buttonLabel)
    return this._updateCrmLabel(componentRef, newName)
  }

  @undoable()
  public changeUploadFilePlaceholder(
    componentRef: ComponentRef,
    placeholderLabel: FieldPlaceholder,
  ) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { placeholderLabel },
    })
  }

  @undoable()
  public async changePlaceholderADI(
    componentRef: ComponentRef,
    placeholder: FieldPlaceholder,
    newName: string,
  ) {
    await this.changePlaceholder(componentRef, placeholder)
    return this._updateCrmLabel(componentRef, newName)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.TOGGLE_REQUIRED_FIELD })
  public changeRequired(componentRef: ComponentRef, required: boolean, _biData = {}) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { required },
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.SELECT_FIELD_TO_CONNECT })
  public setComponentConnection(connectToRef: ComponentRef, connectionConfig, _biData = {}) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getCustomFields() {
    return this.remoteApi.getCustomFields()
  }

  public async createCustomField(componentRef: ComponentRef, field: CustomField) {
    const customField = await this.remoteApi.createCustomField(field)
    await this.coreApi.setComponentConnection(componentRef, {
      customFieldKey: customField.key,
      customFieldName: field.name,
      crmTag: undefined,
    })
    return customField
  }

  private async _getFields(
    componentRefs: ComponentRef[],
    allFieldsTypes: boolean = false,
  ): Promise<FormField[]> {
    if (componentRefs.length === 0) {
      return []
    }
    const components = await this.boundEditorSDK.components.get({
      componentRefs,
      properties: ['props', 'data', 'connections', 'componentType'],
    })

    const outerFieldComponents = components.filter((component) => {
      const primaryConnection = getPrimaryConnection(_.get(component, 'connections'))
      return !isComplexInnerField(_.get(primaryConnection, 'role'))
    })

    const fields = await Promise.all<FormField>(
      outerFieldComponents.map((component) => {
        const comp = {
          ...component,
          connection: getPrimaryConnection(_.get(component, 'connections')),
          nonPrimaryConnections: _.get(component, 'connections').filter(
            (connection) => !connection.isPrimary,
          ),
        }
        return this._getField(comp, { allFieldsTypes })
      }),
    )
    return fields.filter((x) => !!x)
  }

  public async getField(
    componentRef: ComponentRef,
    allFieldsTypes: boolean = false,
  ): Promise<FormField> {
    const connection = await this.coreApi.getComponentConnection(componentRef)
    const { componentType, props, data, connections } = await this._getFieldPropertiesAndData(
      componentRef,
    )
    return this._getField(
      { componentType, connection, props, data, componentRef, nonPrimaryConnections: connections },
      { allFieldsTypes },
    )
  }

  public async getChildFields(componentRef: ComponentRef, role: string) {
    if (!isComplexComponent(role)) {
      return []
    }

    const childComponents = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef: componentRef,
    })
    const childFields = await Promise.all(
      childComponents.map(async (c) => {
        const childConnection = await this.coreApi.getComponentConnection(c)
        return isComplexInnerField(childConnection.role) && this.getField(c, true)
      }),
    )

    return _.compact(childFields)
  }

  public async getChildFieldsData(
    componentRef: ComponentRef,
    role: string,
  ): Promise<{ componentRef: ComponentRef; data: ComponentData }[]> {
    const childFields: FormField[] = await this.getChildFields(componentRef, role)
    return Promise.all(
      childFields.map(async (f: FormField) => {
        const { componentRef: compRef } = f
        return {
          componentRef: compRef,
          data: (await this.getFieldData(compRef)) as ComponentData,
        }
      }),
    )
  }

  private async _getField(
    { componentType, connection, props, data, componentRef, nonPrimaryConnections },
    {
      allFieldsTypes = false,
      updateConnection = true,
    }: { allFieldsTypes: boolean; updateConnection?: boolean },
  ): Promise<FormField> {
    const actualProps = props || {}
    const actualData = data || {}

    const isValidFieldPred: (role: string) => boolean = allFieldsTypes ? isAnyField : isInputField
    const {
      config: {
        crmLabel,
        crmType,
        crmTag,
        customFieldKey,
        customFieldName,
        fieldType,
        collectionFieldKey,
        collectionFieldType,
        paymentItemsMapping, // TODO: Make it a dynamic load without the need to every time add here what we need
        label: labelFromConnection,
      },
      role,
    } = connection as ComponentConnection

    if (!isValidFieldPred(role)) {
      return null
    }

    const { placeholder: propPlaceholder, ...restProps } = actualProps

    const {
      placeholder: dataPlaceholder,
      titleText,
      clearButtonText,
      buttonLabel,
      label: labelFromData,
      checked,
      options,
      value,
      placeholderLabel,
    } = actualData

    const label = labelFromData || labelFromConnection
    const placeholder = dataPlaceholder || propPlaceholder || placeholderLabel
    let defaultLabel

    if (isInputField(role) || isComplexInnerField(role)) {
      defaultLabel = getDefaultLabel({
        titleText,
        buttonLabel,
        label,
        placeholder,
        fieldType,
      })

      if (updateConnection) {
        await this._updateLabelConnection({
          componentRef,
          label: labelFromData,
          defaultLabel,
          labelFromConnection,
        })
      }
    }

    return {
      componentType,
      componentRef,
      crmLabel,
      crmType,
      crmTag,
      fieldType,
      customFieldKey,
      customFieldName,
      collectionFieldKey,
      collectionFieldType,
      paymentItemsMapping,
      checked,
      role,
      label: label || defaultLabel,
      placeholder,
      showLabel: !!labelFromData,
      buttonLabel,
      titleText,
      clearButtonText,
      options,
      defaultValue: value,
      nonPrimaryConnections,
      childFields: await this.getChildFields(componentRef, role),
      ...restProps,
    }
  }

  @undoable()
  public async addPaymentField(
    formData: {
      formComponentRef: ComponentRef
      preset: FormPreset
      plugins: FormPlugin[]
      commonStyles: CommonStyles
    },
    fieldData: {
      fieldType: FormsFieldPreset
      data: any
      config?: any
    },
    paymentData: { currency: string; selectedPaymentOption?: PAYMENT_OPTIONS; product?: Product },
  ) {
    const { formComponentRef, preset, plugins, commonStyles } = formData
    const { data = {}, config = {}, fieldType } = fieldData
    const { currency, selectedPaymentOption, product } = paymentData

    let connectToRef

    switch (fieldType) {
      case FormsFieldPreset.GENERAL_ITEMS_LIST:
        const selectionFieldPayload = await this.addSelectionField({
          componentRef: formComponentRef,
          preset,
          plugins,
          commonStyles,
          data,
          config,
          fieldType,
          flow: ADD_FIELD_FLOW.ADD_PAYMENT_FIELD,
        })
        connectToRef = selectionFieldPayload.connectToRef
        break
      case FormsFieldPreset.GENERAL_CUSTOM_AMOUNT:
        const generalFieldPayload = await this._addField({
          componentRef: formComponentRef,
          preset,
          plugins,
          fieldType,
          extraData: {
            data: { ...data, prefix: _.get(getCurrencyByKey(currency), 'symbol') },
            connectionConfig: { ...config },
          },
          commonStyles,
          flow: ADD_FIELD_FLOW.ADD_PAYMENT_FIELD,
        })
        connectToRef = generalFieldPayload.connectToRef
        break
    }

    await this.coreApi.settings.addPaymentFormData(formComponentRef, {
      currency,
      selectedPaymentOption,
      product,
    })

    return connectToRef
  }

  public addSelectionField({
    componentRef,
    preset,
    plugins,
    commonStyles,
    data = {},
    config = {},
    fieldType,
    flow,
  }: {
    componentRef: ComponentRef
    preset: FormPreset
    plugins: FormPlugin[]
    commonStyles: CommonStyles
    data: any
    config?: any
    fieldType: FormsFieldPreset
    flow?: string
  }) {
    const componentType = fieldsStore.get(fieldType).properties.componentType
    const extraData = {
      data: {
        ...data,
        options: [],
      },
      connectionConfig: { ...config },
    }

    switch (componentType) {
      case FIELD_COMPONENT_TYPES.RADIO_GROUP:
        extraData.data.options = _.map(data.options, (option) =>
          makeGeneralRadioGroupOption(option.label, option.value),
        )
        break
    }

    return this._addField({
      componentRef,
      preset,
      plugins,
      fieldType,
      extraData,
      commonStyles,
      flow,
    })
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD,
    endEvid: EVENTS.PANELS.addFieldPanel.ADD_FIELD_COMPLETE,
  })
  public async addField(
    componentRef: ComponentRef,
    formComponentConfig: ComponentConfig,
    {
      fieldType,
      extraData,
      commonStyles,
      flow,
    }: {
      fieldType: FieldPreset
      extraData: FieldExtraData
      commonStyles: CommonStyles
      flow?: string
    },
    _biData = {},
  ) {
    const origin = _.toLower(this.coreApi.originEditorType())
    this.fedopsLogger.interactionStarted(`add-${origin}-new-field`)

    const preset = _.get(formComponentConfig, 'preset')
    const plugins = convertPluginsToFormsPlugins(_.get(formComponentConfig, 'plugins', []))

    const { isAutofillEmailEnabled } = formComponentConfig
    const fieldData = await this._addField({
      componentRef,
      preset,
      plugins,
      fieldType,
      extraData,
      commonStyles,
      flow,
      isAutofillEmailEnabled,
    })

    const newField = await this._getField(
      {
        componentType: fieldData.componentType,
        componentRef: fieldData.connectToRef,
        props: fieldData.props,
        data: fieldData.data,
        connection: {
          isPrimary: true,
          config: fieldData.connectionConfig,
          role: fieldData.role,
        },
        nonPrimaryConnections: [],
      },
      { allFieldsTypes: false, updateConnection: false },
    )

    this.boundEditorSDK.selection
      .locateAndHighlightComponentByCompRef({
        compRef: fieldData.connectToRef,
      })
      .then(() => {
        setTimeout(() => this.boundEditorSDK.selection.clearHighlights(), 500)
      })

    this.fedopsLogger.interactionEnded(`add-${origin}-new-field`)

    return { fieldData: newField }
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD,
    endEvid: EVENTS.PANELS.addFieldPanel.ADD_FIELD_COMPLETE,
  })
  @withSync()
  public async addFieldADI(
    containerComponent: ComponentRef,
    field: FieldPreset,
    showLabel: boolean,
    showFieldsTitles: boolean,
    plugins: FormPlugin[] = [],
    _biData: object = {},
  ) {
    this.fedopsLogger.interactionStarted('add-adi-new-field')
    const fieldProperties: FieldProperties = fieldsStore.get(field).properties
    _.set(fieldProperties, 'extraData.connectionConfig.fieldType', field)
    const formConfig = await this.coreApi.getComponentConnection(containerComponent)
    if (!formConfig) {
      return
    }
    const preset = formConfig.config.preset
    const commonStyles = await this.coreApi.style.getFieldsCommonStylesGlobalDesign(
      containerComponent,
    )

    const label = _.get(fieldProperties, 'extraData.data.label')
    const placeholder = _.get(fieldProperties, 'extraData.data.placeholder')
    const buttonLabel = _.get(fieldProperties, 'extraData.data.buttonLabel')
    const crmLabel = _.get(fieldProperties, 'extraData.connectionConfig.crmLabel')

    const fieldName = getFieldName({
      label,
      placeholder,
      buttonLabel,
      showLabel,
      crmLabel,
      fieldType: field,
    })

    if (!showLabel && label) {
      _.set(fieldProperties, 'extraData.connectionConfig.label', label)
      _.set(fieldProperties, 'extraData.data.label', '')
    }

    _.set(
      fieldProperties,
      'extraData.connectionConfig.crmLabel',
      fieldName.substring(0, CRM_LABEL_MAX_LENGTH),
    )

    const customField = await this.getCustomFieldForField(fieldProperties.extraData)

    if (customField) {
      _.set(fieldProperties, 'extraData.connectionConfig.customFieldKey', customField?.key)
    }

    try {
      const { width, height, inputHeight } = await this._overrideADILayout(
        containerComponent,
        preset,
        fieldProperties.componentType,
        showFieldsTitles,
      )
      const newLayout = height && {
        props: { inputHeight },
        layout: { width, height },
      }
      const fieldData = await this._addField({
        componentRef: containerComponent,
        preset,
        plugins,
        commonStyles,
        extraData: _.merge(fieldProperties.extraData, newLayout),
        fieldType: field,
      })

      await this.coreApi.layout.updateFieldsLayoutADI(containerComponent, {
        showTitles: showFieldsTitles,
      })

      const newField = this._getField(
        {
          componentType: fieldProperties.componentType,
          componentRef: fieldData.connectToRef,
          props: fieldData.props,
          data: fieldData.data,
          connection: {
            isPrimary: true,
            config: fieldData.connectionConfig,
            role: fieldData.role,
          },
          nonPrimaryConnections: [],
        },
        { allFieldsTypes: false },
      )

      this.fedopsLogger.interactionEnded('add-adi-new-field')

      return newField
    } catch (ex) {}
  }

  public async fetchCustomFieldsByName() {
    try {
      const customFields = await this.remoteApi.getCustomFields()
      return _.groupBy(customFields, 'name')
    } catch (ex) {}
  }

  public async getCustomFieldForField(fieldData): Promise<null | CustomFieldResponse> {
    if (fieldData.connectionConfig.crmType !== CUSTOM_FIELD) {
      return
    }
    const fieldName = fieldData.connectionConfig.crmLabel
    const fieldCustomFieldsTypes = fieldsStore.get(fieldData.connectionConfig.fieldType).metadata
      .customFields
    if (!fieldCustomFieldsTypes.length) {
      return
    }
    try {
      const customField = await this.remoteApi.createCustomField({
        name: fieldName,
        fieldType: fieldCustomFieldsTypes[0],
      })
      return customField
    } catch (ex) {}
  }

  public async restoreField(formRef: ComponentRef, { data, role, config }) {
    const { controllerRef } = await this.coreApi.getComponentConnection(formRef)
    const field = { data, role, connectionConfig: config }
    return this.coreApi.addComponentAndConnect(field, controllerRef, formRef)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED })
  public async changeCheckboxLink(componentRef: ComponentRef, _biData = {}) {
    const { link: previousLink } = (await this.boundEditorSDK.components.data.get({
      componentRef,
    })) as any

    const link = await this.boundEditorSDK.editor.openLinkPanel({
      value: previousLink,
    })

    this.boundEditorSDK.components.data.update({ componentRef, data: { link } })
    const linkLocationValue = await this.boundEditorSDK.editor.utils.getLinkAsString({ link })

    return { link, linkLocationValue }
  }

  public updateCheckboxLinkData(componentRef: ComponentRef, linkLabel: string, link) {
    this.changeCheckboxLinkLabel(componentRef, linkLabel)

    if (!_.isEmpty(link)) {
      this.updateCheckboxLink(componentRef, link)
    }
  }

  @undoable()
  public changeCheckboxLinkLabel(componentRef: ComponentRef, linkLabel: string) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { linkLabel } })
  }

  public async getCheckboxLinkData(componentRef: ComponentRef) {
    const { link, linkLabel } = (await this.boundEditorSDK.components.data.get({
      componentRef,
    })) as any
    const linkLocationValue = link
      ? await this.boundEditorSDK.editor.utils.getLinkAsString({ link })
      : null

    return { link, linkLocationValue, linkLabel }
  }

  public updateCheckboxLink(componentRef: ComponentRef, link) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { link } })
  }

  @undoable()
  public removeCheckboxLinkData(componentRef: ComponentRef) {
    const emptyLinkData = { link: null, linkLabel: '' }
    return this.boundEditorSDK.components.data.update({ componentRef, data: emptyLinkData })
  }

  private _getFilterFieldsLayout(fieldsData: FormField[], roles: string[]): FormField[] {
    return _.filter(fieldsData, (field) => _.includes(roles, field.role))
  }

  private _getFieldsToRePosition(fieldsData: FormField[], y?: number): FormField[] {
    return _.filter(
      fieldsData,
      (f) => f.y >= y && !_.includes(COMPONENTS_TO_REPOSITION_AFTER_ADD_FIELD, f.role),
    )
  }

  private async _findNewFieldLocation(componentRef: ComponentRef, fieldsData?: FormField[]) {
    const childLayouts = fieldsData
      ? this._getFilterFieldsLayout(fieldsData, FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD)
      : await this.coreApi.layout.getChildrenLayouts(
          componentRef,
          FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD,
        )

    const lastLayout: any = _.maxBy(childLayouts, (field: any) => field.y)

    return {
      x: lastLayout ? lastLayout.x : 60,
      y: lastLayout ? lastLayout.y + lastLayout.height + SPACE_BETWEEN_FIELDS : 60,
    }
  }

  private _findNewFieldLocationInComplex(fields?: FormField[]) {
    const lastLayout: any = _.maxBy(fields, (field: any) => field.y + field.height)

    const y = lastLayout ? lastLayout.y + lastLayout.height + SPACE_BETWEEN_FIELDS : 0
    return { x: 0, y }
  }

  private async _overrideADILayout(
    componentRef: ComponentRef,
    presetKey: string,
    componentType: FIELD_COMPONENT_TYPES,
    showTitles: boolean,
  ) {
    const currentPreset = await getFormPreset(this.ravenInstance)(presetKey)
    const componentsLikeTextInput = [
      FIELD_COMPONENT_TYPES.DATE_PICKER,
      FIELD_COMPONENT_TYPES.COMBOBOX,
    ]
    const findComponentInPreset = (compType) =>
      _.find(currentPreset.components, { componentType: compType })

    const componentInPreset =
      findComponentInPreset(componentType) ||
      (_.includes(componentsLikeTextInput, componentType) &&
        findComponentInPreset(FIELD_COMPONENT_TYPES.TEXT_INPUT))
    const height: number = _.get(componentInPreset, 'layout.height')
    const inputHeight: number = _.get(componentInPreset, 'props.inputHeight')
    const hasLabel: boolean = !!_.get(componentInPreset, 'data.label')

    const labelHeight = showTitles && !hasLabel ? 15 : 0

    const { width } = await this.boundEditorSDK.components.layout.get({ componentRef })

    return { width, height: height + labelHeight, inputHeight }
  }

  private async _getRelevantContainerRefsAndFields(formComponentRef: ComponentRef, plugins) {
    const isMultiStepForm = _.includes(plugins, FormPlugin.MULTI_STEP_FORM)

    const containerRef = formComponentRef
    let fieldsContainerRef = formComponentRef

    const allFieldsData = await this.getFieldsSortByXY(formComponentRef, {
      allFieldsTypes: true,
    })
    let fieldsWithinVisibleContainer = allFieldsData

    if (isMultiStepForm) {
      fieldsContainerRef = await this.coreApi.steps.getCurrentStepRef(containerRef)
      fieldsWithinVisibleContainer = _.filter(
        allFieldsData,
        (field) => _.get(field, 'parentComponentRef.id') === fieldsContainerRef.id,
      )
    }
    return { allFieldsData, fieldsWithinVisibleContainer, fieldsContainerRef, containerRef }
  }

  private async _addField({
    plugins,
    componentRef,
    preset,
    fieldType,
    extraData,
    commonStyles,
    flow,
    isAutofillEmailEnabled,
  }: {
    fieldType: FieldPreset
    extraData: FieldExtraData
    commonStyles: CommonStyles
    plugins: FormPlugin[]
    componentRef: ComponentRef
    preset: string
    flow?: string
    deepMerge?: boolean
    isAutofillEmailEnabled?: boolean
  }) {
    const getLayouts = async () => {
      let layout, formWidth, responsiveItemLayoutType: ResponsiveItemLayoutType
      if (isResponsive) {
        const containerLayout = await this.boundEditorSDK.document.responsiveLayout.get({
          componentRef: fieldsContainerRef,
        })
        responsiveItemLayoutType = getItemLayoutType(containerLayout)
        switch (responsiveItemLayoutType) {
          case 'GridItemLayout':
            const gridAreas: GridItemPosition[] = await this._getGridItemLayout({
              componentRef,
              fieldType,
              allFieldsData,
            })
            return {
              layout: createItemLayout({
                itemLayoutType: 'GridItemLayout',
                layoutData: { gridArea: gridAreas[0].gridArea },
              }),
              gridAreas,
              responsiveItemLayoutType,
            }
        }
        layout = extraData.layoutResponsive
      } else {
        const [newFieldLayout, { width }] = await Promise.all([
          this._findNewFieldLocation(fieldsContainerRef, fieldsWithinVisibleContainer),
          this.boundEditorSDK.components.layout.get({ componentRef: containerRef }),
        ])
        layout = _.merge({}, newFieldLayout, extraData.layout)
        formWidth = width
      }

      return {
        layout,
        responsiveItemLayoutType,
        formWidth,
      }
    }

    const createFieldStructure = async () => {
      const { controllerRef, config } = await this.coreApi.getComponentConnection(containerRef)
      const { id: controllerId } = (await this.boundEditorSDK.components.data.get({
        componentRef: controllerRef,
      })) as any

      let fieldStructure

      if (fieldsStore.get(fieldType).complexFieldWidget) {
        const sameComplexFieldsOnStage = _.filter(allFieldsData, (f) => f.fieldType === fieldType)
        const complexFieldExtraData = await this._enrichComplexFieldExtraData({
          extraData,
          fieldType,
        })

        fieldStructure = createComplexField({
          preset,
          fieldType,
          extraData: complexFieldExtraData,
          commonStyles,
          fieldsData: fieldsWithinVisibleContainer,
          sameComplexFieldsOnStage,
          formWidth,
          isResponsive,
          responsiveItemLayoutType,
          layout,
          plugins,
          controllerId,
          formConfig: config, // we may think about send part of config, relevant only for complex
        })
      } else {
        fieldStructure = createField({
          preset,
          fieldType,
          extraData,
          commonStyles,
          fieldsData: fieldsWithinVisibleContainer,
          formWidth,
          layout,
          plugins,
          isResponsive,
          responsiveItemLayoutType,
          globalSimilarity: false,
        })
      }

      const collectionFieldKey = this.getCollectionFieldKey(
        fieldStructure.connectionConfig,
        allFieldsData,
      )

      if (flow === ADD_FIELD_FLOW.ADD_NEW_FIELD || flow === ADD_FIELD_FLOW.ADD_PAYMENT_FIELD) {
        _.set(
          fieldStructure,
          'connectionConfig.crmLabel',
          getDefaultFieldName({
            fieldStructure,
            fieldsOnStage: allFieldsData,
          }),
        )
      }

      _.set(fieldStructure, 'connectionConfig.collectionFieldKey', collectionFieldKey)

      return { fieldStructure, controllerId, controllerRef, config }
    }

    const setComponentOnStage = async () => {
      let connectToRef
      const connectedNewFieldStructure = connectComponent(
        {
          ...fieldStructure.data,
          config: fieldStructure.connectionConfig,
          role: fieldStructure.role,
          subRole: fieldStructure.subRole,
        },
        controllerId,
      )
      if (isResponsive && responsiveItemLayoutType === 'GridItemLayout') {
        connectToRef = await this.boundEditorSDK.responsive.grid.addChild({
          customId: undefined,
          componentDefinition: connectedNewFieldStructure as any,
          containerRef: fieldsContainerRef,
          keepStructure: true,
          positions: gridAreas,
        })
      } else {
        connectToRef = await this.boundEditorSDK.components.add({
          componentDefinition: connectedNewFieldStructure as any,
          pageRef: fieldsContainerRef,
        })
      }
      const emailFieldWithAutofillRoleExists = !!getFieldWithNonPrimaryRole(
        allFieldsData,
        AUTOFILL_MEMBER_EMAIL_ROLE,
      )

      if (isAutofillEmailEnabled && !emailFieldWithAutofillRoleExists) {
        this.coreApi.connect(
          { connectionConfig: { isEditable: true }, role: AUTOFILL_MEMBER_EMAIL_ROLE },
          controllerRef,
          connectToRef,
          false,
        )
      }

      return { connectToRef, connectedNewFieldStructure }
    }

    const fixLayout = async () =>
      isResponsive
        ? this._fixResponsiveFieldsLayout({
            formRef: componentRef,
            newField: { ref: connectToRef, layout: fieldStructure.data.layoutResponsive },
            itemLayoutType: responsiveItemLayoutType,
          })
        : this._fixFormLayoutAfterFieldAdded({
            fieldComponentRef: connectToRef,
            fieldsContainerRef,
            fieldsData: fieldsWithinVisibleContainer,
            containerRef,
            plugins,
          })

    const { allFieldsData, fieldsWithinVisibleContainer, fieldsContainerRef, containerRef, } =
      await this._getRelevantContainerRefsAndFields(componentRef, plugins)

    const isResponsive = this.coreApi.isResponsive()
    const { formWidth, layout, responsiveItemLayoutType, gridAreas } = await getLayouts()
    const { fieldStructure, controllerId, controllerRef, config } = await createFieldStructure()
    const { connectToRef, connectedNewFieldStructure } = await setComponentOnStage()

    fixLayout() // no need to wait for promise to finish by design

    await this._handleAddFieldToCollection({
      formComponentRef: componentRef,
      fieldComponentRef: connectToRef,
      connectedField: connectedNewFieldStructure as any,
      collectionId: _.get(config, 'collectionId'),
    })

    return {
      connectToRef,
      controllerRef,
      ...fieldStructure.data,
      role: fieldStructure.role,
      connectionConfig: fieldStructure.connectionConfig,
    }
  }

  private async _getGridItemLayout({
    componentRef,
    fieldType,
    allFieldsData,
  }: {
    componentRef: ComponentRef
    fieldType: string
    allFieldsData: FormField[]
  }): Promise<GridItemPosition[]> {
    const createRowCallback = (index: number, size, breakpointId: string) =>
      this.boundEditorSDK.responsive.grid.addRow({
        componentRef,
        index,
        size,
        breakpointId,
      })
    const [gridLayouts, fieldsWithLayouts] = await Promise.all([
      this.boundEditorSDK.responsive.grid.getLayouts({ componentRef }),
      await Promise.all(
        allFieldsData.map(async (field) => ({
          role: field.role,
          fieldType: field.fieldType,
          gridLayouts: await this.boundEditorSDK.responsive.grid.getChildPositions({
            componentRef:
              /* 
                Child component can be indirect child of different type of container inside form container,
                in this situation we need the layout of the parent container to be aligned with form layout
              */
              field.parentComponentRef &&
              (field.layoutResponsive?.itemLayouts || [])[0]?.type === 'StackItemLayout'
                ? field.parentComponentRef
                : field.componentRef,
          }),
        })),
      ),
    ])
    return Promise.all(
      gridLayouts.map(async (layout) => ({
        gridArea: await requestEmptyCellInGrid(createRowCallback, {
          allFieldsData: fieldsWithLayouts,
          fieldType,
          containerLayout: layout,
        }),
        breakpointId: layout.breakpointId,
      })),
    )
  }

  private async _fixFieldsStackLayoutAfterFieldAdded({
    formRef,
    newField,
  }: {
    formRef: ComponentRef
    newField: { ref: ComponentRef; layout: ResponsiveLayout }
  }): Promise<void[]> {
    const responsiveLayouts = (
      await this.coreApi.layout.getChildrenResponsiveLayouts(formRef)
    ).layouts.filter((field) => field.componentRef.id !== _.get(newField, 'ref.id'))
    const layoutData = getItemLayoutData(newField.layout)
    const layoutUpdates = calcUpdatesForStackFieldsByNewOrder(responsiveLayouts, layoutData.order)

    return Promise.all<void>(
      _.reverse(layoutUpdates).map(this.boundEditorSDK.responsiveLayout.update),
    )
  }

  private async _fixFieldsGridLayoutAfterFieldAdded({
    formRef,
    newField,
  }: {
    formRef: ComponentRef
    newField?: { ref: ComponentRef; layout: ResponsiveLayout }
  }) {
    console.log('fixFieldsGridLayoutAfterFieldAdded...', formRef, newField)
  }

  private async _fixResponsiveFieldsLayout({
    formRef,
    itemLayoutType,
    newField,
  }: {
    formRef: ComponentRef
    itemLayoutType: ResponsiveItemLayoutType
    newField?: { ref: ComponentRef; layout: ResponsiveLayout }
  }) {
    switch (itemLayoutType) {
      case 'GridItemLayout':
        return this._fixFieldsGridLayoutAfterFieldAdded({ formRef, newField })
      default:
        return this._fixFieldsStackLayoutAfterFieldAdded({ formRef, newField })
    }
  }

  private async _getYAndHeightAddedInForm(
    fieldComponentRef?: ComponentRef,
    yOffsetAddedInContainer?: number,
    oldContainerEndY?: number,
  ) {
    if (yOffsetAddedInContainer) {
      return {
        y: oldContainerEndY,
        height: yOffsetAddedInContainer,
      }
    }
    const { height, y } = await this.boundEditorSDK.components.layout.get({
      componentRef: fieldComponentRef,
    })

    return {
      y,
      height: height + SPACE_BETWEEN_FIELDS,
    }
  }

  private async _fixFormLayoutAfterFieldAdded({
    fieldComponentRef,
    fieldsContainerRef,
    fieldsData,
    containerRef,
    plugins,
    yOffsetAddedInContainer,
    oldContainerEndY,
  }: {
    fieldComponentRef: ComponentRef
    fieldsContainerRef: ComponentRef
    fieldsData: FormField[]
    containerRef: ComponentRef
    plugins: FormPlugin[]
    yOffsetAddedInContainer?: number
    oldContainerEndY?: number
  }) {
    const createUpdates = (components: FormField[], offset) =>
      _.map(components, ({ componentRef, y, height }) => ({
        componentRef,
        layout: {
          y: y + offset,
          height,
        },
      }))

    // returns the y, height which may needed in case reach maximum size
    const getMaxVerticalProps = (
      updates: {
        componentRef: ComponentRef
        layout: FieldLayout
      }[],
      defaultProps: { y; height },
    ) =>
      _(updates)
        .map((u) => u.layout)
        .concat([defaultProps])
        .maxBy(({ y, height }) => y + height)

    const updatePositions = (
      updates: {
        componentRef: ComponentRef
        layout: FieldLayout
      }[],
    ) =>
      Promise.all(
        _.map(updates, (u) =>
          this.boundEditorSDK.components.layout.update({
            componentRef: u.componentRef,
            layout: _.pick(u.layout, ['y']),
          }),
        ),
      )

    const recenterInLightBoxIfNeeded = async (formPlugins) => {
      if (_.includes(formPlugins, FormPlugin.REGISTRATION_FORM)) {
        return this.coreApi.layout.centerComponentInsideLightbox(containerRef)
      }
    }

    // height -  is the actual offset to move elements, could be the added field height
    // or offset added to complex field
    // y - is the y coordinate, from that point the height(offset) added
    const { y, height } = await this._getYAndHeightAddedInForm(
      fieldComponentRef,
      yOffsetAddedInContainer,
      oldContainerEndY,
    )

    const repositionInputFields = this._getFieldsToRePosition(fieldsData, y)

    let updates = createUpdates(repositionInputFields, height)
    const maxVerticalProps = getMaxVerticalProps(updates, { y, height })

    const containerHeightChanged =
      await this.coreApi.layout.addHeightToContainerIfFieldCrossedLimit(
        fieldsContainerRef,
        maxVerticalProps.height + SPACE_BETWEEN_FIELDS,
        maxVerticalProps.y,
        fieldsData,
      )

    if (containerHeightChanged) {
      const footerComponents = this._getFilterFieldsLayout(
        fieldsData,
        COMPONENTS_TO_REPOSITION_AFTER_ADD_FIELD,
      )
      const footerFormUpdates = createUpdates(footerComponents, height)
      updates = [...updates, ...footerFormUpdates]
    }

    await updatePositions(updates)
    await recenterInLightBoxIfNeeded(plugins)
  }

  public async addFieldInComplexWidget({
    complexWidgetRef,
    formComponentRef,
    formComponentConnection,
    plugins,
    fieldType,
    extraData,
    layout,
  }: {
    complexWidgetRef: ComponentRef
    formComponentRef: ComponentRef
    formComponentConnection: ComponentConnection
    plugins: FormPlugin[]
    layout?: FieldLayout
    fieldType: FormsFieldPreset | FieldPreset
    extraData: FieldExtraData
  }): Promise<FormField> {
    return this._addFieldToComplexWidget({
      complexWidgetRef,
      formComponentRef,
      formComponentConnection,
      plugins,
      fieldType,
      layout,
      extraData,
    })
  }

  private async _getComplexFieldWithNewField(
    ref: ComponentRef,
    complexWidget: FormField,
  ): Promise<FormField> {
    const field = await this.getField(ref, true)
    return { ...complexWidget, childFields: [...complexWidget.childFields, field] }
  }

  private async _addFieldToComplexWidget({
    complexWidgetRef,
    formComponentRef,
    formComponentConnection,
    plugins,
    layout = null,
    fieldType,
    extraData,
  }: {
    complexWidgetRef: ComponentRef
    formComponentRef: ComponentRef
    formComponentConnection: ComponentConnection
    plugins: FormPlugin[]
    layout?: FieldLayout
    fieldType: FormsFieldPreset | FieldPreset
    extraData: FieldExtraData
  }): Promise<FormField> {
    const {
      allFieldsData,
      fieldsWithinVisibleContainer,
      fieldsContainerRef,
      containerRef,
    } = await this._getRelevantContainerRefsAndFields(formComponentRef, plugins)

    const complexWidget = _.find(allFieldsData, (f) => f.componentRef.id === complexWidgetRef.id)

    const {
      controllerRef: formControllerRef,
      config: { preset, collectionId },
    } = formComponentConnection

    const [widgetContainerRef] = await this.boundEditorSDK.components.getChildren({
      componentRef: complexWidgetRef,
    })

    const childrenRefs = _.map(complexWidget.childFields, (c) => c.componentRef)
    const [commonStyles] = await Promise.all([
      this.coreApi.style.getFieldsCommonStylesGlobalDesign(formComponentRef, childrenRefs),
    ])

    /**
     * Let's take styles from the adjacent input field in the widget, other wise
     * let's use any other fields of the form as style example
     */
    const { childFields } = complexWidget
    const fieldsData =
      complexWidget.childFields.length > 0 ? complexWidget.childFields : allFieldsData

    const [{ id: complexControllerId }, { id: formControllerId }] = await Promise.all([
      this.boundEditorSDK.components.data.get({
        componentRef: complexWidgetRef,
      }) as any,
      this.boundEditorSDK.components.data.get({
        componentRef: formControllerRef,
      }) as any,
    ])

    const sameComplexFieldsOnStage = _.filter(
      allFieldsData,
      (f) => f.fieldType === complexWidget.fieldType,
    )

    const connectedField = createAndConnectInnerComplexField({
      preset,
      fieldType,
      extraData,
      commonStyles,
      fieldsData,
      sameComplexFieldsOnStage,
      plugins,
      globalSimilarity: false,
      formControllerId,
      complexControllerId,
    })

    const newLayout = layout || this._findNewFieldLocationInComplex(childFields)
    connectedField.layout = _.merge(connectedField.layout, newLayout)

    const ref = await this.boundEditorSDK.components.add({
      componentDefinition: connectedField as any,
      pageRef: widgetContainerRef,
    })

    await this._handleAddFieldToCollection({
      formComponentRef,
      connectedField: connectedField as any,
      collectionId,
    })

    const { height: fieldHeight, y: fieldY } = connectedField.layout
    const complexNewHeight = fieldY + fieldHeight

    const heightDiff = complexNewHeight - complexWidget.height

    if (heightDiff !== 0) {
      await this.boundEditorSDK.components.layout.update({
        componentRef: complexWidgetRef,
        layout: {
          height: complexNewHeight,
        },
      })
    }

    if (heightDiff > 0) {
      await this._fixFormLayoutAfterFieldAdded({
        fieldComponentRef: null,
        fieldsContainerRef,
        fieldsData: fieldsWithinVisibleContainer,
        containerRef,
        plugins,
        yOffsetAddedInContainer: heightDiff,
        oldContainerEndY: complexWidget.y + complexWidget.height,
      })
    }
    return this._getComplexFieldWithNewField(ref, complexWidget)
  }

  private _getDuplicatedFieldsConfigForCollection(
    formFields: FormField[],
    fieldsConfigs: ComponentConfig[],
  ): ComponentConfig[] {
    const fieldsToCollection = _.flatten(
      _.map(formFields, (field) => [field, ...filterFieldsToCollection(field.childFields)]),
    )

    const updatedConfigs = getDuplicatedFieldsConfig(fieldsToCollection, fieldsConfigs)

    _.forEach(updatedConfigs, (config) => {
      const collectionFieldKey = this.getCollectionFieldKey(config, fieldsToCollection)
      _.set(config, 'collectionFieldKey', collectionFieldKey)
    })

    return updatedConfigs
  }

  public getCollectionFieldKey(fieldConnectionConfig, formFields): string {
    return (
      _.get(fieldConnectionConfig, 'collectionFieldKey') ||
      createSuffixedName(
        _.map(formFields, 'collectionFieldKey'),
        _.camelCase(_.get(fieldConnectionConfig, 'crmLabel')),
        '',
      )
    )
  }

  private async _handleAddFieldToCollection({
    formComponentRef,
    fieldComponentRef,
    connectedField,
    collectionId,
  }: {
    formComponentRef: ComponentRef
    fieldComponentRef?: ComponentRef
    connectedField: ComponentStructure
    collectionId: string
  }) {
    const { role } = getPrimaryConnectionFromStructure(connectedField)

    if (role === FIELDS.ROLE_FIELD_COMPLEX_ADDRESS_WIDGET) {
      return this._addInnerFieldsToCollection({
        formComponentRef,
        fieldComponentRef,
        role,
        collectionId,
      })
    } else {
      const config = getConfigFromStructure(connectedField)
      return this.addFieldToCollection(formComponentRef, config, collectionId)
    }
  }

  private async _addInnerFieldsToCollection({
    formComponentRef,
    fieldComponentRef,
    role,
    collectionId,
  }) {
    const childFields = await this.getChildFields(fieldComponentRef, role)

    const validCollectionId = await this.coreApi.getValidCollectionId({
      componentRef: formComponentRef,
      collectionId,
    })

    if (!validCollectionId) {
      return Promise.resolve()
    }

    return this.coreApi.collectionsApi.addFieldsToCollection(validCollectionId, childFields, _.noop)
  }

  public async addFieldToCollection(componentRef, fieldConnectionConfig, collectionId) {
    if (!allowCollectionSync(fieldConnectionConfig.fieldType)) {
      return Promise.resolve()
    }

    const validCollectionId = await this.coreApi.getValidCollectionId({
      componentRef,
      collectionId,
    })

    if (!validCollectionId) {
      return Promise.resolve()
    }

    return this.coreApi.collectionsApi.addFieldToCollection(
      validCollectionId,
      fieldConnectionConfig,
    )
  }

  private async _showRestrictionPopupOnDuplicateField(
    controllerRef: ComponentRef,
    fieldComponentRef: ComponentRef,
  ) {
    const formComponent = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    await this.coreApi.removeComponentRef(fieldComponentRef)
    this.coreApi.managePanels.openPremiumBillingPanel(formComponent?.ref, {
      referrer: BillingPanelReferrer.DUPLICATE_FIELD_ALERT,
      alertType: UpgradeAlertType.FIELDS_LIMIT,
    })
  }

  @withFedops('handle-duplicated-field')
  public async onDuplicateField(fieldComponentRef: ComponentRef) {
    const [fields, { restrictions }, { config: fieldConfig, controllerRef }] = await Promise.all([
      this.getFieldsSortByXY(fieldComponentRef),
      this.coreApi.premium.getRestrictions(),
      this.coreApi.getComponentConnection(fieldComponentRef),
    ])

    if (getFieldsLeft(fields, restrictions) < 0) {
      await this._showRestrictionPopupOnDuplicateField(controllerRef, fieldComponentRef)
      return
    }

    const validCollectionId = await this._getCollectionId(controllerRef)

    const updatedConfig = _.first(
      this._getDuplicatedFieldsConfigForCollection(fields, [fieldConfig]),
    )

    this.addFieldToCollection(fieldComponentRef, updatedConfig, validCollectionId)

    await this._disconnectAutofillMemberEmailConnectionIfExists(fieldComponentRef)

    return this.coreApi.setComponentConnection(fieldComponentRef, updatedConfig, false)
  }

  public async onDuplicateComplexField(fieldComponentRef: ComponentRef) {
    const [fields, { restrictions }, connection] = await Promise.all([
      this.getFieldsSortByXY(fieldComponentRef),
      this.coreApi.premium.getRestrictions(),
      this.coreApi.getComponentConnection(fieldComponentRef),
    ])

    if (getFieldsLeft(fields, restrictions) < 0) {
      await this._showRestrictionPopupOnDuplicateField(connection.controllerRef, fieldComponentRef)
      return
    }

    switch (connection.role) {
      case FIELDS.ROLE_FIELD_COMPLEX_ADDRESS_WIDGET:
        return this._onDuplicateComplexAddressField(fieldComponentRef, connection, fields)

      case FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET:
        return this._onDuplicateComplexPhoneField(fieldComponentRef, connection, fields)

      default:
        return Promise.resolve(null)
    }
  }

  private async _onDuplicateComplexAddressField(
    complexAddressComponentRef: ComponentRef,
    connection: ComponentConnection,
    fields: FormField[],
  ) {
    const { childFieldsAndUpdatedFieldsConfigs } = await this._onDuplicateComplexField(
      complexAddressComponentRef,
      connection,
      fields,
    )

    const childFieldsDataForCollectionActions = createFieldsDataForCollectionActions(
      childFieldsAndUpdatedFieldsConfigs,
    )
    const validCollectionId = await this._getCollectionId(connection.controllerRef)

    return this.coreApi.collectionsApi.addFieldsToCollection(
      validCollectionId,
      childFieldsDataForCollectionActions,
      _.noop,
    )
  }

  private async _onDuplicateComplexPhoneField(
    complexPhoneComponentRef: ComponentRef,
    connection: ComponentConnection,
    fields: FormField[],
  ) {
    const { complexFieldAndUpdatedFieldConfig } = await this._onDuplicateComplexField(
      complexPhoneComponentRef,
      connection,
      fields,
    )

    const validCollectionId = await this._getCollectionId(connection.controllerRef)

    return this.coreApi.collectionsApi.addFieldToCollection(
      validCollectionId,
      complexFieldAndUpdatedFieldConfig.updatedFieldConfig,
    )
  }

  private async _onDuplicateComplexField(
    complexFieldComponentRef: ComponentRef,
    connection: ComponentConnection,
    fields: FormField[],
  ) {
    const complexField = _.find(
      fields,
      (field) => field.componentRef.id === complexFieldComponentRef.id,
    )
    const childFields = filterFieldsToCollection(
      await this.getChildFields(complexFieldComponentRef, connection.role),
    )
    const duplicatedFields = [complexField, ...childFields]
    const duplicatedFieldsConfig = (
      await Promise.all(
        _.map(duplicatedFields, (field) => this.coreApi.getComponentConnection(field.componentRef)),
      )
    ).map(({ config }) => config)
    const updatedDuplicatedFieldsConfig = this._getDuplicatedFieldsConfigForCollection(
      fields,
      duplicatedFieldsConfig,
    )

    await Promise.all([
      ..._.map(_.zip(duplicatedFields, updatedDuplicatedFieldsConfig), ([field, updatedConfig]) =>
        this.coreApi.setComponentConnection(field.componentRef, updatedConfig, false),
      ),
    ])

    return {
      complexFieldAndUpdatedFieldConfig: {
        field: complexField,
        updatedFieldConfig: updatedDuplicatedFieldsConfig[0],
      },
      childFieldsAndUpdatedFieldsConfigs: _.map(
        _.zip(childFields, _.slice(updatedDuplicatedFieldsConfig, 1)),
        ([field, updatedFieldConfig]) => ({ field, updatedFieldConfig }),
      ),
    }
  }

  private async _disconnectAutofillMemberEmailConnectionIfExists(fieldComponentRef: ComponentRef) {
    const connections: Partial<ComponentConnection>[] = await this.boundEditorSDK.controllers.listConnections(
      { componentRef: fieldComponentRef },
    )
    const autofillMemberEmailConnection = getAutofillMemberEmailConnection(connections)

    if (autofillMemberEmailConnection) {
      const { controllerRef, role } = autofillMemberEmailConnection

      return this.boundEditorSDK.controllers.disconnect({
        controllerRef,
        connectToRef: fieldComponentRef,
        role,
      })
    }
  }

  private _changeUploadFileLabel(componentRef: ComponentRef, buttonLabel) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { buttonLabel },
    })
  }

  private async _changeLabel(componentRef: ComponentRef, label: string) {
    await this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label },
    })
    return this.coreApi.setComponentConnection(componentRef, { label })
  }

  private async _getCollectionId(controllerRef) {
    const controllerType = await this.coreApi.getControllerType(controllerRef)
    let componentRef: ComponentRef

    if (isComplexController(controllerType)) {
      componentRef = await this.coreApi.getFormContainerFromComplexFieldController(controllerRef)
    } else {
      const comp = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
      componentRef = comp?.ref
    }

    if (!componentRef) {
      return
    }

    const {
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    return this.coreApi.getValidCollectionId({ componentRef, collectionId })
  }

  public getFieldData(componentRef: ComponentRef) {
    return this.boundEditorSDK.components.data.get({ componentRef })
  }

  private async _getFieldPropertiesAndData(componentRef: ComponentRef) {
    const res = await this.boundEditorSDK.components.get({
      componentRefs: componentRef,
      properties: ['props', 'data', 'componentType', 'connections'],
    })

    return res[0]
  }

  private _updateLabelConnection({ componentRef, label, defaultLabel, labelFromConnection }) {
    if (labelFromConnection) {
      return
    }

    return this.coreApi.setComponentConnection(componentRef, { label: label || defaultLabel })
  }

  @undoable()
  public changePlaceholder(
    componentRef: ComponentRef,
    placeholder: string | { text: string; value: string },
  ) {
    const updatePropPlaceholderPromise = this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { placeholder },
    })
    const updateDataPlaceholderPromise = this.boundEditorSDK.components.data.update({
      componentRef,
      data: { placeholder },
    })

    return Promise.all([updatePropPlaceholderPromise, updateDataPlaceholderPromise])
  }

  @undoable()
  public changeSelectableListPlaceholder(
    componentRef: ComponentRef,
    placeholder: { text: string; value: string },
  ) {
    const updatePropPlaceholderPromise = this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { placeholder },
    })
    const updateDataPlaceholderPromise = this.boundEditorSDK.components.data.update({
      componentRef,
      data: { placeholder, value: '' },
    })

    return Promise.all([updatePropPlaceholderPromise, updateDataPlaceholderPromise])
  }

  @undoable()
  public onDateFormatChange(componentRef: ComponentRef, newFormat: string) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { dateFormat: newFormat },
    })
  }

  @undoable()
  public onFileUploaderTypeChanged(componentRef: ComponentRef, newType: FileType) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { filesType: newType },
    })
  }

  @undoable()
  public onFileUploaderTogglePlaceholderChanged(componentRef: ComponentRef, toggleValue: boolean) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { showPlaceholder: toggleValue },
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.adiEditFieldPanel.ADD_NEW_CHOICE })
  public async addFieldOption(componentRef: ComponentRef, newOptions: FieldOption[], _biData = {}) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.adiEditFieldPanel.DELETE_CHOICE })
  public async deleteFieldOption(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    _biData = {},
  ) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.adiEditFieldPanel.EDIT_CHOICE_DONE })
  public async editFieldOptionName(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    _biData = {},
  ) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.adiEditFieldPanel.TOGGLE_DEFAULT_CHOICE })
  public async toggleDefaultFieldOption(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    _biData = {},
  ) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.adiEditFieldPanel.DRAG_CHOICE_COMPLETE })
  public async reorderFieldOptions(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    _biData = {},
  ) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable() // TODO: Change all the above to use this API
  public async updateFieldOptions(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    extraConfig?,
  ) {
    await this._editFieldsOptions(componentRef, newOptions)

    if (extraConfig) {
      await this.coreApi.setComponentConnection(componentRef, extraConfig, false)
    }
  }

  private async _editFieldsOptions(componentRef: ComponentRef, newOptions: FieldOption[]) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { options: newOptions },
    })
  }

  @undoable()
  public updateDefaultOptionValue(componentRef: ComponentRef, newValue) {
    const updatePropPlaceholderPromise = this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { placeholder: { text: '', value: '' } },
    })
    const updateDataPlaceholderPromise = this.boundEditorSDK.components.data.update({
      componentRef,
      data: { placeholder: { text: '', value: '' }, value: newValue },
    })

    return Promise.all([updatePropPlaceholderPromise, updateDataPlaceholderPromise])
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DELETE_FIELD })
  @withSync()
  public async removeFieldADI(
    formRef: ComponentRef,
    componentRef: ComponentRef,
    showTitles: boolean,
    _biData = {},
  ) {
    await this.coreApi.removeComponentRef(componentRef)
    return this.coreApi.layout.updateFieldsLayoutADI(formRef, { showTitles })
  }

  private _isElementLayoutInsideOtherElementBoundaries(elementY, { startY, height }) {
    const elementBoundaries = {
      startY,
      endY: startY + height,
    }

    return elementY >= elementBoundaries.startY && elementY <= elementBoundaries.endY
  }

  private async _reLayoutCrucialElement(formComponentRef: ComponentRef, role, positionY) {
    const allLayouts = await this.coreApi.layout.getChildrenLayouts(formComponentRef, CRUCIAL_ROLES)

    const elementLayout = _.find(allLayouts, (layout) => layout.role === role)

    if (!elementLayout) {
      return
    }

    await this.coreApi.layout.addHeightToContainerIfFieldCrossedLimit(
      formComponentRef,
      elementLayout.height + SPACE_BETWEEN_FIELDS,
      positionY,
    )

    await this.boundEditorSDK.components.layout.update({
      componentRef: elementLayout.componentRef,
      layout: {
        y: positionY,
      },
    })

    const remainingLayouts = _.filter(allLayouts, (layout) => layout.role !== role)

    // check if the element we updated isn't overlapping other element layout ( other element inside our element )
    const trespassingLayout = _.find(remainingLayouts, (layout) =>
      this._isElementLayoutInsideOtherElementBoundaries(layout.y, {
        startY: positionY,
        height: elementLayout.height,
      }),
    )

    // check if the element we updated isn't trespassing other element layout ( our element inside other element )
    const overlappingLayout = _.find(
      remainingLayouts,
      (layout) =>
        this._isElementLayoutInsideOtherElementBoundaries(layout.y, {
          startY: positionY,
          height: elementLayout.height,
        }) ||
        this._isElementLayoutInsideOtherElementBoundaries(positionY, {
          startY: layout.y,
          height: layout.height,
        }),
    )

    if (!trespassingLayout && !overlappingLayout) {
      return
    }

    let diffBetweenLayoutsYAxis

    if (trespassingLayout) {
      // calc how much we need to move the trespassing element to get to the bottom of our element ( move element outside our element )
      diffBetweenLayoutsYAxis = positionY + elementLayout.height - trespassingLayout.y
    } else {
      // calc how much we need to move the overlapping element to get to the bottom of our element ( move element away from our element )
      diffBetweenLayoutsYAxis = overlappingLayout.y + overlappingLayout.height - positionY
    }

    // move all other elements with the same delta
    await Promise.all(
      _.map(remainingLayouts, (layout) => {
        // ignore layouts that exists before our layout
        if (layout.y + layout.height < positionY) {
          return Promise.resolve()
        }

        return this.boundEditorSDK.components.layout.update({
          componentRef: layout.componentRef,
          layout: {
            y: layout.y + diffBetweenLayoutsYAxis + SPACE_BETWEEN_FIELDS,
          },
        })
      }),
    )
  }

  public async reLayoutSubmitButton(formComponentRef: ComponentRef) {
    const newSubmitButtonLayout = await this._findNewFieldLocation(formComponentRef)

    return this._reLayoutCrucialElement(
      formComponentRef,
      ROLE_SUBMIT_BUTTON,
      newSubmitButtonLayout.y,
    )
  }

  public async reLayoutPreviousButton(stepContainerRef: ComponentRef) {
    const children = await this.coreApi.layout.getChildrenLayouts(stepContainerRef, [
      ROLE_NEXT_BUTTON,
      ROLE_SUBMIT_BUTTON,
    ])
    const roleButton = _.maxBy(children, (child) => child.y)

    let positionY

    if (roleButton) {
      positionY = roleButton.y + roleButton.height + SPACE_BETWEEN_FIELDS / 2
    } else {
      const newLayout = await this._findNewFieldLocation(stepContainerRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(stepContainerRef, ROLE_PREVIOUS_BUTTON, positionY)
  }

  public async reLayoutNextButton(stepComponentRef: ComponentRef) {
    const newLayout = await this._findNewFieldLocation(stepComponentRef)

    return this._reLayoutCrucialElement(stepComponentRef, ROLE_NEXT_BUTTON, newLayout.y)
  }

  public async reLayoutLoginLink(formComponentRef: ComponentRef) {
    const roleSubmitLayout = _.first(
      await this.coreApi.layout.getChildrenLayouts(formComponentRef, ROLE_SUBMIT_BUTTON),
    )

    let positionY

    if (roleSubmitLayout) {
      positionY = roleSubmitLayout.y + roleSubmitLayout.height + SPACE_BETWEEN_FIELDS / 2
    } else {
      const newLayout = await this._findNewFieldLocation(formComponentRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(formComponentRef, ROLE_LINK_TO_LOGIN, positionY)
  }

  public async reLayoutHiddenMessage(formComponentRef: ComponentRef, role) {
    const roleSubmitLayout = _.first(
      await this.coreApi.layout.getChildrenLayouts(formComponentRef, ROLE_SUBMIT_BUTTON),
    )

    let positionY

    if (roleSubmitLayout) {
      positionY = roleSubmitLayout.y + roleSubmitLayout.height + SPACE_BETWEEN_FIELDS / 2
    } else {
      const newLayout = await this._findNewFieldLocation(formComponentRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(formComponentRef, role, positionY)
  }

  public async reLayoutLimitMessage(formComponentRef: ComponentRef) {
    const crucialElementsWithoutLimit = _.filter(
      CRUCIAL_ROLES,
      (role) => role !== ROLE_LIMIT_MESSAGE,
    )
    const beforeLimitMessageLayouts = await this.coreApi.layout.getChildrenLayouts(
      formComponentRef,
      crucialElementsWithoutLimit,
    )

    const lowerEndLayout = _.max(
      _.map(beforeLimitMessageLayouts, (layout) => layout.y + layout.height),
    )

    let positionY

    if (lowerEndLayout) {
      positionY = lowerEndLayout + SPACE_BETWEEN_FIELDS / 2
    } else {
      const newLayout = await this._findNewFieldLocation(formComponentRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(formComponentRef, ROLE_LIMIT_MESSAGE, positionY)
  }

  public async reLayoutErrorMessage(formComponentRef: ComponentRef) {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(formComponentRef, [
      ROLE_SUBMIT_BUTTON,
      ROLE_LINK_TO_LOGIN,
    ])

    const lastLayout = _.maxBy(childLayouts, (field: any) => field.y)

    let positionY

    if (lastLayout) {
      positionY = lastLayout.y + lastLayout.height + SPACE_BETWEEN_FIELDS * 2
    } else {
      const newLayout = await this._findNewFieldLocation(formComponentRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(formComponentRef, ROLE_MESSAGE, positionY)
  }

  public async restoreCrucialElement(
    formComponentRef: ComponentRef,
    createElement: (preset: FormPreset, locale, boxLayout) => any,
    parentComponentRef?: ComponentRef,
  ): Promise<{
    role: any
    connectionConfig: any
    connectToRef: ComponentRef
    controllerRef: ComponentRef
  }> {
    const destComponentRef = parentComponentRef || formComponentRef

    const connectionConfig = await this.coreApi.getComponentConnection(formComponentRef)
    const { controllerRef, config } = connectionConfig
    const boxLayout = await this.boundEditorSDK.components.layout.get({
      componentRef: destComponentRef,
    })
    const locale = await this.boundEditorSDK.info.getLanguage()

    const preset = _.get(config, 'preset')
    const theme = _.get(config, 'theme')

    const fieldPreset = await createElement(preset, locale, boxLayout)
    const styledFieldPreset = await this.coreApi.style.updateFieldPresetTheme(fieldPreset, theme)

    return this.coreApi.addComponentAndConnect(styledFieldPreset, controllerRef, destComponentRef)
  }

  public async isComponentExistsByRole(parentComponentRef: ComponentRef, role: string) {
    const componentRef: ComponentRef = await this.coreApi.findComponentByRole(
      parentComponentRef,
      role,
    )
    return !!componentRef
  }

  protected async _getContainerToRestoreMessage(formComponentRef: ComponentRef, stepRole?: string) {
    if (await this.coreApi.isMultiStepForm(formComponentRef)) {
      const { controllerRef } = await this.coreApi.getComponentConnection(formComponentRef)
      const comp = await this.coreApi.findConnectedComponent(controllerRef, stepRole)
      return comp?.ref
    }
    return formComponentRef
  }

  @undoable()
  public async restoreDownloadDocumentMessage(formComponentRef: ComponentRef, newMessage) {
    if (await this.isComponentExistsByRole(formComponentRef, ROLE_DOWNLOAD_MESSAGE)) {
      return
    }

    let itemLayoutType: ResponsiveItemLayoutType
    let layoutData: ResponsiveLayoutData

    if (this.coreApi.isResponsive()) {
      const childLayouts = await this.coreApi.layout.getChildrenResponsiveLayouts(formComponentRef)
      itemLayoutType = childLayouts.itemLayoutType
      layoutData = getItemLayoutData(_.get(_.last(childLayouts.layouts), 'layoutResponsive'), true)
    }

    const createMessage = async (preset: FormPreset, locale, formLayout) => {
      newMessage = `<span style="text-decoration: underline">${newMessage}</span>`

      return fetchHiddenMessage(this.ravenInstance)(
        {
          fallbackSchema: hiddenMessageStructure,
          role: ROLE_DOWNLOAD_MESSAGE,
          newMessage,
          formLayout,
          preset,
          locale,
          itemLayoutType,
          layoutData,
        },
        (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
      )
    }

    const parentComponentRef = await this._getContainerToRestoreMessage(
      formComponentRef,
      THANK_YOU_STEP_ROLE,
    )

    await this.restoreCrucialElement(parentComponentRef, createMessage)

    if (!this.coreApi.isResponsive()) {
      await this.reLayoutHiddenMessage(parentComponentRef, ROLE_DOWNLOAD_MESSAGE)
      await this.updateFormHeightIfNeeded(parentComponentRef)
    }
  }

  private async _setLimitStructureWithLabelGlobalDesign(commonStyles: CommonStyles) {
    const fontOptions = await this.boundEditorSDK.fonts.getFontsOptions()
    const color = _.get(
      commonStyles[BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR],
      'value',
      'color_15',
    ) as string
    const font = _.get(
      commonStyles[BASE_DESIGN_GROUPS.LABEL_TEXT_FONT],
      'value',
      'font_8',
    ) as string

    const defaultText = 'This form no longer accepts submissions.'

    hiddenLimitMessageStructure.data.text = htmlTextFromStyle(defaultText, {
      color,
      font,
      fontOptions,
      textAlignment: 'center',
    })
    return hiddenLimitMessageStructure
  }

  @undoable()
  public async restoreLimitMessage(
    formComponentRef: ComponentRef,
    {
      commonStyles,
      newMessage,
    }: {
      commonStyles?: CommonStyles
      newMessage?: string
    } = {},
  ) {
    if (await this.isComponentExistsByRole(formComponentRef, ROLE_LIMIT_MESSAGE)) {
      return
    }

    const createMessage = async (preset: FormPreset, locale, formLayout) => {
      newMessage = newMessage || translations.t('settings.limitMessage.default')

      if (isMultiStep) {
        return fetchHiddenMessageAndReplaceRole(this.ravenInstance)(
          {
            role: ROLE_MESSAGE,
            newMessage,
            fallbackSchema: hiddenMessageStructure,
            formLayout,
            preset,
            locale,
            itemLayoutType,
            layoutData,
          },
          (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
          ROLE_LIMIT_MESSAGE,
        )
      } else {
        const limitMessageStructure = await this._setLimitStructureWithLabelGlobalDesign(
          commonStyles,
        )
        return getMessageSchema({
          newMessage,
          formLayout,
          role: ROLE_LIMIT_MESSAGE,
          rawSchema: limitMessageStructure,
          itemLayoutType,
          layoutData,
        })
      }
    }

    const getResponsiveLayoutParams = async () => {
      const childLayouts = await this.coreApi.layout.getChildrenResponsiveLayouts(formComponentRef)
      return {
        itemLayoutType: childLayouts.itemLayoutType as ResponsiveItemLayoutType,
        layoutData: getItemLayoutData(
          _.get(_.last(childLayouts.layouts), 'layoutResponsive'),
        ) as ResponsiveLayoutData,
      }
    }

    const isResponsive = this.coreApi.isResponsive()
    const [isMultiStep, parentComponentRef] = await Promise.all([
      this.coreApi.isMultiStepForm(formComponentRef),
      this._getContainerToRestoreMessage(formComponentRef, LIMIT_SUBMISSIONS_STEP_ROLE),
    ])
    const { itemLayoutType, layoutData } = isResponsive && (await getResponsiveLayoutParams())

    await this.restoreCrucialElement(formComponentRef, createMessage, parentComponentRef)

    if (!isResponsive) {
      await this.reLayoutLimitMessage(formComponentRef)
      await this.updateFormHeightIfNeeded(formComponentRef)
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.FORM_SETTINGS].RESTORE_CRUCIAL_ELEMENTS })
  public async restoreSubmitButton(componentRef: ComponentRef, _biData = {}) {
    if (await this.isComponentExistsByRole(componentRef, ROLE_SUBMIT_BUTTON)) {
      return
    }

    const { connectToRef: newRef, controllerRef } = await (this.coreApi.isResponsive()
      ? this._restoreResponsiveSubmitButton(componentRef)
      : this._restoreClassicSubmitButton(componentRef))

    await this.coreApi.rules.restoreSubmitButton(controllerRef, newRef)

    return newRef
  }

  private async _restoreClassicSubmitButton(componentRef: ComponentRef) {
    let containerRef = componentRef

    if (await this.coreApi.isMultiStepForm(componentRef)) {
      const stepsData = await this.coreApi.steps.getSteps(componentRef)
      const stepsWithoutThankYouStep = _.filter(
        stepsData,
        (step) => step.role !== THANK_YOU_STEP_ROLE,
      )
      containerRef = _.last(stepsWithoutThankYouStep).componentRef
    }

    // TODO: Extract registration form scope when working on plugin system
    const isRegistrationForm = await this.coreApi.isRegistrationForm(componentRef)
    const label = translations.t(`preset.${isRegistrationForm ? 'signup' : 'submit'}ButtonLabel`)
    const fallbackSchema = isRegistrationForm ? signupButtonStructure : submitButtonStructure

    const createButton = async (preset: FormPreset, locale, _boxLayout) =>
      fetchSubmitButtonSchema(this.ravenInstance)(
        { label, preset, locale, fallbackSchema },
        (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
      )

    const newRef = await this.restoreCrucialElement(componentRef, createButton, containerRef)
    await this.reLayoutSubmitButton(containerRef)
    await this.updateFormHeightIfNeeded(containerRef)

    return newRef
  }

  private async _restoreResponsiveSubmitButton(componentRef: ComponentRef) {
    const {
      itemLayoutType,
      layouts: childLayouts,
    } = await this.coreApi.layout.getChildrenResponsiveLayouts(componentRef) // change
    const messageLayout = _.find(
      childLayouts,
      ({ role }) => role === ROLE_MESSAGE || role === ROLE_DOWNLOAD_MESSAGE,
    )

    let layoutData

    if (messageLayout) {
      layoutData = getItemLayoutData(messageLayout.layoutResponsive)
      await this._fixResponsiveFieldsLayout({
        formRef: componentRef,
        newField: { ref: componentRef, layout: messageLayout.layoutResponsive },
        itemLayoutType,
      })
    } else {
      layoutData = getItemLayoutData(_.get(_.last(childLayouts), 'layoutResponsive'), true)
    }

    const createButton = async (preset: FormPreset, locale, _boxLayout) =>
      fetchSubmitButtonSchema(this.ravenInstance)(
        {
          label: translations.t(`preset.submitButtonLabel`),
          preset,
          locale,
          fallbackSchema: submitButtonStructure,
          itemLayoutType,
          layoutData,
        },
        (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
      )

    return this.restoreCrucialElement(componentRef, createButton, componentRef)
  }

  public async updateFormHeightIfNeeded(componentRef: ComponentRef) {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(componentRef, null, true)
    const lastLayout: any = _.maxBy(childLayouts, (field: any) => field.y)
    const formLayout = await this.boundEditorSDK.components.layout.get({ componentRef })

    const formBottomY = _.get(formLayout, 'y', 0) + _.get(formLayout, 'height', 0)
    const lastLayoutBottomY = _.get(lastLayout, 'y', 0) + _.get(lastLayout, 'height', 0)

    if (formBottomY - SPACE_BETWEEN_FIELDS > lastLayoutBottomY) {
      return
    }

    const extraHeight = lastLayoutBottomY - formBottomY + SPACE_BETWEEN_FIELDS

    return this.coreApi.addHeightToContainers(componentRef, extraHeight)
  }

  public async restoreLoginLink(componentRef: ComponentRef) {
    const label = translations.t(`fieldTypes.regForm_linkToLoginDialog.text`)

    const createLoginLink = async (preset: FormPreset, locale, _boxLayout) =>
      fetchLoginLinkSchema(this.ravenInstance)(
        { label, preset, locale, fallbackSchema: registrationLoginLinkStructure },
        (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
      )

    await this.restoreCrucialElement(componentRef, createLoginLink)
    await this.reLayoutLoginLink(componentRef)
    await this.updateFormHeightIfNeeded(componentRef)
  }

  public highlightField(componentRef: ComponentRef) {
    return this.boundEditorSDK.selection
      .locateAndHighlightComponentByCompRef({
        compRef: componentRef,
      })
      .then(() => {
        setTimeout(() => this.boundEditorSDK.selection.clearHighlights(), 750)
      })
  }

  @absorbException('fields-api')
  public async handleDeleteEmailFieldWithAutofill({
    formComponentRef,
    formComponentConnection,
    controllerRef,
    autofillMemberEmailConnection,
  }: {
    formComponentRef: ComponentRef
    formComponentConnection: ComponentConnection
    autofillMemberEmailConnection: ComponentConnection
    controllerRef: ComponentRef
  }) {
    const fieldsWithoutTheRemovedField = await this.getFieldsSortByXY(formComponentRef)
    const emailFields = fieldsWithoutTheRemovedField.filter(
      (field) => field.crmType === CRM_TYPES.EMAIL,
    )
    const shouldConnectNewEmailFieldWithAutofillRole =
      !!autofillMemberEmailConnection &&
      formComponentConnection.config.isAutofillEmailEnabled &&
      emailFields.length > 0

    if (shouldConnectNewEmailFieldWithAutofillRole) {
      const { isEditable } = autofillMemberEmailConnection.config
      const firstEmailFieldComponentRef = emailFields[0].componentRef

      await this.coreApi.connect(
        { connectionConfig: { isEditable }, role: AUTOFILL_MEMBER_EMAIL_ROLE },
        controllerRef,
        firstEmailFieldComponentRef,
        false,
      )
    }
  }

  public async changePaymentFieldsCurrency(controllers: { controllerRef: ComponentRef }[]) {
    const currency = await this.coreApi.getCurrency()

    return currency
      ? Promise.all([
          this._changePaymentListItemsCurrency(controllers, currency),
          this._changePaymentCustomAmountCurrency(controllers, currency),
        ])
      : Promise.resolve()
  }

  private async _changePaymentListItemsCurrency(controllers: ControllerRef[], currency: string) {
    const paymentListFields = await Promise.all(
      (
        await Promise.all(
          controllers.map(({ controllerRef }) =>
            this.coreApi.findConnectedComponent(controllerRef, FIELDS.ROLE_FIELD_ITEMS_LIST),
          ),
        )
      )
        .filter((field) => field)
        .map(async (paymentField) => ({
          connection: paymentField.connection,
          componentRef: paymentField.ref,
        })),
    )

    return Promise.all(
      paymentListFields.map(({ connection: paymentConnection, componentRef }) => {
        const paymentItemsMapping = _.get(paymentConnection, 'config.paymentItemsMapping')
        if (!paymentItemsMapping) {
          return Promise.resolve()
        }
        const newOptions: RadioOption[] = paymentMappingToRadioOptions(
          paymentItemsMapping,
          currency,
          { t: translations.t },
        ).map((option) => ({ ...option, type: OptionType.RADIO_BUTTON }))
        return this._editFieldsOptions(componentRef, newOptions)
      }),
    )
  }

  private async _changePaymentCustomAmountCurrency(controllers: ControllerRef[], currency: string) {
    const currencySymbol = _.get(getCurrencyByKey(currency), 'symbol')

    const paymentCustomAmountFields = await Promise.all(
      (
        await Promise.all(
          controllers.map(({ controllerRef }) =>
            this.coreApi.findConnectedComponent(controllerRef, FIELDS.ROLE_FIELD_CUSTOM_AMOUNT),
          ),
        )
      ).filter((field) => field),
    )

    return Promise.all(
      paymentCustomAmountFields.map((paymentField) => {
        return this.boundEditorSDK.components.data.update({
          componentRef: paymentField.ref,
          data: { prefix: currencySymbol },
        })
      }),
    )
  }

  public async filterUnknownOptions({
    optionsField,
    initialOptions,
    filteredOptions,
  }: {
    optionsField: FormField
    initialOptions: FieldOption[]
    filteredOptions: FieldOption[]
  }) {
    if (initialOptions.length !== filteredOptions.length) {
      const data: { value?: string; options: FieldOption[] } = { options: filteredOptions }

      if (!_.find(filteredOptions, (option) => option.value === optionsField.defaultValue)) {
        if (filteredOptions.length === 0) {
          data.value = ''

          if (optionsField.required) {
            await this.boundEditorSDK.components.properties.update({
              componentRef: optionsField.componentRef,
              props: { required: false },
            })
          }
        } else {
          data.value = _.first(filteredOptions).value
        }
      }

      await this.boundEditorSDK.components.data.update({
        componentRef: optionsField.componentRef,
        data,
      })
    }
  }

  private async _enrichComplexFieldExtraData({
    extraData,
    fieldType,
  }: {
    extraData: FieldExtraData
    fieldType: FieldPreset
  }) {
    const baseExtraData = {
      [fieldType]: extraData,
    }

    switch (fieldType) {
      case FormsFieldPreset.COMPLEX_PHONE_WIDGET:
        const userGEO = await this.coreApi.getUserGEO()
        return userGEO && isCountriesCodesKeys(userGEO)
          ? {
              ...baseExtraData,
              [FormsFieldPreset.COMPLEX_PHONE_DROPDOWN]: {
                data: {
                  value: getCountryCodeValueByGEO(userGEO),
                  placeholder: {
                    value: '',
                    text: '',
                  },
                },
              },
            }
          : baseExtraData
      default:
        return baseExtraData
    }
  }

  public async connectFieldsToSubRoles(controllers: { controllerRef: ComponentRef }[]) {
    const fields = _.flatMap(
      await Promise.all(
        controllers.map(async ({ controllerRef }) => {
          const connectedComponents = await this.boundEditorSDK.controllers.listConnectedComponents(
            {
              controllerRef,
            },
          )
          return Promise.all(
            connectedComponents.map(async (componentRef) => {
              const componentConnection = await this.coreApi.getComponentConnection(componentRef)
              return {
                connection: componentConnection,
                subRole: _.get(
                  fieldsStore.all(),
                  `${componentConnection.config.fieldType}.subRole`,
                ),
                existingSubRole: componentConnection.subRole,
                componentRef,
              }
            }),
          )
        }),
      ),
    ).filter(({ subRole, existingSubRole }) => subRole && !existingSubRole)

    await Promise.all(
      fields.map((field) =>
        this.coreApi.setComponentSubRole(field.componentRef, field.subRole, field.connection),
      ),
    )
  }

  public async updateFieldCollectionType(
    componentRef: ComponentRef,
    previousFieldProp,
  ): Promise<void> {
    if (!this.experiments.enabled('specs.crm.FormsEditorMultipleFileUploads')) {
      return
    }

    const { fieldType, currentFieldProps } = await this._getFieldInfo(componentRef)
    if (fieldType !== FormsFieldPreset.GENERAL_UPLOAD_BUTTON) {
      return
    }

    const { numFilesLimit, filesType } = currentFieldProps
    const { numFilesLimit: previousNumFilesLimit, filesType: previousFilesType } = previousFieldProp
    const filesTypeChanged = !(filesType === previousFilesType)
    const isSingleFile = (num: number) => !num || num === 1

    if (numFilesLimit === previousNumFilesLimit && !filesTypeChanged) {
      return
    }

    if (!isSingleFile(numFilesLimit) && !isSingleFile(previousNumFilesLimit) && !filesTypeChanged) {
      return
    }

    if (isSingleFile(numFilesLimit) && isSingleFile(previousNumFilesLimit)) {
      return
    }

    let updatedCollectionType: string

    if (isSingleFile(numFilesLimit)) {
      updatedCollectionType = 'image'
    } else {
      switch (filesType) {
        case 'Document': {
          updatedCollectionType = 'document'
          break
        }
        case 'Audio': {
          updatedCollectionType = 'audio'
          break
        }
        case 'Image':
        case 'Video':
        case 'Gallery': {
          updatedCollectionType = 'media-gallery'
          break
        }
      }
    }

    const {
      config: { collectionFieldKey },
      controllerRef,
    } = await this.coreApi.getComponentConnection(componentRef)
    const collectionId = await this._getCollectionId(controllerRef)
    if (!collectionId) {
      return
    }

    await this.coreApi.collectionsApi.updateFieldType(
      collectionId,
      collectionFieldKey,
      updatedCollectionType,
    )
    await this.coreApi.setComponentConnection(componentRef, {
      collectionFieldType: updatedCollectionType,
    })
  }
}
