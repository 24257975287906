import { SET_POST_EDITOR_POST } from '../../store/post-editor/post-editor-actions';
import { isPublished } from '../event-helpers';

const handler = (action) => {
  const post = action.payload.post;
  return {
    evid: 207,
    is_published: isPublished(post),
    post_id: post._id,
    is_demo: post.isDemo,
    eventMeta: {
      isNavigation: true,
      description: 'post editor opened',
    },
  };
};

export const uouSetPostEditorPostEvent = { [SET_POST_EDITOR_POST]: handler };
