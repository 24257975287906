import React from 'react';
import classNames from 'classnames';
import StarIcon from './star-icon';
import styles from './rating-stars.scss';

export const OneStar = ({ a11yLabel }) => {
  return (
    <div className={styles.root}>
      {a11yLabel && <span className={styles.a11yLabel}>{a11yLabel}</span>}
      <div aria-hidden="true" className={styles.innerContainer}>
        <StarIcon className={classNames(styles.icon, styles.full)} />
      </div>
    </div>
  );
};
