import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from '../../hoc/with-translate';
import { getUserBadgeLabel } from '../../services/get-user-badge-label';
import { getUserRole } from '../../services/user-role';
import Badge from '../badge';

const UserBadge = ({ t, user }) => {
  const role = getUserRole(user);

  const ariaLabel = getUserBadgeLabel({ t, role });

  return role ? <Badge role={role} ariaLabel={ariaLabel} /> : null;
};

UserBadge.propTypes = {
  user: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default withTranslate(UserBadge);
