import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import TimeAgo from '../../../common/components/time-ago';
import TimeToRead from '../../../common/components/time-to-read';
import PostMetadataLayout from '../post-metadata-layout';
import styles from './post-metadata.scss';

const PostMetadata = ({
  className,
  date,
  dateFormatted,
  readTime,
  isShortReadTimeLabel,
  showPublishDate,
  showReadingTime,
}) => (
  <PostMetadataLayout
    className={classNames(className, styles.container)}
    itemClassName={styles.item}
  >
    {showPublishDate && (
      <TimeAgo
        className="post-metadata__date"
        time={date}
        timeFormatted={dateFormatted}
      />
    )}
    {showReadingTime && (
      <TimeToRead
        className="post-metadata__readTime"
        minutes={readTime}
        isShortLabel={isShortReadTimeLabel}
      />
    )}
  </PostMetadataLayout>
);

PostMetadata.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  dateFormatted: PropTypes.string,
  readTime: PropTypes.number,
  isShortReadTimeLabel: PropTypes.bool,
  className: PropTypes.string,
  showPublishDate: PropTypes.bool,
  showReadingTime: PropTypes.bool,
};

export default PostMetadata;
