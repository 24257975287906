
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller0 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Archive/controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Archive/controller.ts';
            var controllerExport0 = _controllerExport0;
            

            
import wrapController1 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller1 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Blog/controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Blog/controller.ts';
            var controllerExport1 = _controllerExport1;
            

            
import wrapController2 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller2 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/CategoryMenu/controller.ts';
            import * as _controllerExport2 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/CategoryMenu/controller.ts';
            var controllerExport2 = _controllerExport2;
            

            
import wrapController3 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller3 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/MyPosts/controller.ts';
            import * as _controllerExport3 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/MyPosts/controller.ts';
            var controllerExport3 = _controllerExport3;
            

            
import wrapController4 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller4 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post/controller.ts';
            import * as _controllerExport4 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post/controller.ts';
            var controllerExport4 = _controllerExport4;
            

            
import wrapController5 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller5 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/PostList/controller.ts';
            import * as _controllerExport5 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/PostList/controller.ts';
            var controllerExport5 = _controllerExport5;
            

            
import wrapController6 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller6 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/RssButton/controller.ts';
            import * as _controllerExport6 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/RssButton/controller.ts';
            var controllerExport6 = _controllerExport6;
            

            
import wrapController7 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';

            import controller7 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/TagCloud/controller.ts';
            import * as _controllerExport7 from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/TagCloud/controller.ts';
            var controllerExport7 = _controllerExport7;
            

  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  
var initI18n = null;

  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://2062d0a4929b45348643784b5cb39c36@sentry.wixpress.com/1643',
      id: '2062d0a4929b45348643784b5cb39c36',
      projectName: 'communities-blog-ooi',
      teamName: 'blogas',
      errorMonitor: true,
    };

  var experimentsConfig = {"centralized":true,"scopes":["wix-social-blog","ricos"]};

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "communities-blog-ooi",
    biConfig: null,
    appName: "Wix Blog",
    appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":["wix-social-blog","ricos"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Archive/controller.ts",
          appName: "Wix Blog",
          appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
          projectName: "communities-blog-ooi",
          componentName: "Archive",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "2f3d2c69-2bc4-4519-bd72-0a63dd92577f" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":["wix-social-blog","ricos"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Blog/controller.ts",
          appName: "Wix Blog",
          appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
          projectName: "communities-blog-ooi",
          componentName: "Blog",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "14c1462a-97f2-9f6a-7bb7-f5541f23caa6" }, { method: controller2,
          wrap: wrapController2,
          exports: controllerExport2,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":["wix-social-blog","ricos"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/CategoryMenu/controller.ts",
          appName: "Wix Blog",
          appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
          projectName: "communities-blog-ooi",
          componentName: "CategoryMenu",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "a0d7808c-0d7d-4a40-8cf0-911a9f0de96f" }, { method: controller3,
          wrap: wrapController3,
          exports: controllerExport3,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":["wix-social-blog","ricos"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/MyPosts/controller.ts",
          appName: "Wix Blog",
          appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
          projectName: "communities-blog-ooi",
          componentName: "MyPosts",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "e5a2773b-0e6b-4cbb-a012-3b4a69e92046" }, { method: controller4,
          wrap: wrapController4,
          exports: controllerExport4,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":["wix-social-blog","ricos"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Post/controller.ts",
          appName: "Wix Blog",
          appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
          projectName: "communities-blog-ooi",
          componentName: "Post",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "211b5287-14e2-4690-bb71-525908938c81" }, { method: controller5,
          wrap: wrapController5,
          exports: controllerExport5,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":["wix-social-blog","ricos"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/PostList/controller.ts",
          appName: "Wix Blog",
          appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
          projectName: "communities-blog-ooi",
          componentName: "PostList",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "813eb645-c6bd-4870-906d-694f30869fd9" }, { method: controller6,
          wrap: wrapController6,
          exports: controllerExport6,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":["wix-social-blog","ricos"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: true,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/RssButton/controller.ts",
          appName: "Wix Blog",
          appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
          projectName: "communities-blog-ooi",
          componentName: "RssButton",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "1515a9e7-b579-fbbb-43fc-0e3051c14803" }, { method: controller7,
          wrap: wrapController7,
          exports: controllerExport7,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":["wix-social-blog","ricos"]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: true,
          biConfig: null,
          controllerFileName: "/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/TagCloud/controller.ts",
          appName: "Wix Blog",
          appDefinitionId: "14bcded7-0066-7c35-14d7-466cb3f09103",
          projectName: "communities-blog-ooi",
          componentName: "TagCloud",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "c0a125b8-2311-451e-99c5-89b6bba02b22" }],
    true);

    export const createControllers = _createControllers
