import { flowRight, map, toString } from 'lodash';
import { getContentText } from './content-text';
import extractHashtags from './extract-hashtags';

const mapHashtags = (hashtags) =>
  map(hashtags, (hashtagData) => hashtagData.hashtag);
const mapToLowerCase = (hashtags) =>
  map(hashtags, (hashtag) => hashtag.toLowerCase());

export const extractHashtagsFromText = flowRight(
  mapToLowerCase,
  mapHashtags,
  extractHashtags,
  toString,
);

export const extractHashtagsFromContent = flowRight(
  mapToLowerCase,
  mapHashtags,
  extractHashtags,
  getContentText,
);
