import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import LocaleData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ar';
import 'dayjs/locale/bg';
import 'dayjs/locale/cs';
import 'dayjs/locale/da';
import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fi';
import 'dayjs/locale/fr';
import 'dayjs/locale/he';
import 'dayjs/locale/hi';
import 'dayjs/locale/hu';
import 'dayjs/locale/id';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/ka';
import 'dayjs/locale/ko';
import 'dayjs/locale/lt';
import 'dayjs/locale/ms';
import 'dayjs/locale/nl';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sv';
import 'dayjs/locale/th';
import 'dayjs/locale/tl-ph';
import 'dayjs/locale/tr';
import 'dayjs/locale/uk';
import 'dayjs/locale/vi';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/zh-cn';
if (!dayjs._isExtended) {
    dayjs.extend(LocalizedFormat);
    dayjs.extend(LocaleData);
    dayjs.extend(RelativeTime);
    dayjs.extend(IsSameOrBefore);
    dayjs.extend(AdvancedFormat);
    dayjs.extend(Utc);
    dayjs._isExtended = true;
}
export default dayjs;
