import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import withExperiment from '../../hoc/with-experiment';
import {
  getFeedAndCategoryLinks,
  getActiveLink,
} from '../../selectors/category-links-selectors';
import { isSeo } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';
import ChangeCategoryTrigger from './change-category-trigger';
import SeoSelect from './seo-select';
import styles from './change-category.scss';

export const ChangeCategory = ({
  links,
  activeLink,
  onChange,
  isMobileMenuColorMatchDesktopExperimentEnabled,
  isSeo,
}) => {
  const renderSelect = () => {
    return (
      <select
        data-hook="category-dropdown"
        className={styles.select}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={activeLink.path}
      >
        {links.map((link) => (
          <option key={link.key} value={link.path}>
            {link.text}
          </option>
        ))}
      </select>
    );
  };

  if (isSeo) {
    return <SeoSelect activeLink={activeLink} links={links} />;
  }

  if (isMobileMenuColorMatchDesktopExperimentEnabled) {
    return (
      <ChangeCategoryTrigger
        title={activeLink.text}
        arrowDownIconClass="blog-navigation-container-fill"
        containerBackgroundClass="blog-navigation-container-background-color"
        containerFontClass="blog-navigation-container-color"
      >
        {renderSelect()}
      </ChangeCategoryTrigger>
    );
  }

  return (
    <ChangeCategoryTrigger title={activeLink.text}>
      {renderSelect()}
    </ChangeCategoryTrigger>
  );
};

ChangeCategory.propTypes = {
  links: PropTypes.array,
  activeLink: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
  isSeo: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  links: getFeedAndCategoryLinks(state),
  activeLink: getActiveLink(state),
  isSeo: isSeo(state),
  onChange: actions.navigateWithinBlog,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withExperiment({
    isMobileMenuColorMatchDesktopExperimentEnabled:
      EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
  }),
)(ChangeCategory);
