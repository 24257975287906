import CoreApi from '../core/core-api'
import { getAppManifest as coreGetAppManifest } from '../core/manifests/app-manifest'
import { editorAppMetaData } from './editor-app'
import { EditorPlatformApp } from '@wix/platform-editor-sdk'
import { handleError } from './monitoring'
import { EXPERIMENTS } from '../../panels/commons/constants/experiments'
import { PremiumStatus } from '@wix/ambassador-premium-v1-asset/types'
import { PREMIUM_PLAN_LIMITS } from '../../constants/premium'

export const getAppManifest: EditorPlatformApp['getAppManifest'] = async (
  _options,
  _editorSdk,
  _context,
) => {
  const api: CoreApi = await editorAppMetaData.getCoreApi()

  try {
    if (api.isADI()) {
      return {}
    }

    api.interactionStarted('get-app-manifest')

    const isResponsive = api.isResponsive()
    const isAscendToPremiumEnabled = api.isExperimentEnabled(EXPERIMENTS.ASCEND_TO_PREMIUM)

    if (!editorAppMetaData.appManifest) {
      let isTopPremium = false

      if (isAscendToPremiumEnabled) {
        try {
          const { restrictions, currentPlan } = await api.premium.getRestrictions()
          isTopPremium =
            currentPlan === PremiumStatus.PREMIUM &&
            restrictions.forms.limit === PREMIUM_PLAN_LIMITS[PremiumStatus.PREMIUM].formLimit
        } catch (ex) {}
      } else {
        try {
          const currentPlan = await api.premium.getCurrentAscendPlan()
          isTopPremium = currentPlan.isTopPremium
        } catch (ex) {}
      }

      editorAppMetaData.appManifest = coreGetAppManifest({
        isTopPremium,
        isResponsive,
        isAscendToPremiumEnabled,
      })
    }

    try {
      const filteredManifest = await api.filterAppManifestV2(editorAppMetaData.appManifest)

      // console.log('original', new Blob([JSON.stringify(editorAppMetaData.appManifest, null, 2)]).size / 1024, 'KB')
      // console.log(editorAppMetaData.appManifest)

      // console.log('filtered', new Blob([JSON.stringify(filteredManifest, null, 2)]).size / 1024, 'KB')
      // console.log(filteredManifest)

      api.interactionEnded('get-app-manifest')
      return filteredManifest
    } catch (err) {
      handleError(err, {
        extra: { message: 'Failed to load filtered manifest' },
      })

      return editorAppMetaData.appManifest
    }
  } catch (err) {
    handleError(err, {
      extra: { message: 'Failed to load manifest' },
    })
  }
}
