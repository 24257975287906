import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  POST_LIST_SETTINGS_PARAMS,
  getWixDataCategoryId,
  getWixDataTagId,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_UNIFY_POSTS_EMPTY_STATES } from '@wix/communities-blog-experiments';

import EmptyStates from '../../../common/components/empty-states';
import withTranslate from '../../../common/hoc/with-translate';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import {
  getIsEditorSegment,
  isEditor as getIsEditor,
} from '../../../common/store/basic-params/basic-params-selectors';

const NoPosts = ({
  t,
  isMultilingual,
  showFilterWarning,
  showUnifiedStateText,
  ...props
}) => (
  <EmptyStates
    title={
      showUnifiedStateText
        ? t('no-posts-feed.nothing-to-show')
        : showFilterWarning
        ? t('no-posts.filtered.title')
        : isMultilingual
        ? t('no-posts-feed.no-published-posts-multilingual')
        : t('no-posts-feed.on-the-way')
    }
    content={
      showUnifiedStateText
        ? t('no-posts.publish-posts-to-see-them')
        : showFilterWarning
        ? t('no-posts.filtered.subtitle')
        : t('no-posts-feed.stay-tuned')
    }
    {...props}
  />
);

NoPosts.propTypes = {
  t: PropTypes.func,
  isMultilingual: PropTypes.bool,
  showFilterWarning: PropTypes.bool,
};

const mapStateToProps = ({ state }) => {
  const unifiedStatesEnabled = isExperimentEnabled(
    state,
    EXPERIMENT_UNIFY_POSTS_EMPTY_STATES,
  );
  const isEditorSegment = getIsEditorSegment(state);
  const isEditor = getIsEditor(state);
  const isFiltered = Boolean(
    getWixDataCategoryId(state) ||
      getWixDataTagId(state) ||
      getAppSettingsValue({
        state,
        key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath,
      }),
  );
  return {
    isMultilingual: Boolean(getQueryLocale(state)),
    showFilterWarning: isEditorSegment && isFiltered,
    showUnifiedStateText: unifiedStatesEnabled && isEditor,
  };
};

export default flowRight(connect(mapStateToProps), withTranslate)(NoPosts);
