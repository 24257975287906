import { get } from 'lodash';
import { fetchArchive } from '../../../common/actions/fetch-archive';
import { pageOpened } from '../../../common/actions/page-opened';
import { ARCHIVE_PAGE } from '../../../common/services/detect-route';
import { generateArchiveSEOTags } from '../../../common/services/generate-seo-tags/generate-archive-seo-tags';
import { setMetaTagRobotsNoIndex } from '../../../common/services/set-metatag-robots-noindex';
import {
  isSite,
  isSSR,
} from '../../../common/store/basic-params/basic-params-selectors';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { ROUTE_404 } from '../../constants/routes';

export const createArchivePageRouter =
  (store, wixCodeApi) =>
  async ({ params }, redirect) => {
    !isSSR(store.getState()) &&
      store.dispatch(pageOpened({ page: ARCHIVE_PAGE }));

    const { year, month, pageIndex } = params;
    if (year && month) {
      try {
        const posts = await store.dispatch(
          fetchArchive(year, month, pageIndex),
        );

        if (!posts.length) {
          return redirect(ROUTE_404);
        }
        store.dispatch(fetchProfileUrls());

        const state = store.getState();
        const page = parseInt(get(params, 'pageIndex', '1'), 10);
        if (isSite(state)) {
          const sectionUrl = getSectionUrl(state);
          const archiveSEOTags = generateArchiveSEOTags({
            sectionUrl,
            state,
            page,
            posts,
            locale: wixCodeApi.site.regionalSettings,
            year,
            month,
            multilingual: wixCodeApi.window.multilingual,
          });
          wixCodeApi.seo.renderSEOTags(archiveSEOTags);
        } else {
          setMetaTagRobotsNoIndex(wixCodeApi);
        }
      } catch (error) {
        redirect(ROUTE_404);
      }
    }
  };
