import { TinyColor } from '@ctrl/tinycolor';
import { get, find } from 'lodash';
var throwRequired = function (name) {
    throw new Error("".concat(name, " is required"));
};
export var getStyleColor = function (appSettings, wixParam) {
    return get(appSettings, "style.colors[".concat(wixParam, "].value"));
};
export var getPresetColor = function (appSettings, presetName) {
    return get(find(appSettings.colorPresets, function (_a) {
        var reference = _a.reference;
        return reference === presetName;
    }), 'value');
};
export var setColorOpacity = function (color, alpha) {
    if (alpha === void 0) { alpha = 1; }
    return new TinyColor(color).setAlpha(alpha).toRgbString();
};
export var getSiteColor = function (appSettings, _a) {
    var wixParam = _a.wixParam, presetFallback = _a.presetFallback, colorFallback = _a.colorFallback, _b = _a.opacity, opacity = _b === void 0 ? undefined : _b;
    if (!wixParam) {
        throwRequired('wixParam');
    }
    if (!presetFallback) {
        throwRequired('presetFallback');
    }
    if (!colorFallback) {
        throwRequired('colorFallback');
    }
    var color = getStyleColor(appSettings, wixParam) ||
        getPresetColor(appSettings, presetFallback) ||
        colorFallback;
    return opacity !== undefined
        ? setColorOpacity(color, opacity)
        : new TinyColor(color).toRgbString();
};
export var resolveColor = function (color, colorPresets) {
    if (!color) {
        return 'transparent';
    }
    if (!color.includes('{')) {
        return color;
    }
    var currentColor = color.replace(/[\{\}]/g, '');
    var siteColor = find(colorPresets, function (_a) {
        var name = _a.name;
        return name === currentColor;
    });
    return get(siteColor, 'value', 'transparent');
};
