var _a, _b;
export var SOCIAL_SHARING_FACEBOOK = 'facebook';
export var SOCIAL_SHARING_TWITTER = 'twitter';
export var SOCIAL_SHARING_LINKED_IN = 'linked-in';
export var SOCIAL_SHARING_LINK = 'link';
export var SOCIAL_SHARING_PRINT = 'print';
export var SOCIAL_SHARING_PROVIDERS = [
    SOCIAL_SHARING_FACEBOOK,
    SOCIAL_SHARING_TWITTER,
    SOCIAL_SHARING_LINKED_IN,
    SOCIAL_SHARING_LINK,
];
export var SHARE_BUTTON_TRANSLATION_KEYS = (_a = {},
    _a[SOCIAL_SHARING_FACEBOOK] = 'share-button.via-facebook',
    _a[SOCIAL_SHARING_TWITTER] = 'share-button.via-twitter',
    _a[SOCIAL_SHARING_LINKED_IN] = 'share-button.via-linked-in',
    _a[SOCIAL_SHARING_LINK] = 'share-button.via-link',
    _a[SOCIAL_SHARING_PRINT] = 'post-actions.print',
    _a);
export var PROVIDER_TITLE_TRANSLATION_KEYS = (_b = {},
    _b[SOCIAL_SHARING_FACEBOOK] = 'social-sharing.facebook',
    _b[SOCIAL_SHARING_TWITTER] = 'social-sharing.twitter',
    _b[SOCIAL_SHARING_LINKED_IN] = 'social-sharing.linked-in',
    _b[SOCIAL_SHARING_LINK] = 'social-sharing.link',
    _b[SOCIAL_SHARING_PRINT] = 'social-sharing.print-post',
    _b);
