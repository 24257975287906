import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EXPERIMENT_UNIFY_POSTS_EMPTY_STATES } from '@wix/communities-blog-experiments';
import EmptyStates from '../../components/empty-states';
import withExperiment from '../../hoc/with-experiment';
import withTranslate from '../../hoc/with-translate';
import { getQueryLocale } from '../../selectors/locale-selectors';
import { isEditor } from '../../store/basic-params/basic-params-selectors';

const NoPostsFeed = ({
  t,
  isMultilingual,
  isEditor,
  unifyEmptyStatesExperimentEnabled,
  ...props
}) => {
  const title = isMultilingual
    ? t('no-posts-feed.no-published-posts-multilingual')
    : unifyEmptyStatesExperimentEnabled && isEditor
    ? t('no-posts-feed.no-posts-to-show')
    : t('no-posts-feed.on-the-way');

  const content =
    unifyEmptyStatesExperimentEnabled && isEditor
      ? t('no-posts-feed.publish-posts-to-see-them')
      : t('no-posts-feed.stay-tuned');

  return <EmptyStates {...props} title={title} content={content} />;
};

NoPostsFeed.propTypes = {
  t: PropTypes.func,
  isMultilingual: PropTypes.bool,
  isEditor: PropTypes.bool,
};

const mapStateToProps = ({ state }) => ({
  isMultilingual: Boolean(getQueryLocale(state)),
  isEditor: isEditor(state),
});

export default flowRight(
  withExperiment({
    unifyEmptyStatesExperimentEnabled: EXPERIMENT_UNIFY_POSTS_EMPTY_STATES,
  }),
  connect(mapStateToProps),
  withTranslate,
)(NoPostsFeed);
