export const PINNED_LAYER_SUFFIX: string = '-pinned-layer'
export const RESPONSIVE_PAGE_CONTAINER_PREFIX: string = 'Container'
export const SECTIONS_CONTAINER_PREFIX: string = 'PAGE_SECTIONS'
export const MASTER_PAGE_COMPONENTS_IN_RESPONSIVE = [
	'masterPage',
	'SITE_PAGES',
	'SITE_HEADER',
	'SITE_FOOTER',
	'PAGES_CONTAINER',
]
