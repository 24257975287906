import { ROUTER_DATASET } from '@wix/wix-data-client-common/dist/esm/datasetTypes'
import { uniq } from 'lodash'

const mergeDatasetWithRouter = (dataset, routerData) => {
  const { readWriteType } = dataset
  const {
    config: {
      dataset: {
        collectionName: collectionId,
        filter,
        sort,
        includes,
        pageSize,
        lowercase,
      },
    },
    dynamicUrl,
    userDefinedFilter,
  } = routerData

  return {
    ...dataset,
    collectionId,
    filter,
    sort,
    includes,
    pageSize,
    readWriteType,
    dynamicPageData: {
      lowercase,
      dynamicUrl,
      userDefinedFilter,
    },
  }
}

export const createDatasetConfigs = (controllerConfigs, routerData) =>
  controllerConfigs.map(
    ({
      compId: id,
      config: {
        dataset: {
          collectionName: collectionId,
          filter,
          sort,
          includes,
          nested,
          pageSize,
          readWriteType,
          deferred,
          cursor,
        } = {}, // Router controllerConfig may have an empty dataset
      } = {}, // Or an empty config
      // That's how platform sends it to us. It will be filled with config from routerData
      type,
      connections,
      livePreviewOptions: {
        shouldFetchData: dataIsInvalidated,
        compsIdsToReset: updatedCompIds = [],
      } = {},
    }) => {
      const dataset = {
        id,
        type,
        collectionId,
        filter,
        sort,
        pageSize,
        readWriteType,
        includes,
        nested,
        deferred,
        connections,
        dataIsInvalidated,
        updatedCompIds,
        cursor,
      }

      return type === ROUTER_DATASET && routerData
        ? mergeDatasetWithRouter(dataset, routerData)
        : dataset
    },
  )

export const getDistinctOptionsSortModel = ({
  wixData,
  schema,
  sort: customSort,
  fieldName,
}) => {
  const isFieldSortable = schema.fields[fieldName]?.sortable ?? true
  const defaultSort = schema.defaultSort
    ? {
        [schema.defaultSort.fieldName]: schema.defaultSort.direction,
      }
    : null
  const customSortExists = customSort?.length > 0
  const fieldAlreadyBeingSorted =
    customSort?.find(sortObject => sortObject[fieldName]) ||
    schema?.defaultSort?.fieldName === fieldName
  const includeFallbackSort = !fieldAlreadyBeingSorted && isFieldSortable
  const fallbackSort = wixData.sort().ascending(fieldName).getSortModel()

  if (customSortExists) {
    return includeFallbackSort ? [...customSort, ...fallbackSort] : customSort
  } else if (defaultSort) {
    return includeFallbackSort ? [defaultSort, ...fallbackSort] : defaultSort
  } else if (isFieldSortable) {
    return fallbackSort
  }

  return null
}

export const getReferencedItemIdsByOption = (referencedItems, displayField) => {
  const options = uniq(referencedItems.map(item => item[displayField]))

  const referencedItemIdsByOption = Object.fromEntries(
    options.map(option => [
      option,
      referencedItems
        .filter(item => item[displayField] === option)
        .map(item => item._id),
    ]),
  )

  return { options, referencedItemIdsByOption }
}
