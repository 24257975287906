import React, { Component } from 'react';
import classNames from 'classnames';
import { range, omit, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { getLastPage } from '@wix/communities-blog-client-common';

import withTranslate from '../../hoc/with-translate';
import { handleEnterKeyUp } from '../../services/accessibility';
import { ArrowLeftIcon } from '../icons/arrow-left-icon';
import { ArrowRightIcon } from '../icons/arrow-right-icon';
import styles from './pagination.scss';

const PAGE_NUMBERS_TO_SHOW = 5;

const dataHook = (suffix) => `pagination__${suffix}`;

export class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFromProps(props);
  }

  componentDidUpdate({ page, entityCount, pageSize }) {
    if (
      this.props.page !== page ||
      this.props.entityCount !== entityCount ||
      this.props.pageSize !== pageSize
    ) {
      this.setState(this.stateFromProps(this.props));
    }
  }

  stateFromProps({ entityCount, pageSize, page }) {
    return {
      currentPage: page,
      lastPage: getLastPage(entityCount, pageSize),
    };
  }

  render() {
    const { entityCount, pageSize, className, showPosition } = this.props;
    if (entityCount <= pageSize) {
      return null;
    }
    return (
      <div className={className} aria-live="polite">
        {this.renderGoToFirst()}
        {this.renderGoToPrevious()}
        {showPosition ? this.renderPosition() : this.renderPageNumbers()}
        {this.renderGoToNext()}
        {this.renderGoToLast()}
      </div>
    );
  }

  renderPageNumbers() {
    const { currentPage, lastPage } = this.state;
    const sideSize = Math.floor(PAGE_NUMBERS_TO_SHOW / 2);
    let from = 1;
    let to = PAGE_NUMBERS_TO_SHOW;
    if (lastPage < PAGE_NUMBERS_TO_SHOW) {
      to = lastPage;
    } else if (currentPage + sideSize + 1 > lastPage) {
      from = lastPage - sideSize * 2;
      to = lastPage;
    } else if (currentPage > sideSize + 1) {
      from = currentPage - sideSize;
      to = currentPage + sideSize;
    }
    return range(from, to + 1).map(this.renderPageNumber);
  }

  renderPageNumber = (page) => {
    const { activeClass, innerClass, component: Component, t } = this.props;
    const isActive = page === this.state.currentPage;
    const pageClassName = classNames(styles.page, innerClass, {
      [activeClass]: isActive,
      'blog-button-color': isActive,
    });
    const eventProps = !isActive
      ? this.getEventProps(page, Pagination.PAGE)
      : {};
    return (
      <Component
        key={page}
        page={page}
        isActive={isActive}
        className={pageClassName}
        aria-label={t('pagination.page', { page })}
        data-hook={`pagination__${page}`}
        {...eventProps}
      >
        {page}
      </Component>
    );
  };

  renderPosition() {
    const { currentPage, lastPage } = this.state;
    return (
      <div className={this.props.positionClass}>
        {currentPage}/{lastPage}
      </div>
    );
  }

  renderGoToFirst() {
    const { arrowLeft, t } = this.props;
    return this.renderArrows(
      Pagination.FIRST,
      [arrowLeft, arrowLeft],
      1,
      t('pagination.first-page'),
    );
  }

  renderGoToPrevious() {
    const { arrowLeft, t } = this.props;
    const currentPage = this.state.currentPage;
    return this.renderArrows(
      Pagination.PREVIOUS,
      [arrowLeft],
      currentPage - 1,
      t('pagination.previous-page'),
    );
  }

  renderGoToNext() {
    const { arrowRight, t } = this.props;
    return this.renderArrows(
      Pagination.NEXT,
      [arrowRight],
      this.state.currentPage + 1,
      t('pagination.next-page'),
    );
  }

  renderGoToLast() {
    const { arrowRight, t } = this.props;
    return this.renderArrows(
      Pagination.LAST,
      [arrowRight, arrowRight],
      this.state.lastPage,
      t('pagination.last-page'),
    );
  }

  renderArrows(type, arrows, page, label) {
    const shouldShow = this.props.arrowVisibilityRules[type](
      this.state,
      this.props.showPosition,
    );
    if (!shouldShow) {
      return null;
    }
    const {
      arrowEnableRules,
      arrowClass,
      disabledClass,
      showPosition,
      component: Component,
    } = this.props;
    const isEnabled = arrowEnableRules[type](this.state, showPosition);
    const className = classNames(
      styles[type],
      arrowClass,
      !isEnabled && disabledClass,
    );
    const [arrow1, arrow2] = arrows;
    const eventProps = isEnabled ? this.getEventProps(page, type) : {};
    return (
      <Component
        page={page}
        isDisabled={!isEnabled}
        className={className}
        aria-label={label}
        data-hook={dataHook(type)}
        {...eventProps}
      >
        {arrow1}
        {arrow2}
      </Component>
    );
  }

  getEventProps(page, type) {
    if (!isFunction(this.props.onChange)) {
      return {};
    }
    const handleClick = this.handleClick(page, type);
    return {
      onClick: handleClick,
      onKeyUp: handleEnterKeyUp(handleClick),
      tabIndex: '0',
    };
  }

  handleClick = (page, buttonType) => {
    const { onChange, pageSize } = this.props;
    return (e) => {
      e.preventDefault();
      this.setState({ currentPage: page });
      const from = (page - 1) * pageSize;
      const to = page * pageSize;
      onChange && onChange({ page, from, to, buttonType });
      return false;
    };
  };
}

Pagination.FIRST = 'first';
Pagination.LAST = 'last';
Pagination.NEXT = 'next';
Pagination.PREVIOUS = 'previous';
Pagination.PAGE = 'page';

Pagination.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  entityCount: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  component: PropTypes.func.isRequired,
  activeClass: PropTypes.string,
  disabledClass: PropTypes.string,
  arrowClass: PropTypes.string,
  positionClass: PropTypes.string,
  innerClass: PropTypes.string,
  arrowLeft: PropTypes.node,
  arrowRight: PropTypes.node,
  arrowEnableRules: PropTypes.object.isRequired,
  arrowVisibilityRules: PropTypes.object.isRequired,
  showPosition: PropTypes.bool.isRequired,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  page: 1,
  entityCount: 0,
  showPosition: false,
  arrowLeft: <ArrowLeftIcon className="blog-icon-fill" />,
  arrowRight: <ArrowRightIcon className="blog-icon-fill" />,
  arrowEnableRules: {
    [Pagination.FIRST]: ({ currentPage }, showPosition) =>
      currentPage > (showPosition ? 1 : 3),
    [Pagination.PREVIOUS]: ({ currentPage }) => currentPage > 1,
    [Pagination.NEXT]: ({ currentPage, lastPage }) => currentPage < lastPage,
    [Pagination.LAST]: ({ currentPage, lastPage }, showPosition) =>
      currentPage < lastPage - (showPosition ? 0 : 2),
  },
  arrowVisibilityRules: {
    [Pagination.FIRST]: ({ lastPage }, showPosition) =>
      lastPage > (showPosition ? 2 : PAGE_NUMBERS_TO_SHOW),
    [Pagination.PREVIOUS]: () => true,
    [Pagination.NEXT]: () => true,
    [Pagination.LAST]: ({ lastPage }, showPosition) =>
      lastPage > (showPosition ? 2 : PAGE_NUMBERS_TO_SHOW),
  },
  component: Page,
};

function Page({ children, ...props }) {
  return (
    <div {...omit(props, ['page', 'isDisabled', 'isActive'])}>{children}</div>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  page: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default withTranslate(Pagination);
