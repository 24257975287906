import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getIsInIFrame } from '../selectors/is-in-iframe';
import { initWindowResize } from '../services/communities-window-resize';
import { getHasReportedAppLoad } from '../store/app-loaded/app-loaded-selectors';

export const withCommunitiesWindowResize = (WrappedComponent) => {
  class WithCommunitiesWindowResize extends React.Component {
    startListening = () => {};
    stopListening = () => {};
    isListening = false;

    onWindowResizeInit = ({ startListening, stopListening }) => {
      this.isListening = true;

      this.startListening = () => {
        if (this.isListening) {
          return;
        }
        this.isListening = true;
        startListening();
      };

      this.stopListening = () => {
        if (!this.isListening) {
          return;
        }
        stopListening();
        this.isListening = false;
      };
    };

    handleNavigation = () => {
      Wix.getCurrentPageId((pageId) => {
        if (pageId === this.props.host.pageId) {
          this.startListening();
        } else {
          this.stopListening();
        }
      });
    };

    componentDidMount() {
      if (!getIsInIFrame(this.props.state)) {
        return;
      }

      Wix.addEventListener(Wix.Events.PAGE_NAVIGATION, this.handleNavigation);
    }

    componentDidUpdate(prevProps) {
      if (!getIsInIFrame(this.props.state)) {
        return;
      }

      const appLoaded = getHasReportedAppLoad(this.props.state);
      if (appLoaded && appLoaded !== getHasReportedAppLoad(prevProps.state)) {
        initWindowResize(this.onWindowResizeInit);
      }
    }

    componentWillUnmount() {
      Wix.removeEventListener(
        Wix.Events.PAGE_NAVIGATION,
        this.handleNavigation,
      );
      this.stopListening();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithCommunitiesWindowResize, WrappedComponent);

  return WithCommunitiesWindowResize;
};
