import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import RssIcon from '../rss-icon';
import styles from './rss-button.scss';

const ICON_TYPE_PARAM = 'widget-iconBackgroundType';

class RssButton extends React.Component {
  disableClick = (event) => event.preventDefault();

  render() {
    const { rssFeedLink, host } = this.props;
    const iconType = get(host, `style.styleParams.numbers.${ICON_TYPE_PARAM}`);
    const conditionalProps = rssFeedLink
      ? {}
      : {
          onClick: this.disableClick,
          className: styles.disableClick,
        };
    return (
      <a
        href={rssFeedLink}
        target="_blank"
        rel="noopener noreferrer"
        data-hook="blog-loaded-indicator"
        {...conditionalProps}
      >
        <RssIcon iconType={iconType} />
      </a>
    );
  }
}

RssButton.propTypes = {
  rssFeedLink: PropTypes.string,
};

RssButton.defaultProps = {
  rssFeedLink: '',
};

export default RssButton;
