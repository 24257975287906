import React from 'react';
import PropTypes from 'prop-types';

import LinkText from '../../../common/components/link-text';
import { connect } from '../../../common/components/runtime-context';
import LinkList from '../../../link-list';
import { getCategoryMenu } from '../../aggregated-category-menu/selectors';
import {
  getShowPostCount,
  getUseMobileDesignSettings,
  getUseMobileLayoutSettings,
} from '../../selectors/category-menu-selectors';

const CategoryMenu = ({
  categoryMenu,
  showPostCount,
  useMobileDesignSettings,
  useMobileLayoutSettings,
}) => (
  <nav aria-label={categoryMenu.areaLabel}>
    <LinkList
      links={categoryMenu.categoryMenu.map((category) => ({
        key: category.id,
        path: category.path,
        text: (
          <LinkText
            text={category.display.text}
            postCount={showPostCount ? category.display.postCount : null}
            a11yText={category.display.a11yText}
          />
        ),
      }))}
      emptyState={{ text: categoryMenu.emptyState }}
      useMobileDesignSettings={useMobileDesignSettings}
      useMobileLayoutSettings={useMobileLayoutSettings}
    />
  </nav>
);

CategoryMenu.propTypes = {
  t: PropTypes.func.isRequired,
  categoryMenu: PropTypes.array.isRequired,
  showPostCount: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => {
  return {
    categoryMenu: getCategoryMenu(state),
    showPostCount: getShowPostCount(state),
    useMobileDesignSettings: getUseMobileDesignSettings(state),
    useMobileLayoutSettings: getUseMobileLayoutSettings(state),
  };
};

export default connect(mapRuntimeToProps)(CategoryMenu);
