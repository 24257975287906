import { openModal, closeModal } from './store/modal-actions';
import { NAME } from './store/modal-state-name';
import { modal } from './store/reducer';

export { createModalOpenedMiddleware } from './store/modal-opened-middleware';
export { createModalClosedMiddleware } from './store/modal-closed-middleware';

export const modalActions = {
  openModal,
  closeModal,
};

export const modalReducer = { [NAME]: modal };
