import CoreApi from '../core-api'
import _ from 'lodash'
import {
  STEP_ROLE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  THANK_YOU_STEP_ROLE,
  ROLE_SUBMIT_BUTTON,
  FIELDS,
  ROLE_MESSAGE,
  LIMIT_SUBMISSIONS_STEP_ROLE,
} from '../../../constants/roles'
import { STEP_POSITION } from './constants'
import {
  getStepPosition,
  isNavigationButton,
  isSortableStep,
  findPrimaryConnection,
  getReorderedSteps,
  getStepTitle,
  isFirstStep,
  isStepIsLast,
  getFieldsLabelsForDuplication,
  getFieldLabelForDuplication,
  isNonMessageStep,
  isMessageStep,
  getMessagesStepCount,
  getNonMessagesStep,
  getNonMessagesStepCount,
  getMesssageStepIndexOf,
} from './utils'
import { COMPONENT_TYPES, FieldNameType, FormPlugin } from '@wix/forms-common'
import { FormPreset } from '../../../constants/form-types'
import translations from '../../../utils/translations'
import {
  getComponentByRole,
  fetchPreset,
  convertPreset,
  connectComponentToConnection,
  limitComponentInContainer,
  fetchThankYouStepSchema,
  fetchMultiStepNavigationButtonSchema,
} from '../services/form-service'
import { isInputField, componentRefToString, getPrimaryConnectionFromStructure } from '../utils'
import { undoable, withBi } from '../decorators'
import { SPACE_BETWEEN_FIELDS } from '../fields/api'
import { MULTI_STEP_BUTTON_SIDE_MARGIN } from '../layout/constants/layout-settings'
import { EVENTS } from '../../../constants/bi'
import previousButtonStructure from '../../../assets/presets/previous-button.json'
import nextButtonStructure from '../../../assets/presets/next-button.json'
import { getDuplicatedFieldsConfig } from '../fields/utils'
import { getPlugins, convertPluginsToFormsPlugins } from '../plugins/utils'
import { PanelEventName } from '../manage-panels/consts/panel-event-names'
import { OwnManageStepsPanelPropsProps } from '../../../panels/manage-steps-panel/components/manage-steps-panel'
import { TABS } from '../../../panels/form-settings-panel/constants'
import { PanelName } from '../../../constants/panel-names'

const EMPTY_ACTION = { add: () => Promise.resolve(), remove: () => Promise.resolve() }

export default class StepsApi {
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private biLogger: any
  private ravenInstance

  private thankYouStepSchemaByFormRef: { [key: string]: any }
  private limitStepSchemaByFormRef: { [key: string]: any }

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, ravenInstance }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.thankYouStepSchemaByFormRef = {}
    this.limitStepSchemaByFormRef = {}
    this.biLogger = biLogger
    this.ravenInstance = ravenInstance
  }

  private _getMessageStepByFormRef(stepsContainerRef: ComponentRef, role: string) {
    // TODO EditorX: Change componentRefToString to support it correctly with getFormId
    if (role === THANK_YOU_STEP_ROLE) {
      return this.thankYouStepSchemaByFormRef[componentRefToString(stepsContainerRef)]
    } else if (role === LIMIT_SUBMISSIONS_STEP_ROLE) {
      return this.limitStepSchemaByFormRef[componentRefToString(stepsContainerRef)]
    }
  }

  private async _setMessageStepByFormRef(
    stepsContainerRef: ComponentRef,
    stepRef: ComponentRef,
    role: string,
  ) {
    if (!stepRef) {
      return
    }
    const formComponentRefStr = componentRefToString(stepsContainerRef)
    const serializedStep = await this.boundEditorSDK.components.serialize({
      componentRef: stepRef,
    })
    if (role === THANK_YOU_STEP_ROLE) {
      this.thankYouStepSchemaByFormRef[formComponentRefStr] = serializedStep
    } else if (role === LIMIT_SUBMISSIONS_STEP_ROLE) {
      this.limitStepSchemaByFormRef[formComponentRefStr] = serializedStep
    }
    return
  }

  public selectStep(stepsContainer: ComponentRef, index: number): Promise<void> {
    return this.boundEditorSDK.components.behaviors.execute({
      componentRef: stepsContainer,
      behaviorName: 'changeState',
      behaviorParams: { stateIndex: index },
    })
  }

  private async _selectStepByComponentRef(
    stepsContainer: ComponentRef,
    stepComponentRef: ComponentRef,
  ): Promise<void> {
    const steps = await this.getSteps(stepsContainer)
    const stepIndex = _.findIndex(steps, (step) => _.isEqual(step.componentRef, stepComponentRef))

    return this.boundEditorSDK.components.behaviors.execute({
      componentRef: stepsContainer,
      behaviorName: 'changeState',
      behaviorParams: { stateIndex: stepIndex },
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.MANAGE_STEPS].DRAG_STEP })
  public async reorderSteps(
    stepsContainerRef: ComponentRef,
    stepsData: StepData[],
    srcIndex: number,
    destIndex: number,
    _biData = {},
  ): Promise<{ stepsData: StepData[]; selectedIndex: number }> {
    if (srcIndex === destIndex) {
      return { stepsData, selectedIndex: -1 }
    }

    const [{ currentIndex }, multiStepFormData] = await Promise.all([
      this.boundEditorSDK.components.behaviors.getRuntimeState({
        componentRef: stepsContainerRef,
      }) as Promise<any>,
      this.boundEditorSDK.components.serialize({ componentRef: stepsContainerRef }),
    ])
    const { currentStep: currentStepNewIndex, stepsData: reorderedSteps } = getReorderedSteps(
      stepsData,
      srcIndex,
      destIndex,
      stepsData[currentIndex].componentRef,
    )

    await this._updateStepsTitles(reorderedSteps)

    await Promise.all([
      this._changeStepsOrder(stepsData, srcIndex, destIndex),
      this._fixStepsNavigationButtons(reorderedSteps, multiStepFormData),
      this.selectStep(stepsContainerRef, currentStepNewIndex),
    ])

    const updatedSteps = await this.getSteps(stepsContainerRef)

    await this._updateConnectionConfigStepsOrder(stepsContainerRef, updatedSteps)

    return { stepsData: updatedSteps, selectedIndex: currentStepNewIndex }
  }

  private _changeStepsOrder(stepsData: StepData[], srcIndex: number, destIndex: number) {
    return this.coreApi.makeActionOnMobileAndDesktop(
      stepsData[srcIndex].componentRef,
      (stepRef: ComponentRef) =>
        this.boundEditorSDK.components.arrangement.moveToIndex({
          componentRef: stepRef,
          index: destIndex,
        }),
    )
  }

  private async _fixStepsNavigationButtons(
    stepsData: StepData[],
    multiStepFormData: ComponentStructure,
  ) {
    const [ previousButton, nextButton, submitButton ] = await this._getNavigationButtons(
      multiStepFormData,
      [ ROLE_PREVIOUS_BUTTON, ROLE_NEXT_BUTTON, ROLE_SUBMIT_BUTTON ],
    )

    const stepsWithPositions = stepsData.map((step: StepData, index) => ({
      ...step,
      position: getStepPosition(stepsData, index),
    }))

    const stepsWithoutMessageSteps = _.filter(stepsWithPositions, (stepDataWithPosition) =>
      isNonMessageStep(stepDataWithPosition.role),
    )

    const actionsToPerformPerStep = await Promise.all(
      _.map(stepsWithoutMessageSteps, ({ componentRef, position }) =>
        this._fixStepNavigation(componentRef, position, {
          previousButton,
          nextButton,
          submitButton,
        }),
      ),
    )

    const actionsToPerform = _.reduce(
      actionsToPerformPerStep,
      (result, value) => {
        return {
          add: _.concat(result.add, value.add),
          remove: _.concat(result.remove, value.remove),
        }
      },
      { add: [], remove: [] },
    )

    await Promise.all(_.map(actionsToPerform.add, (add) => add()))
    await Promise.all(_.map(actionsToPerform.remove, (remove) => remove()))
  }

  private async _fixStepNavigation(
    stepComponentRef: ComponentRef,
    stepPos: string,
    {
      previousButton,
      nextButton,
      submitButton,
    }: {
      previousButton: ComponentStructure
      nextButton: ComponentStructure
      submitButton: ComponentStructure
    },
  ) {
    const [[submitButtonRef], [previousButtonRef], [nextButtonRef]] = await Promise.all([
      this.coreApi.findChildComponentsByRole(stepComponentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.findChildComponentsByRole(stepComponentRef, ROLE_PREVIOUS_BUTTON),
      this.coreApi.findChildComponentsByRole(stepComponentRef, ROLE_NEXT_BUTTON),
    ])

    if (stepPos === STEP_POSITION.OTHER) {
      return this._fixRegularStep({
        stepComponentRef,
        previousButtonRef,
        submitButtonRef,
        previousButton,
        nextButton,
      })
    }

    if (stepPos === STEP_POSITION.FIRST_STEP) {
      return this._fixFirstStep({
        stepComponentRef,
        previousButtonRef,
        submitButtonRef,
        nextButton,
      })
    }

    if (stepPos === STEP_POSITION.FIRST_AND_LAST_STEP) {
      return this._fixFirstLastStep({
        stepComponentRef,
        previousButtonRef,
        nextButtonRef,
        submitButton,
      })
    }

    if (stepPos === STEP_POSITION.LAST_STEP) {
      return this._fixLastStep({
        stepComponentRef,
        previousButtonRef,
        nextButtonRef,
        previousButton,
        submitButton,
      })
    }
  }

  private async _fixRegularStep({
    stepComponentRef,
    previousButtonRef,
    submitButtonRef,
    previousButton,
    nextButton,
  }: {
    stepComponentRef: ComponentRef
    previousButtonRef: ComponentRef
    submitButtonRef: ComponentRef
    previousButton: ComponentStructure
    nextButton: ComponentStructure
  }): Promise<any> {
    const transformActions = submitButtonRef
      ? await this._transformButtonToRole(stepComponentRef, submitButtonRef, nextButton)
      : EMPTY_ACTION

    const addPreviousAction = () =>
      this._addPreviousButtonIfNotExists(stepComponentRef, previousButtonRef, previousButton)

    return {
      add: [transformActions.add, addPreviousAction],
      remove: [transformActions.remove],
    }
  }

  private async _addPreviousButtonIfNotExists(stepComponentRef, previousRef, buttonStructure) {
    return previousRef
      ? Promise.resolve()
      : this._addPreviousButton(stepComponentRef, buttonStructure)
  }

  private async _addPreviousButton(stepComponentRef, buttonStructure) {
    // const bottomY = buttonStructure.layout.y + buttonStructure.height + SPACE_BETWEEN_FIELDS
    // const { height: stepHeight } = await this.boundEditorSDK.components.layout.get({
    //   componentRef: stepComponentRef,
    // })

    // if (bottomY > stepHeight) {
    //   await this.coreApi.addHeightToContainers(stepComponentRef, bottomY - stepHeight)
    // }

    await this.boundEditorSDK.components.add({
      componentDefinition: buttonStructure,
      pageRef: stepComponentRef,
    })

    return this.coreApi.fields.reLayoutPreviousButton(stepComponentRef)
  }

  private async _fixFirstStep({
    stepComponentRef,
    previousButtonRef,
    submitButtonRef,
    nextButton,
  }: {
    stepComponentRef: ComponentRef
    previousButtonRef: ComponentRef
    submitButtonRef: ComponentRef
    nextButton: ComponentStructure
  }) {
    const transformActions = submitButtonRef
      ? await this._transformButtonToRole(stepComponentRef, submitButtonRef, nextButton)
      : EMPTY_ACTION

    const removePreviousAction = () => this.coreApi.removeComponentRef(previousButtonRef)

    return {
      add: [transformActions.add],
      remove: [transformActions.remove, removePreviousAction],
    }
  }

  private async _fixFirstLastStep({
    stepComponentRef,
    previousButtonRef,
    nextButtonRef,
    submitButton,
  }: {
    stepComponentRef: ComponentRef
    previousButtonRef: ComponentRef
    nextButtonRef: ComponentRef
    submitButton: ComponentStructure
  }) {
    const transformActions = nextButtonRef
      ? await this._transformButtonToRole(stepComponentRef, nextButtonRef, submitButton)
      : EMPTY_ACTION

    const removePreviousAction = () => this.coreApi.removeComponentRef(previousButtonRef)

    return {
      add: [transformActions.add],
      remove: [transformActions.remove, removePreviousAction],
    }
  }

  private async _fixLastStep({
    stepComponentRef,
    previousButtonRef,
    nextButtonRef,
    previousButton,
    submitButton,
  }: {
    stepComponentRef: ComponentRef
    previousButtonRef: ComponentRef
    nextButtonRef: ComponentRef
    previousButton: ComponentStructure
    submitButton: ComponentStructure
  }) {
    const transformActions = nextButtonRef
      ? await this._transformButtonToRole(stepComponentRef, nextButtonRef, submitButton)
      : EMPTY_ACTION

    const addPreviousAction = () =>
      this._addPreviousButtonIfNotExists(stepComponentRef, previousButtonRef, previousButton)

    return {
      add: [transformActions.add, addPreviousAction],
      remove: [transformActions.remove],
    }
  }

  private async _transformButtonToRole(
    stepRef: ComponentRef,
    buttonRef: ComponentRef,
    destButton: ComponentStructure,
  ) {
    const { x, y } = await this.boundEditorSDK.components.layout.get({ componentRef: buttonRef })

    return {
      add: () =>
        this.boundEditorSDK.components.add({
          pageRef: stepRef,
          componentDefinition: _.merge({}, destButton, { layout: { x, y } }) as any,
        }),
      remove: () => this.boundEditorSDK.components.remove({ componentRef: buttonRef }),
    }
  }

  public async getSteps(stepsContainer: ComponentRef): Promise<StepData[]> {
    const children: ComponentRef[] = await this.boundEditorSDK.components.getChildren({
      componentRef: stepsContainer,
    })
    const stepsConnections = await this.boundEditorSDK.components.get({
      componentRefs: children,
      properties: ['connections'],
    })

    return _.compact(
      stepsConnections.map((child) => {
        const {
          config: { title },
          role,
        } = _.find(child.connections, { isPrimary: true })
        if (_.eq(role, STEP_ROLE) || isMessageStep(role)) {
          return { componentRef: child.componentRef, title, role }
        }
        return null
      }),
    )
  }

  public updateStepTitle(step: ComponentRef, title): Promise<void> {
    return this.boundEditorSDK.application.sessionState.update({
      stateMap: { [step.id]: title },
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.MANAGE_STEPS].EDIT_STEP })
  public async renameStep(
    stepsContainerRef: ComponentRef,
    currentStepRef,
    { label, id },
    dropDownTitle,
    _biData = {},
  ): Promise<{ stepsData: StepData[] }> {
    await this.updateStepTitle(currentStepRef, dropDownTitle)
    await this.selectStep(currentStepRef, id)
    await this.coreApi.setComponentConnection(currentStepRef, { title: label })

    const updatedSteps = await this.getSteps(stepsContainerRef)
    return { stepsData: updatedSteps }
  }

  private async _isCrucialButtonMissing(formComponentRef, role, tab) {
    const stepsData: StepData[] = await this.getSteps(formComponentRef)

    const messagesStepsCount = getMessagesStepCount(stepsData)

    const { controllerRef } = await this.coreApi.getComponentConnection(formComponentRef)
    const buttons = await this.coreApi.findConnectedComponentsByRole(controllerRef, role)
    const expectedButtonsCount = _.size(stepsData) - (1 + messagesStepsCount)
    const actualButtonsCount = _.size(buttons)
    const isMissing = actualButtonsCount < expectedButtonsCount
    return isMissing ? { type: FieldNameType.ROLE, name: role, tab } : null
  }

  public isPreviousButtonMissing(formComponentRef: ComponentRef) {
    return this._isCrucialButtonMissing(formComponentRef, ROLE_PREVIOUS_BUTTON, TABS.SUBMIT_MESSAGE)
  }

  public isNextButtonMissing(formComponentRef: ComponentRef) {
    return this._isCrucialButtonMissing(formComponentRef, ROLE_NEXT_BUTTON, TABS.SUBMIT_MESSAGE)
  }

  private async _restoreNavigationButtonForStep({
    formComponentRef,
    stepComponentRef,
    fallbackSchema,
    role,
  }) {
    const label = translations.t(`preset.${_.camelCase(role)}Label`)

    const createButton = async (preset: FormPreset, locale, _boxLayout) =>
      fetchMultiStepNavigationButtonSchema(this.ravenInstance)(
        { label, preset, locale, role, fallbackSchema },
        (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
      )

    await this.coreApi.fields.restoreCrucialElement(
      formComponentRef,
      createButton,
      stepComponentRef,
    )
  }

  private async _restoreCrucialNavigationButton({
    formComponentRef,
    stepsData,
    role,
    fallbackSchema,
    reLayout,
  }: {
    formComponentRef: ComponentRef
    stepsData: StepData[]
    role: string
    fallbackSchema
    reLayout: Function
  }) {
    const stepWithPossibleMissingNavigationButton = await Promise.all(
      _.map(stepsData, async (stepData) => {
        const component = await this.coreApi.findChildComponentsByRole(stepData.componentRef, role)
        return {
          missingNavigationButton: _.isEmpty(component),
          componentRef: stepData.componentRef,
        }
      }),
    )

    return stepWithPossibleMissingNavigationButton.reduce(
      (previousPromise, nextStep) =>
        previousPromise.then(async () => {
          if (!nextStep.missingNavigationButton) {
            return Promise.resolve()
          }

          await this._selectStepByComponentRef(formComponentRef, nextStep.componentRef)
          await this._restoreNavigationButtonForStep({
            formComponentRef,
            stepComponentRef: nextStep.componentRef,
            role,
            fallbackSchema,
          })

          await reLayout(nextStep.componentRef)
          return this.coreApi.fields.updateFormHeightIfNeeded(nextStep.componentRef)
        }),
      Promise.resolve(),
    )
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.FORM_SETTINGS].RESTORE_CRUCIAL_ELEMENTS })
  public async restorePreviousButton(formComponentRef: ComponentRef, _biData = {}) {
    const stepsData: StepData[] = await this.getSteps(formComponentRef)
    const stepsWithoutFirstAndMessageSteps = getNonMessagesStep(_.slice(stepsData, 1))

    return this._restoreCrucialNavigationButton({
      formComponentRef,
      stepsData: stepsWithoutFirstAndMessageSteps,
      role: ROLE_PREVIOUS_BUTTON,
      fallbackSchema: previousButtonStructure,
      reLayout: (stepComponentRef) => this.coreApi.fields.reLayoutPreviousButton(stepComponentRef),
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.FORM_SETTINGS].RESTORE_CRUCIAL_ELEMENTS })
  public async restoreNextButton(formComponentRef: ComponentRef, _biData = {}) {
    const stepsData: StepData[] = await this.getSteps(formComponentRef)
    const stepsWithoutMessagesStep = getNonMessagesStep(stepsData)
    const stepsWithoutLastStepAndMessagesStep = _.slice(
      stepsWithoutMessagesStep,
      0,
      stepsWithoutMessagesStep.length - 1,
    )

    return this._restoreCrucialNavigationButton({
      formComponentRef,
      stepsData: stepsWithoutLastStepAndMessagesStep,
      role: ROLE_NEXT_BUTTON,
      fallbackSchema: nextButtonStructure,
      reLayout: (stepComponentRef) => this.coreApi.fields.reLayoutNextButton(stepComponentRef),
    })
  }

  private _updateStepsTitles(stepsData: StepData[], nonSortableSteps = 1) {
    const numOfSortableSteps = stepsData.length - nonSortableSteps
    const stateTitlesMap = stepsData.reduce((acc, stepData, i) => {
      const title = isSortableStep(stepData)
        ? `${i + 1}/${numOfSortableSteps} - ${stepData.title}`
        : stepData.title
      acc[stepData.componentRef.id] = title
      return acc
    }, {})

    return this.boundEditorSDK.application.sessionState.update({
      stateMap: stateTitlesMap,
    })
  }

  public async updateMultiStepFormTitles(stepsContainerRef: ComponentRef) {
    const stepsData: StepData[] = await this.getSteps(stepsContainerRef)
    getMessagesStepCount(stepsData)

    return this._updateStepsTitles(stepsData, getMessagesStepCount(stepsData))
  }

  public async updateMultiStepFormsTitles(
    controllers: { controllerRef: ComponentRef }[],
  ): Promise<any> {
    const multiStepForms = await this._getMultiStepForms(controllers)
    return Promise.all(
      multiStepForms.map((formRef: ComponentRef) => this.updateMultiStepFormTitles(formRef)),
    )
  }

  private async _getMultiStepForms(
    controllers: { controllerRef: ComponentRef }[],
  ): Promise<ComponentRef[]> {
    const multiStepForms = await Promise.all(
      controllers.map(({ controllerRef }) =>
        this.coreApi.findConnectedComponentsByPlugin(controllerRef, FormPlugin.MULTI_STEP_FORM),
      ),
    )
    return multiStepForms.filter((forms) => forms && forms.length).map((forms) => forms[0])
  }

  public async getCurrentStateIndex(componentRef: ComponentRef): Promise<number> {
    const currentState = (await this.boundEditorSDK.components.behaviors.getRuntimeState({
      componentRef,
    })) as any
    return currentState.currentIndex
  }

  public async getLastStepIndex(componentRef: ComponentRef): Promise<number> {
    const steps = await this.coreApi.findChildComponentsByRole(componentRef, [STEP_ROLE])
    return steps.length - 1
  }

  public async getCurrentStepRef(componentRef): Promise<ComponentRef> {
    const componentType = await this.boundEditorSDK.components.getType({ componentRef })
    if (componentType !== COMPONENT_TYPES.STATE_BOX) {
      return null
    }

    const currentStateIndex = await this.getCurrentStateIndex(componentRef)
    const children = await this.boundEditorSDK.components.getChildren({ componentRef })
    return children[currentStateIndex]
  }

  private async _getFormConnectionItem(
    stepsContainerRef: ComponentRef,
  ): Promise<ComponentConnectionItem> {
    const formConnection = await this.coreApi.getComponentConnection(stepsContainerRef)
    const formData = (await this.boundEditorSDK.components.data.get({
      componentRef: formConnection.controllerRef,
    })) as any

    const formConnectionItem: ComponentConnectionItem = {
      type: 'ConnectionItem',
      role: formConnection.role,
      config: JSON.stringify(formConnection.config),
      isPrimary: formConnection.isPrimary,
      controllerId: formData.id,
    }
    return formConnectionItem
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.MANAGE_STEPS].EDIT_STEP })
  public async deleteStep(
    stepsContainerRef: ComponentRef,
    stepsData: StepData[],
    deletedIndex: number,
    _biData = {},
  ): Promise<{ stepsData: StepData[]; selectedIndex: number }> {
    const selectedIndex = deletedIndex ? deletedIndex - 1 : deletedIndex
    const deletedStepRef = stepsData[deletedIndex].componentRef
    const deletedStepPosition = getStepPosition(stepsData, deletedIndex)

    switch (deletedStepPosition) {
      case STEP_POSITION.LAST_STEP: {
        const formConnection = await this._getFormConnectionItem(stepsContainerRef)
        await this._makeRegularStepLastStep(
          stepsData[deletedIndex - 1].componentRef,
          formConnection,
        )
        break
      }
      case STEP_POSITION.FIRST_STEP: {
        await this._makeRegularStepFirstStep(stepsData[deletedIndex + 1].componentRef)
        break
      }
    }

    await this.boundEditorSDK.components.remove({ componentRef: deletedStepRef })
    await this.updateMultiStepFormTitles(stepsContainerRef)
    await this.selectStep(stepsContainerRef, selectedIndex)

    const updatedSteps = await this.getSteps(stepsContainerRef)

    await this._updateConnectionConfigStepsOrder(stepsContainerRef, updatedSteps)

    return { stepsData: updatedSteps, selectedIndex }
  }

  public loadInitialPanelData(
    formComponentRef: ComponentRef,
    componentConnection: ComponentConnection,
  ): Promise<Partial<OwnManageStepsPanelPropsProps>> {
    const preset = _.get(componentConnection, 'config.preset')
    const plugins = getPlugins(componentConnection)

    return Promise.all([
      this.getSteps(formComponentRef),
      this.coreApi.premium.getRestrictions(),
      this.getCurrentStateIndex(formComponentRef),
      this.coreApi.settings.getCrucialElements(formComponentRef, componentConnection),
    ]).then(
      ([stepsData, { restrictions, currentPlan }, selectedStepIndex, missingFields]) => ({
        stepsData,
        restrictions,
        selectedStepIndex,
        missingFields,
        formComponentRef,
        preset,
        plugins: convertPluginsToFormsPlugins(plugins),
        currentPlan,
      }),
    )
  }

  private async _getPreset(formConnection: ComponentConnectionItem) {
    const { preset: presetKey } = JSON.parse(formConnection.config)
    const locale = await this.boundEditorSDK.info.getLanguage()
    const rawPreset = await fetchPreset(this.ravenInstance)(presetKey, locale, (reason) =>
      this.coreApi.logFetchPresetsFailed(null, reason),
    )
    if (!rawPreset) {
      return
    }
    return convertPreset(rawPreset, {
      controllerId: formConnection.controllerId,
    })
  }

  private _convertSubmitButtonToNavigationButton(
    presetStructure: ComponentStructure,
    role: string,
  ) {
    const submitButton = getComponentByRole(presetStructure, ROLE_SUBMIT_BUTTON)
    const label =
      role === ROLE_PREVIOUS_BUTTON
        ? previousButtonStructure.data.label
        : nextButtonStructure.data.label // TODO: translate
    const y =
      role === ROLE_PREVIOUS_BUTTON
        ? submitButton.layout.y - 15 - submitButton.layout.height
        : submitButton.layout.y

    return {
      ...submitButton,
      data: { ...submitButton.data, label },
      connections: {
        ...submitButton.connections,
        items: [{ ...submitButton.connections.items[0], role }],
      },
      layout: { ...submitButton.layout, y },
      props: { ...submitButton.props, metaData: undefined, id: undefined },
    }
  }

  private async _getNavigationButtonsFromPreset(
    formConnection: ComponentConnectionItem,
    rolesToSearch: string[],
    fetchedPresetStructure?: ComponentStructure,
  ) {
    const presetStructure = fetchedPresetStructure || (await this._getPreset(formConnection))
    if (!presetStructure) {
      return []
    }

    return rolesToSearch
      .map((role) => getComponentByRole(presetStructure, role))
      .map(
        (component, index) =>
          component ||
          this._convertSubmitButtonToNavigationButton(presetStructure, rolesToSearch[index]),
      )
  }

  private _getCoupledNavigationButtonsFromSteps(
    allSteps: ComponentStructure[],
    rolesToSearch: string[],
  ): ComponentStructure[] {
    const stepWithBothButtons: ComponentStructure = _.find(allSteps, (stepStructure: any) => {
      const [leftButton, rightButton] = this._getNavigationButtonsFromStep(
        stepStructure,
        rolesToSearch,
      )
      return leftButton && rightButton
    })

    return stepWithBothButtons
      ? this._getNavigationButtonsFromStep(stepWithBothButtons, rolesToSearch)
      : []
  }

  private _getCoupledNavigationButtonsFromLastStep(
    allSteps: ComponentStructure[],
  ): ComponentStructure[] {
    const rolesToSearch = [ROLE_PREVIOUS_BUTTON, ROLE_SUBMIT_BUTTON]
    const lastStep = _.findLast(allSteps, (stepStructure) => {
      const primaryConnection = getPrimaryConnectionFromStructure(stepStructure)
      return isNonMessageStep(_.get(primaryConnection, 'role'))
    })

    return this._getNavigationButtonsFromStep(lastStep, rolesToSearch)
  }

  private async _getCoupledNavigationButtonsFromPreset(
    formConnection: ComponentConnectionItem,
    rolesToSearch: string[],
  ): Promise<ComponentStructure[]> {
    const presetStructure = await this._getPreset(formConnection)
    if (!presetStructure) {
      return []
    }
    const allSteps = _.get(presetStructure, 'components', [])
    const [leftButton, rightButton] = this._getCoupledNavigationButtonsFromSteps(
      allSteps,
      rolesToSearch,
    )

    if (leftButton && rightButton) {
      return [leftButton, rightButton]
    }

    // If we're here - we need prev & next buttons, but they don't exist together in preset
    const [previousButton, submitButton] = this._getCoupledNavigationButtonsFromLastStep(allSteps)
    const nextButton = getComponentByRole(presetStructure, ROLE_NEXT_BUTTON)

    const positionedNextButton = _.assign({}, nextButton, {
      layout: _.get(submitButton, 'layout'),
    })

    if (previousButton && nextButton) {
      return [previousButton, positionedNextButton]
    }

    // If wer'e here - we don't have the buttons in the preset
    return this._getNavigationButtonsFromPreset(formConnection, rolesToSearch, presetStructure)
  }

  private _getNavigationButtonsFromStep(
    stepDefinition: ComponentStructure,
    rolesToSearch: string[],
  ) {
    return rolesToSearch.map((role) => getComponentByRole(stepDefinition, role))
  }

  private async _getNavigationButtons(
    multiStepDefinition: ComponentStructure,
    rolesToSearch: string[],
  ): Promise<ComponentStructure[]> {
    const buttons = this._getNavigationButtonsFromStep(multiStepDefinition, rolesToSearch)
    if (buttons.filter(_.isNil).length) {
      const formConnection = findPrimaryConnection(multiStepDefinition)
      const buttonsFromPreset = await this._getNavigationButtonsFromPreset(
        formConnection,
        rolesToSearch,
      )
      return buttons.map((button, index) => button || buttonsFromPreset[index])
    } else {
      return buttons
    }
  }

  private async _getNavigationButtonsForNewStep(
    currentStepDefinition: ComponentStructure,
    formConnection: ComponentConnectionItem,
    isLastStep: boolean,
    stepHeight: number,
  ): Promise<{
    leftButton: ComponentStructure
    rightButton: ComponentStructure
  }> {
    const rolesToSearch = [ROLE_PREVIOUS_BUTTON, isLastStep ? ROLE_SUBMIT_BUTTON : ROLE_NEXT_BUTTON]

    const [leftButtonStep, rightButtonStep] = this._getNavigationButtonsFromStep(
      currentStepDefinition,
      rolesToSearch,
    )
    if (leftButtonStep && rightButtonStep) {
      return {
        leftButton: leftButtonStep,
        rightButton: rightButtonStep,
      }
    }

    const [ leftButtonFromPreset, rightButtonFromPreset ] =
      await this._getCoupledNavigationButtonsFromPreset(formConnection, rolesToSearch)

    return {
      rightButton: limitComponentInContainer(rightButtonFromPreset, stepHeight),
      leftButton: limitComponentInContainer(leftButtonFromPreset, stepHeight),
    }
  }

  private async _createNewStepStructure(
    currentStepDefinition: ComponentStructure,
    formConnection: ComponentConnectionItem,
    isLastStep: boolean,
  ): Promise<ComponentStructure> {
    const { leftButton, rightButton } = await this._getNavigationButtonsForNewStep(
      currentStepDefinition,
      formConnection,
      isLastStep,
      currentStepDefinition.layout.height,
    )
    const stepWithNewName = connectComponentToConnection(currentStepDefinition, {
      role: STEP_ROLE,
      config: { title: translations.t('multiStepForm.newStepName') },
      controllerId: formConnection.controllerId,
    })

    return {
      ...stepWithNewName,
      components: [leftButton, rightButton],
    }
  }

  private async _createDuplicatedStepStructure(
    stepsContainerRef,
    currentStepDefinition: ComponentStructure,
  ): Promise<ComponentStructure> {
    const stepComponents = await this._duplicateStepComponents(
      stepsContainerRef,
      currentStepDefinition,
    )
    const stepTitle = translations.t('multiStepForm.duplicatedStepName', {
      name: getStepTitle(currentStepDefinition),
    })
    const stepConnection = findPrimaryConnection(currentStepDefinition)

    const stepConfig: ComponentConfig = JSON.parse(stepConnection.config)
    _.set(stepConfig, 'title', stepTitle)

    stepConnection.config = JSON.stringify(stepConfig)

    return {
      ...currentStepDefinition,
      components: stepComponents,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.CHANGE_BUTTON_LABEL].LABEL_FLUSHED })
  public async updateStepsButtonLabel(stateBoxRef, role, buttonLabel, _biData = {}) {
    const steps: ComponentRef[] = await this.boundEditorSDK.components.getChildren({
      componentRef: stateBoxRef,
    })

    const buttons = _.flatten(
      await Promise.all(
        _.map(steps, (stepRef) => this.coreApi.findChildComponentsByRole(stepRef, role)),
      ),
    )

    return Promise.all(
      _.map(buttons, (buttonRef) =>
        this.boundEditorSDK.components.data.update({
          componentRef: buttonRef,
          data: { label: buttonLabel },
        }),
      ),
    )
  }

  private async _getNewStepIndexData(
    steps: StepData[],
    currentStepIndex: number,
  ): Promise<{ stepIndex: number; stepToDuplicateIndex: number; isLastStep: boolean }> {
    const lastStepAfterAddingStep = getNonMessagesStepCount(steps)
    const lastRegularStepIndex = lastStepAfterAddingStep - 1

    const stepIndex =
      currentStepIndex > lastRegularStepIndex ? lastStepAfterAddingStep : currentStepIndex + 1

    const isLastStep = stepIndex === lastStepAfterAddingStep
    return {
      stepIndex,
      stepToDuplicateIndex: stepIndex - 1,
      isLastStep,
    }
  }

  private async _getButtonStructureByRole(
    existingButtonRef: ComponentRef,
    buttonRole: string,
    formConnection: ComponentConnectionItem,
    coords: { x: number; y: number } | {},
  ): Promise<ComponentStructure> {
    let baseStructure
    if (existingButtonRef) {
      baseStructure = await this.boundEditorSDK.components.serialize({
        componentRef: existingButtonRef,
      })
    } else {
      const [buttonFromPreset] = await this._getNavigationButtonsFromPreset(formConnection, [
        buttonRole,
      ])
      baseStructure = buttonFromPreset
    }

    return _.merge({}, baseStructure, { layout: coords })
  }

  private async _makeRegularStepFirstStep(stepRef: ComponentRef): Promise<void> {
    const [prevButtonRef] = await this.coreApi.findChildComponentsByRole(
      stepRef,
      ROLE_PREVIOUS_BUTTON,
    )

    if (prevButtonRef) {
      return this.boundEditorSDK.components.remove({ componentRef: prevButtonRef })
    }
  }

  private async _convertStepButton(
    stepRef: ComponentRef,
    formConnection: ComponentConnectionItem,
    { currentButtonRole, newButtonRole },
  ): Promise<void> {
    const [[currentButton], buttonToCopy] = await Promise.all([
      this.coreApi.findChildComponentsByRole(stepRef, currentButtonRole),
      this.coreApi.findComponentByRole(stepRef, newButtonRole),
    ])

    let newButtonCoords = {}
    if (currentButton) {
      const { x, y } = await this.boundEditorSDK.components.layout.get({
        componentRef: currentButton,
      })
      await this.boundEditorSDK.components.remove({ componentRef: currentButton })
      newButtonCoords = { x, y }
    }

    const newButtonStructure = await this._getButtonStructureByRole(
      buttonToCopy,
      newButtonRole,
      formConnection,
      newButtonCoords,
    )

    if (_.isEmpty(newButtonCoords)) {
      const { x, y, extraHeightToStep } = await this._calcButtonLayout(
        stepRef,
        newButtonRole,
        newButtonStructure,
      )
      _.merge(newButtonStructure, { layout: { x, y } })
      if (extraHeightToStep) {
        this.coreApi.layout.addHeightToContainerIfFieldCrossedLimit(stepRef, extraHeightToStep, y)
      }
    }

    await this.boundEditorSDK.components.add({
      componentDefinition: newButtonStructure as any,
      pageRef: stepRef,
    })
  }

  private async _makeRegularStepLastStep(
    stepRef: ComponentRef,
    formConnection: ComponentConnectionItem,
  ): Promise<void> {
    await this._convertStepButton(stepRef, formConnection, {
      currentButtonRole: ROLE_NEXT_BUTTON,
      newButtonRole: ROLE_SUBMIT_BUTTON,
    })
  }

  private async _makeLastStepRegularStep(
    lastStepRef: ComponentRef,
    formConnection: ComponentConnectionItem,
  ): Promise<void> {
    await this._convertStepButton(lastStepRef, formConnection, {
      currentButtonRole: ROLE_SUBMIT_BUTTON,
      newButtonRole: ROLE_NEXT_BUTTON,
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.MANAGE_STEPS].ADD_STEP })
  public async addNewStep(
    stepsContainerRef: ComponentRef,
    stepsData: StepData[],
    _biData = {},
  ): Promise<{ stepsData: StepData[]; selectedIndex: number }> {
    const [{ currentIndex }, currentMultiStepDefinition] = await Promise.all([
      this.boundEditorSDK.components.behaviors.getRuntimeState({
        componentRef: stepsContainerRef,
      }) as Promise<any>,
      this.boundEditorSDK.components.serialize({
        componentRef: stepsContainerRef,
      }),
    ])
    const { stepIndex, stepToDuplicateIndex, isLastStep } = await this._getNewStepIndexData(
      stepsData,
      currentIndex,
    )
    const formConnection = findPrimaryConnection(currentMultiStepDefinition)
    const stepStructure = await this._createNewStepStructure(
      currentMultiStepDefinition.components[stepToDuplicateIndex],
      formConnection,
      isLastStep,
    )
    await this.boundEditorSDK.components.add({
      pageRef: stepsContainerRef,
      componentDefinition: stepStructure as any,
      optionalIndex: stepIndex,
    })

    await this.updateMultiStepFormTitles(stepsContainerRef)

    await Promise.all([
      this.selectStep(stepsContainerRef, stepIndex),
      isLastStep
        ? this._makeLastStepRegularStep(
            stepsData[stepToDuplicateIndex].componentRef,
            formConnection,
          )
        : Promise.resolve(),
    ])

    const updatedSteps = await this.getSteps(stepsContainerRef)

    await this._updateConnectionConfigStepsOrder(stepsContainerRef, updatedSteps)

    return { stepsData: updatedSteps, selectedIndex: stepIndex }
  }

  private _updateConnectionConfigStepsOrder(
    stepsContainerRef: ComponentRef,
    updatedSteps: StepData[],
  ) {
    const stepsIds = _.chain(updatedSteps).map('componentRef.id').compact().value()
    return this.coreApi.setComponentConnection(
      stepsContainerRef,
      { stepsOrderIds: stepsIds },
      false,
    )
  }

  public async updateConnectionConfigStepsOrder(stepsContainerRef: ComponentRef): Promise<void> {
    const steps = await this.getSteps(stepsContainerRef)
    await this._updateConnectionConfigStepsOrder(stepsContainerRef, steps)
  }

  private async _calcButtonLayout(
    stepRef: ComponentRef,
    buttonRole: string,
    buttonStructure: ComponentStructure,
  ): Promise<{ x: number; y: number; extraHeightToStep: number }> {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(stepRef, null, true)
    let buttonY
    if (!_.isEmpty(childLayouts)) {
      const navigationButton = _.find(childLayouts, (element) => isNavigationButton(element.role))
      if (navigationButton) {
        buttonY = navigationButton.y
      } else {
        const lastLayout = _.maxBy(childLayouts, (field: any) => field.y + field.height)
        buttonY = lastLayout.y + lastLayout.height + SPACE_BETWEEN_FIELDS
      }
    } else {
      buttonY = SPACE_BETWEEN_FIELDS
    }
    const stepLayout = await this.boundEditorSDK.components.layout.get({ componentRef: stepRef })
    const heightLeftInStep = stepLayout.height - (buttonY + buttonStructure.layout.height)
    const extraHeightToStep = heightLeftInStep < 0 ? -heightLeftInStep : 0

    if (_.eq(buttonRole, ROLE_PREVIOUS_BUTTON)) {
      return { x: MULTI_STEP_BUTTON_SIDE_MARGIN, y: buttonY, extraHeightToStep }
    }

    if (_.eq(buttonRole, ROLE_NEXT_BUTTON) || _.eq(buttonRole, ROLE_SUBMIT_BUTTON)) {
      return {
        x: Math.max(
          stepLayout.width - MULTI_STEP_BUTTON_SIDE_MARGIN - buttonStructure.layout.width,
          0,
        ),
        y: buttonY,
        extraHeightToStep,
      }
    }

    return null
  }

  private async _getDuplicatedFieldStructure(
    fieldDefinition: ComponentStructure,
    fieldConnection: ComponentConnectionItem,
    fieldLabelsForDuplication: Partial<FormField>[],
  ): Promise<ComponentStructure> {
    if (
      fieldConnection.role === FIELDS.ROLE_FIELD_SUBSCRIBE ||
      fieldConnection.role === FIELDS.ROLE_FIELD_RECAPTCHA
    ) {
      return Promise.resolve(null)
    }

    const fieldConfig: ComponentConfig = JSON.parse(fieldConnection.config)
    const updatedConfig = _.first(
      getDuplicatedFieldsConfig(fieldLabelsForDuplication, [fieldConfig]),
    )

    fieldConnection.config = JSON.stringify(updatedConfig)

    return fieldDefinition
  }

  private async _duplicateStepComponents(
    stepsContainerRef: ComponentRef,
    currentStepDefinition: ComponentStructure,
  ): Promise<ComponentStructure[]> {
    const allFields: FormField[] = await this.coreApi.fields.getFieldsSortByXY(stepsContainerRef)
    const fieldLabelsForDuplication: Partial<FormField>[] = getFieldsLabelsForDuplication(allFields)
    const fieldsForCollection = []
    const duplicatedComponents = []

    await currentStepDefinition.components.reduce(async (previousPromise, componentDefinition) => {
      await previousPromise
      const compConnection = findPrimaryConnection(componentDefinition)
      if (compConnection && isInputField(compConnection.role)) {
        const fieldStructure = await this._getDuplicatedFieldStructure(
          componentDefinition,
          compConnection,
          fieldLabelsForDuplication,
        )

        if (fieldStructure) {
          fieldsForCollection.push(fieldStructure)
          fieldLabelsForDuplication.push(getFieldLabelForDuplication(fieldStructure))
          duplicatedComponents.push(fieldStructure)
        }
      } else {
        duplicatedComponents.push(componentDefinition)
      }
    }, Promise.resolve())

    if (!_.isEmpty(fieldsForCollection)) {
      await this._addDuplicatedFieldsToCollection(stepsContainerRef, fieldsForCollection)
    }
    return duplicatedComponents.filter((comp) => !!comp)
  }

  private async _addDuplicatedFieldsToCollection(stepsContainerRef, fieldsDefinitions) {
    const stepsContainerConnection = await this.coreApi.getComponentConnection(stepsContainerRef)
    const validCollectionId = await this.coreApi.getValidCollectionId({
      componentRef: stepsContainerRef,
      collectionId: _.get(stepsContainerConnection, 'config.collectionId'),
    })

    const fieldsData = fieldsDefinitions.map((fieldDef) => {
      const fieldConnection = findPrimaryConnection(fieldDef)
      return _.assign({}, JSON.parse(fieldConnection.config), { role: fieldConnection.role })
    })

    return this.coreApi.collectionsApi.addFieldsToCollection(validCollectionId, fieldsData)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.MANAGE_STEPS].EDIT_STEP })
  public async duplicateStep(
    stepsContainerRef: ComponentRef,
    stepsData: StepData[],
    currentIndex: number,
    _biData = {},
  ): Promise<StepData[]> {
    const duplicatedStepIndex = currentIndex + 1
    const currentStepRef = stepsData[currentIndex].componentRef
    const currentStepStructure = await this.boundEditorSDK.components.serialize({
      componentRef: currentStepRef,
    })

    const duplicatedStepStructure = await this._createDuplicatedStepStructure(
      stepsContainerRef,
      currentStepStructure,
    )

    const addedStepRef = await this.boundEditorSDK.components.add({
      pageRef: stepsContainerRef,
      componentDefinition: duplicatedStepStructure as any,
      optionalIndex: duplicatedStepIndex,
    })

    await Promise.all([
      this.updateMultiStepFormTitles(stepsContainerRef),
      isFirstStep(stepsData, currentIndex)
        ? this._restoreNavigationButtonForStep({
            formComponentRef: stepsContainerRef,
            stepComponentRef: addedStepRef,
            fallbackSchema: previousButtonStructure,
            role: ROLE_PREVIOUS_BUTTON,
          })
        : Promise.resolve(),
      isStepIsLast(stepsData, currentIndex)
        ? this._makeLastStepRegularStep(
            currentStepRef,
            await this._getFormConnectionItem(stepsContainerRef),
          )
        : Promise.resolve(),
    ])

    await Promise.all([
      this.selectStep(stepsContainerRef, duplicatedStepIndex),
      isFirstStep(stepsData, currentIndex)
        ? this.coreApi.fields.reLayoutPreviousButton(addedStepRef)
        : Promise.resolve(),
    ])

    const updatedSteps = await this.getSteps(stepsContainerRef)

    await this._updateConnectionConfigStepsOrder(stepsContainerRef, updatedSteps)

    return updatedSteps
  }

  public async removeThankYouStep(stepsContainerRef: ComponentRef) {
    const [thankYouStepRef] = await this.coreApi.findChildComponentsByRole(
      stepsContainerRef,
      THANK_YOU_STEP_ROLE,
    )
    const [steps, currentIndex] = await Promise.all([
      this.getSteps(stepsContainerRef),
      this.getCurrentStateIndex(stepsContainerRef),
    ])

    await this._setMessageStepByFormRef(stepsContainerRef, thankYouStepRef, THANK_YOU_STEP_ROLE)
    await this.coreApi.removeComponentRef(thankYouStepRef)

    const thankYouIndex = getMesssageStepIndexOf(steps, THANK_YOU_STEP_ROLE)

    if (thankYouStepRef && currentIndex === thankYouIndex) {
      await this.selectStep(stepsContainerRef, thankYouIndex - 1)
    }

    return this.updateMultiStepFormTitles(stepsContainerRef)
  }

  public async removeLimitStep(stepsContainerRef: ComponentRef) {
    const [limitStepRef] = await this.coreApi.findChildComponentsByRole(
      stepsContainerRef,
      LIMIT_SUBMISSIONS_STEP_ROLE,
    )

    const [steps, currentIndex] = await Promise.all([
      this.getSteps(stepsContainerRef),
      this.getCurrentStateIndex(stepsContainerRef),
    ])
    await this._setMessageStepByFormRef(
      stepsContainerRef,
      limitStepRef,
      LIMIT_SUBMISSIONS_STEP_ROLE,
    )
    await this.coreApi.removeComponentRef(limitStepRef)

    const moveToStepIndex = _.max([getNonMessagesStepCount(steps) - 1, 0])
    if (limitStepRef && currentIndex === steps.length - 1) {
      await this.selectStep(stepsContainerRef, moveToStepIndex)
    }

    return this.updateMultiStepFormTitles(stepsContainerRef)
  }

  public async restoreThankYouStep(
    stepsContainerRef: ComponentRef,
    newMessage,
    role = ROLE_MESSAGE,
  ) {
    const { controllerRef, config } = await this.coreApi.getComponentConnection(stepsContainerRef)
    const restoreMessage = (roleToBeRestored) => {
      return roleToBeRestored === ROLE_MESSAGE
        ? this.coreApi.fields.restoreHiddenMessage(stepsContainerRef, newMessage)
        : this.coreApi.fields.restoreDownloadDocumentMessage(stepsContainerRef, newMessage)
    }
    let cachedThankYouStepSchema = this._getMessageStepByFormRef(
      stepsContainerRef,
      THANK_YOU_STEP_ROLE,
    )
    if (cachedThankYouStepSchema) {
      await this.boundEditorSDK.components.add({
        pageRef: stepsContainerRef,
        componentDefinition: cachedThankYouStepSchema,
      })
    } else {
      const preset = _.get(config, 'preset')
      const locale = await this.boundEditorSDK.info.getLanguage()
      cachedThankYouStepSchema = await fetchThankYouStepSchema(this.ravenInstance)({
        preset,
        locale,
      })
      await this.coreApi.addComponentAndConnect(
        {
          data: cachedThankYouStepSchema,
          role: THANK_YOU_STEP_ROLE,
          connectionConfig: cachedThankYouStepSchema.config,
        },
        controllerRef,
        stepsContainerRef,
      )
    }
    await restoreMessage(role)
    const updatedSteps: StepData[] = await this.getSteps(stepsContainerRef)

    let thankYouStepIndex = getMesssageStepIndexOf(updatedSteps, THANK_YOU_STEP_ROLE)
    const limitStepIndex = getMesssageStepIndexOf(updatedSteps, LIMIT_SUBMISSIONS_STEP_ROLE)

    if (thankYouStepIndex > limitStepIndex) {
      await this._changeStepsOrder(updatedSteps, thankYouStepIndex, limitStepIndex)
      thankYouStepIndex = limitStepIndex
    }

    return Promise.all([
      this.updateMultiStepFormTitles(stepsContainerRef),
      this.selectStep(stepsContainerRef, thankYouStepIndex),
    ])
  }

  public async restoreLimitStep(stepsContainerRef: ComponentRef, newMessage) {
    const [limitStep] = await this.coreApi.findChildComponentsByRole(
      stepsContainerRef,
      LIMIT_SUBMISSIONS_STEP_ROLE,
    )
    if (!limitStep) {
      const { controllerRef, config } = await this.coreApi.getComponentConnection(stepsContainerRef)

      const cachedlimitStepSchema = this._getMessageStepByFormRef(
        stepsContainerRef,
        LIMIT_SUBMISSIONS_STEP_ROLE,
      )

      if (cachedlimitStepSchema) {
        await this.boundEditorSDK.components.add({
          pageRef: stepsContainerRef,
          componentDefinition: cachedlimitStepSchema,
        })
      } else {
        const preset = _.get(config, 'preset')
        const locale = await this.boundEditorSDK.info.getLanguage()
        let baseSchema = await fetchThankYouStepSchema(this.ravenInstance)({
          preset,
          locale,
        })

        baseSchema = {
          ...baseSchema,
          config: {
            ...baseSchema.config,
            title: translations.t('multiStepForm.formSubmissionsLimitStepName'),
          },
          role: LIMIT_SUBMISSIONS_STEP_ROLE,
        }

        await this.coreApi.addComponentAndConnect(
          {
            data: baseSchema,
            role: LIMIT_SUBMISSIONS_STEP_ROLE,
            connectionConfig: baseSchema.config,
          },
          controllerRef,
          stepsContainerRef,
        )
      }
    }
    await this.coreApi.fields.restoreLimitMessage(stepsContainerRef, { newMessage })

    const updatedSteps: StepData[] = await this.getSteps(stepsContainerRef)
    return Promise.all([
      this.updateMultiStepFormTitles(stepsContainerRef),
      this.selectStep(stepsContainerRef, updatedSteps.length - 1),
    ])
  }

  public registerOnStateChanged(panelToken: string, callback: (payload: any) => void): void {
    this.coreApi.managePanels.registerPanelEvent({
      panelToken,
      eventName: PanelEventName.STATEBOX_STATE_CHANGED,
      callback,
    })
  }

  public async onStateChanged({ componentRef }: { componentRef: ComponentRef }): Promise<void> {
    const selectedIndex = await this.getCurrentStateIndex(componentRef)
    this.coreApi.managePanels.triggerPanelEvent(PanelEventName.STATEBOX_STATE_CHANGED, {
      selectedIndex,
    })
  }
}
