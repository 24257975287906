import { get } from 'lodash';

import { DISABLE_POST_COMMENTS_SUCCESS } from '../../../comments/actions/disable-post-comments-types';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../../../comments/actions/enable-post-comments-types';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../../post-page/actions/fetch-recent-posts';
import { FETCH_SEARCH_SUCCESS } from '../../../search/actions/fetch-search-types';
import { POST_PUBLISHED } from '../../actions/blog-socket';
import { CLEAR_POSTS } from '../../actions/clear-posts';
import { CREATE_POST_DRAFT_SUCCESS } from '../../actions/create-post-draft-types';
import { DELETE_POST_SUCCESS } from '../../actions/delete-post';
import { FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_HASHTAG_POSTS_SUCCESS } from '../../actions/fetch-hashtag-posts';
import { FETCH_POST_SUCCESS } from '../../actions/fetch-post';
import { FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { PIN_POST_SUCCESS } from '../../actions/pin-post-types';
import { PUBLISH_POST_SUCCESS } from '../../actions/publish-post-types';
import { SAVE_POST_DRAFT_SUCCESS } from '../../actions/save-post-draft-types';
import { SET_POSTS } from '../../actions/set-posts';
import {
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_FAILURE,
} from '../../actions/subscribe-types';
import { UNPIN_POST_SUCCESS } from '../../actions/unpin-post-types';
import {
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_FAILURE,
} from '../../actions/unsubscribe-types';
import { UPDATE_POST_SUCCESS } from '../../actions/update-post';
import { SET_USER } from '../auth/set-user';

const unionPosts = (oldPosts, newPosts, key = '_id') =>
  newPosts.reduce(
    (result, post) => {
      result[post[key]] = post;
      return result;
    },
    { ...oldPosts },
  );

// eslint-disable-next-line complexity
const post = (state, action, key) => {
  switch (action.type) {
    case SET_USER:
      if (state.owner.siteMemberId === action.payload.siteMemberId) {
        return { ...state, owner: { ...state.owner, ...action.payload } };
      }
      return state;
    case SUBSCRIBE_REQUEST:
      if (state[key] === action.payload[key]) {
        return { ...state, isSubscribed: true };
      }
      return state;
    case SUBSCRIBE_FAILURE:
      if (state[key] === action.payload[key]) {
        return { ...state, isSubscribed: false };
      }
      return state;
    case UNSUBSCRIBE_REQUEST:
      if (state[key] === action.payload[key]) {
        return { ...state, isSubscribed: false };
      }
      return state;
    case UNSUBSCRIBE_FAILURE:
      if (state[key] === action.payload[key]) {
        return { ...state, isSubscribed: true };
      }
      return state;
    default:
      return state;
  }
};

// eslint-disable-next-line complexity
const posts =
  (key) =>
  (state = {}, { type, payload } = {}) => {
    switch (type) {
      case CLEAR_POSTS:
        return {};
      case SET_POSTS:
        return unionPosts({}, payload, key);
      case FETCH_POST_SUCCESS:
        return unionPosts(state, [payload.post], key);
      case FETCH_FEED_POSTS_SUCCESS:
      case FETCH_RECENT_POSTS_SUCCESS:
      case FETCH_CATEGORY_POSTS_SUCCESS:
      case FETCH_ARCHIVE_SUCCESS:
      case FETCH_HASHTAG_POSTS_SUCCESS:
      case FETCH_TAG_POSTS_SUCCESS:
        return unionPosts(state, payload, key);
      case UPDATE_POST_SUCCESS:
      case PIN_POST_SUCCESS:
      case UNPIN_POST_SUCCESS:
      case ENABLE_POST_COMMENTS_SUCCESS:
      case DISABLE_POST_COMMENTS_SUCCESS:
        return unionPosts(state, [payload], key);
      case POST_PUBLISHED:
      case CREATE_POST_DRAFT_SUCCESS:
      case PUBLISH_POST_SUCCESS:
        return unionPosts(state, [payload], key);

      case SAVE_POST_DRAFT_SUCCESS:
        return unionPosts(state, [get(payload, 'post')], key);

      case FETCH_SEARCH_SUCCESS:
        return unionPosts(
          state,
          payload.posts.filter((post) => post.type === 'post'),
          key,
        );
      case DELETE_POST_SUCCESS: {
        const idKey = payload?.length > 24 ? 'id' : '_id';
        return Object.keys(state).reduce((result, postId) => {
          const current = state[postId];
          if (current[idKey] !== payload) {
            result[postId] = current;
          }
          return result;
        }, {});
      }
      case SUBSCRIBE_REQUEST:
      case SUBSCRIBE_FAILURE:
      case UNSUBSCRIBE_REQUEST:
      case UNSUBSCRIBE_FAILURE:
        if (payload.type === 'post') {
          return Object.keys(state).reduce((result, current) => {
            result[current] = post(state[current], { type, payload }, key);
            return result;
          }, {});
        }
        return state;
      case SET_USER:
        return Object.keys(state).reduce((result, current) => {
          result[current] = post(state[current], { type, payload }, key);
          return result;
        }, {});
      default:
        return state;
    }
  };

export default posts('_id');

export const postsByStableId = posts('id');
