import React from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

const handleEventNoop = (ev) => {
  ev.stopPropagation();
  ev.preventDefault();
};

const NoopLink = ({ children, ...props }) => {
  const elementProps = omit(props, ['user', 'tReady', 'lng']);
  return (
    <span
      style={{ pointerEvents: 'none' }}
      {...elementProps}
      onClick={handleEventNoop}
    >
      {children}
    </span>
  );
};

NoopLink.propTypes = {
  children: PropTypes.node,
};

export default NoopLink;
