import React from 'react';
import { trimEnd } from 'lodash';
import PropTypes from 'prop-types';
import { getBaseUrl } from '../../store/topology/topology-selectors';
import { connect } from '../runtime-context';

const SiteLink = ({ to, baseUrl, children, ...props }) => (
  <a href={`${trimEnd(baseUrl, '/')}${to}`} {...props}>
    {children}
  </a>
);

SiteLink.propTypes = {
  to: PropTypes.string.isRequired,
  baseUrl: PropTypes.string,
};

const mapRuntimeToProps = (state) => ({
  baseUrl: getBaseUrl(state),
});

export default connect(mapRuntimeToProps)(SiteLink);
