import React from 'react';
import { flowRight, get, pick } from 'lodash';
import PropTypes from 'prop-types';
import App from '../../../common/components/app';
import CategoriesLink from '../../../common/components/categories-link';
import CategoryDesktopHeader from '../../../common/components/category-desktop-header';
import CategoryMobileHeader from '../../../common/components/category-mobile-header';
import PopoverRoot from '../../../common/components/popovers/popover-root';
import { connect } from '../../../common/components/runtime-context';
import LoginPage from '../../../common/containers/login-page';
import NotFoundPage from '../../../common/containers/not-found-page';
import { getCurrentMatch } from '../../../common/router/router-selectors';
import MessageRoot from '../../components/messages/message-root';
import ModalRoot from '../../components/modals/modal-root';
import {
  ROUTE_404,
  ROUTE_LOGIN,
  ROUTE_CREATE_POST,
  ROUTE_EDIT_POST,
  ROUTE_PREVIEW_POST,
  ROUTE_EDIT_COMMENT,
  ROUTE_POST,
  ROUTE_LAST_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
} from '../../constants/routes';

import LastPostPage from '../last-post-page';
import PostEditorPageOrModal from '../post-editor-page-or-modal';
import PostComponent from '../post-page';

const PostCommentEditComponent = () => {
  return <div>Edit comment</div>;
};

const WrapComponent = (Component) => (props) =>
  (
    <App
      main={<Component params={props.params} />}
      modalRoot={<ModalRoot />}
      messageRoot={<MessageRoot />}
      popoverRoot={<PopoverRoot />}
      mobileHeader={<CategoryMobileHeader />}
      mobileMenu={<CategoriesLink />}
      desktopHeader={
        <CategoryDesktopHeader postSlug={get(props, 'params.postSlug', null)} />
      }
    />
  );

const PostEditor = WrapComponent(PostEditorPageOrModal);

const ROUTE_COMPONENT_MAP = {
  [ROUTE_404]: WrapComponent(NotFoundPage),
  [ROUTE_LOGIN]: WrapComponent(LoginPage),
  [ROUTE_CREATE_POST]: PostEditor,
  [ROUTE_EDIT_POST]: PostEditor,
  [ROUTE_PREVIEW_POST]: WrapComponent(PostComponent),
  [ROUTE_EDIT_COMMENT]: PostCommentEditComponent,
  [ROUTE_EDIT_COMMENT_ADVANCED_SLUG]: PostCommentEditComponent,
  [ROUTE_POST]: WrapComponent(PostComponent),
  [ROUTE_POST_ADVANCED_SLUG]: WrapComponent(PostComponent),
  [ROUTE_LAST_POST]: WrapComponent(LastPostPage),
};

const Router = ({ route, ...rest }) => {
  let Component = ROUTE_COMPONENT_MAP[route];

  if (!Component) {
    Component = () => <div>Route "{route}" - is not implemented</div>;
  }

  return <Component {...rest} />;
};

Router.propTypes = {
  route: PropTypes.string,
  pathname: PropTypes.string,
  sectionUrl: PropTypes.string,
  params: PropTypes.object,
};

const mapRuntimeToProps = (state) => ({
  ...pick(getCurrentMatch(state), [
    'route',
    'pathname',
    'sectionUrl',
    'params',
  ]),
});

export default flowRight(connect(mapRuntimeToProps))(Router);
