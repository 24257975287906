import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import AuthorWithPostDetails from '../../../post-page/components/author-with-post-details';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import Avatar from '../avatar';
import ProfileLink from '../link/profile-link';
import styles from './post-page-metadata-mobile.scss';

const PostPageMetadataMobile = ({
  post,
  isMetadataHeaderVisible,
  showAuthorName,
  showPublishDate,
  showReadingTime,
  showAuthorBadge,
  showAuthorPicture,
}) => (
  <div className={styles.container}>
    {showAuthorName && showAuthorPicture && (
      <ProfileLink user={post.owner}>
        <Avatar user={post.owner} isNameVisible={false} />
      </ProfileLink>
    )}
    {isMetadataHeaderVisible && (
      <AuthorWithPostDetails
        post={post}
        showAuthorName={showAuthorName}
        showPublishDate={showPublishDate}
        showReadingTime={showReadingTime}
        showAuthorBadge={showAuthorBadge}
      />
    )}
  </div>
);

PostPageMetadataMobile.propTypes = {
  post: PropTypes.object.isRequired,
  isMetadataHeaderVisible: PropTypes.bool,
  showAuthorName: PropTypes.bool,
  showAuthorBadge: PropTypes.bool,
  showPublishDate: PropTypes.bool,
  showReadingTime: PropTypes.bool,
  showAuthorPicture: PropTypes.bool,
};

export default flowRight(withFeedMetadataSettings)(PostPageMetadataMobile);
