import { __assign } from "tslib";
import { get, set, trimEnd } from 'lodash';
import { EXPERIMENT_TOGGLE_POST_INDEX_SEO_SETTINGS } from '@wix/communities-blog-experiments';
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import { isExperimentEnabled } from '../selectors';
import { getPostExcerpt, getPostCover } from '../selectors/post-selectors';
import { getPostSeoDescription, getPostAmpUrl, getCanonicalPostUrl, } from '../selectors/post-seo-selectors';
import { getMainCategorySeoLabel, getMainCategoryDisplayLabel, } from '../store/categories/categories-selectors';
import { capitalizeLanguageCode } from './capitalize-language-code';
import { getImageUrl } from './image-utils';
export var getFullLanguageCode = function (siteLanguages, language) {
    var _a;
    if (siteLanguages === void 0) { siteLanguages = []; }
    if (language === void 0) { language = 'en'; }
    var langCode = ((_a = siteLanguages.find(function (siteLang) { return siteLang.languageCode === language; })) === null || _a === void 0 ? void 0 : _a.seoLang) || language;
    return capitalizeLanguageCode(langCode);
};
var getPostFlattenedCategories = function (post, categoryMap) {
    return post.categoryIds
        .map(function (id) { return categoryMap[id]; })
        .filter(Boolean)
        .map(function (_a) {
        var menuLabel = _a.menuLabel;
        return menuLabel;
    })
        .join(', ');
};
var mapTranslationData = function (translation, siteLanguages) {
    var langCode = getFullLanguageCode(siteLanguages, translation.language);
    return {
        langCode: langCode,
        url: "".concat(translation.url.base).concat(translation.url.path),
        seoData: translation.seoData,
    };
};
var getTranslatedPagesData = function (translations, siteLanguages) {
    if (siteLanguages === void 0) { siteLanguages = []; }
    return translations
        .filter(function (translation) { return translation.status === POST_STATUS.published; })
        .map(function (translation) { return mapTranslationData(translation, siteLanguages); });
};
export var generatePostPageSEOTags = function (_a) {
    var _b;
    var appConfig = _a.appConfig, post = _a.post, state = _a.state, categoriesMap = _a.categoriesMap, customPostUrl = _a.customPostUrl, mediaUrls = _a.mediaUrls, multilingual = _a.multilingual, ownerUrl = _a.ownerUrl;
    var postPageSectionUrl = appConfig.postPageSectionUrl, ampBaseUrl = appConfig.ampBaseUrl, publisher = appConfig.publisher;
    var slug = post.slug, seoDescription = post.seoDescription, title = post.title, seoTitle = post.seoTitle, lastPublishedDate = post.lastPublishedDate, firstPublishedDate = post.firstPublishedDate, owner = post.owner, _c = post.translations, translations = _c === void 0 ? [] : _c;
    var mainCategory = (_b = getMainCategorySeoLabel(state, post)) !== null && _b !== void 0 ? _b : getMainCategoryDisplayLabel(state, post);
    var itemData = {
        post: {
            id: post.id,
            title: title,
            language: getFullLanguageCode(multilingual === null || multilingual === void 0 ? void 0 : multilingual.siteLanguages, post.language),
            description: getPostSeoDescription(post),
            amphtml: getPostAmpUrl(post._id, ampBaseUrl, slug),
            type: 'article',
            canonicalUrl: getCanonicalPostUrl({
                post: post,
                postPageSectionUrl: postPageSectionUrl,
                customPostUrl: customPostUrl,
                multilingual: multilingual,
            }),
            optInMeta: 'max-snippet:-1, max-image-preview:large, max-video-preview:-1',
            slug: slug,
            excerpt: getPostExcerpt(post),
            lastPublishedDate: lastPublishedDate,
            firstPublishedDate: firstPublishedDate,
            mainCategory: mainCategory,
            categories: getPostFlattenedCategories(post, categoriesMap, state),
            owner: {
                name: owner.name,
                url: ownerUrl,
            },
            path: customPostUrl || "".concat(trimEnd(postPageSectionUrl, '/'), "/").concat(post.slug),
            contentImages: mediaUrls,
        },
        legacySeoData: {
            title: seoTitle,
            description: seoDescription,
        },
        postsSettings: {
            isAmpEnabled: Boolean(ampBaseUrl),
            isSchemaEnabled: true,
        },
        translatedPages: getTranslatedPagesData(translations, multilingual === null || multilingual === void 0 ? void 0 : multilingual.siteLanguages),
    };
    if (owner.image) {
        var ownerImage = __assign(__assign({}, owner.image), { file_name: owner.image.file_name || owner.image.id });
        set(itemData, 'post.owner.image.url', typeof owner.image === 'string'
            ? owner.image
            : getImageUrl({ image: ownerImage }));
    }
    if (publisher) {
        itemData.publisher = {
            id: publisher['@id'],
            name: publisher.name,
            logo: get(publisher, 'logo.url'),
        };
    }
    var isTogglePostIndexSEOExperimentEnabled = isExperimentEnabled(state, EXPERIMENT_TOGGLE_POST_INDEX_SEO_SETTINGS);
    var isPostShownInSearch = get(post, 'seoShowInSearch', true);
    if (isTogglePostIndexSEOExperimentEnabled) {
        if (!isPostShownInSearch) {
            itemData.post.noIndex = true;
        }
    }
    var _d = getPostCover(post), shouldRender = _d.shouldRender, imageMetadata = _d.imageMetadata, videoMetadata = _d.videoMetadata;
    if (shouldRender) {
        itemData.post.image = {
            url: imageMetadata
                ? getImageUrl({ image: imageMetadata })
                : videoMetadata.thumbnail_url,
            width: imageMetadata
                ? imageMetadata.width
                : videoMetadata.thumbnail_width,
            height: imageMetadata
                ? imageMetadata.height
                : videoMetadata.thumbnail_height,
        };
    }
    return itemData;
};
