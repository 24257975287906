import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import withExperiment from '../../hoc/with-experiment';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import styles from './change-category-trigger.scss';

const ChangeCategoryTrigger = ({
  children,
  title,
  containerBackgroundClass,
  containerFontClass,
  arrowDownIconClass,
  isMobileMenuColorMatchDesktopExperimentEnabled,
  ...props
}) => {
  containerBackgroundClass =
    isMobileMenuColorMatchDesktopExperimentEnabled && containerBackgroundClass
      ? containerBackgroundClass
      : 'blog-card-background-color';
  containerFontClass =
    isMobileMenuColorMatchDesktopExperimentEnabled && containerFontClass
      ? containerFontClass
      : 'blog-text-color';

  const className = isMobileMenuColorMatchDesktopExperimentEnabled
    ? classNames(
        styles.container,
        containerBackgroundClass,
        containerFontClass,
        'categories-button',
      )
    : classNames(
        styles.container,
        'blog-card-background-color',
        'blog-text-color',
        'categories-button',
        styles.boxShadow,
      );

  const iconFillClass =
    isMobileMenuColorMatchDesktopExperimentEnabled && arrowDownIconClass
      ? arrowDownIconClass
      : 'blog-icon-fill';

  return (
    <div className={className} {...props}>
      <div className={styles.wrapper}>
        <span data-hook="category-title" className={styles.title}>
          {title}
        </span>
        <ArrowDownIcon className={classNames(iconFillClass, styles.icon)} />
        {children}
      </div>
    </div>
  );
};

ChangeCategoryTrigger.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  containerBackgroundClass: PropTypes.string,
  containerFontClass: PropTypes.string,
  arrowDownIconClass: PropTypes.string,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
};

export default withExperiment({
  isMobileMenuColorMatchDesktopExperimentEnabled:
    EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
})(ChangeCategoryTrigger);
