import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';

import PostMetadata from '../../../post-page/components/post-metadata';
import PostMetadataLayout from '../../../post-page/components/post-metadata-layout';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import Avatar from '../avatar';
import ProfileLink from '../link/profile-link';
import styles from './post-page-metadata-desktop.scss';

const PostPageMetadataDesktop = ({
  post,
  showAuthorName,
  showAuthorBadge,
  className,
  linkClassName,
  showPublishDate,
  showReadingTime,
  showAuthorPicture,
}) => (
  <PostMetadataLayout className={classNames(styles.container, className)}>
    {showAuthorName && (
      <ProfileLink user={post.owner} className={linkClassName}>
        <Avatar
          user={post.owner}
          isImageVisible={showAuthorPicture}
          isBadgeVisible={showAuthorBadge}
        />
      </ProfileLink>
    )}
    {(showPublishDate || showReadingTime) && (
      <PostMetadata
        date={post.firstPublishedDate || new Date()}
        readTime={post.timeToRead}
        isShortReadTimeLabel={false}
        showPublishDate={showPublishDate}
        showReadingTime={showReadingTime}
      />
    )}
  </PostMetadataLayout>
);

PostPageMetadataDesktop.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  post: PropTypes.object.isRequired,
  showAuthorName: PropTypes.bool,
  showAuthorBadge: PropTypes.bool,
  showPublishDate: PropTypes.bool,
  showReadingTime: PropTypes.bool,
  showAuthorPicture: PropTypes.bool,
};

export default flowRight(withFeedMetadataSettings)(PostPageMetadataDesktop);
