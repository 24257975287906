import { LAYOUT_NAME_PG_GRID, LAYOUT_PG_GRID } from './layout-types';
import { PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS } from './pro-gallery';
export var POST_LIST_WIDGET_DEFAULTS = {
    margins: 10,
    sidesPadding: 16,
    postSize: 254,
    contentHeight: 156,
    imageRatio: 2,
    spacing: 12,
    layoutName: LAYOUT_NAME_PG_GRID,
    layoutType: LAYOUT_PG_GRID,
    layoutCardsRowType: PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.FIT_TO_SCREEN,
    layoutPostsPerRow: 3,
};
export var POST_LIST_SETTINGS_PARAMS = {
    isFeatured: {
        wixParam: 'postListWidgetIsFeatured',
        appSettingsPath: 'style.booleans.postListWidgetIsFeatured',
        defaultValue: false,
    },
    entityCount: {
        wixParam: 'postListWidgetEntityCount',
        appSettingsPath: 'style.numbers.postListWidgetEntityCount',
        defaultValue: 3,
        minValue: 1,
        maxValue: 50,
    },
    paginationType: {
        wixParam: 'postListWidgetPaginationType',
        appSettingsPath: 'style.numbers.postListWidgetPaginationType',
        defaultValue: '0',
        values: {
            NUMBER_OF_POSTS: '0',
            PAGINATED: '1',
        },
    },
    postsPerPage: {
        wixParam: 'postListWidgetPostsPerPage',
        appSettingsPath: 'style.numbers.postListWidgetPostsPerPage',
        defaultValue: 3,
        minValue: 1,
        maxValue: 50,
    },
};
export var POST_LIST_LAYOUT_PARAMS = {
    sliderLoop: {
        wixParam: 'postListWidgetSliderLoop',
        appSettingsPath: 'style.booleans.postListWidgetSliderLoop',
        defaultValue: false,
    },
    widgetBackgroundColor: {
        wixParam: 'postListWidgetBackgroundColor',
        appSettingsPath: 'style.colors.postListWidgetBackgroundColor',
        defaultValue: 'color-5',
        defaultOpacity: 0,
    },
    sliderAutoSlide: {
        wixParam: 'postListWidgetSliderAutoSlide',
        appSettingsPath: 'style.booleans.postListWidgetSliderAutoSlide',
        defaultValue: false,
    },
    sliderPauseTime: {
        wixParam: 'postListWidgetSliderPauseTime',
        appSettingsPath: 'style.numbers.postListWidgetSliderPauseTime',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'postListWidgetSliderShowArrows',
        appSettingsPath: 'style.booleans.postListWidgetSliderShowArrows',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'postListWidgetSliderArrowsPosition',
        appSettingsPath: 'style.numbers.postListWidgetSliderArrowsPosition',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'postListWidgetSliderArrowsSize',
        appSettingsPath: 'style.numbers.postListWidgetSliderArrowsSize',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'postListWidgetSliderArrowsColor',
        appSettingsPath: 'style.colors.postListWidgetSliderArrowsColor',
        defaultValue: 'color-5',
    },
};
export var POST_LIST_LAYOUT_PARAMS_MOBILE = {
    sliderLoop: {
        wixParam: 'postListWidgetSliderLoopMobile',
        appSettingsPath: 'style.booleans.postListWidgetSliderLoopMobile',
        defaultValue: false,
    },
    widgetBackgroundColor: {
        wixParam: 'postListWidgetBackgroundColorMobile',
        appSettingsPath: 'style.colors.postListWidgetBackgroundColorMobile',
        defaultValue: 'color-5',
        defaultOpacity: 0,
    },
    sliderAutoSlide: {
        defaultValue: true,
        wixParam: 'postListWidgetSliderAutoSlideMobile',
        appSettingsPath: 'style.booleans.postListWidgetSliderAutoSlideMobile',
    },
    sliderPauseTime: {
        wixParam: 'postListWidgetSliderPauseTimeMobile',
        appSettingsPath: 'style.numbers.postListWidgetSliderPauseTimeMobile',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'postListWidgetSliderShowArrowsMobile',
        appSettingsPath: 'style.booleans.postListWidgetSliderShowArrowsMobile',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'postListWidgetSliderArrowsPositionMobile',
        appSettingsPath: 'style.numbers.postListWidgetSliderArrowsPositionMobile',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'postListWidgetSliderArrowsSizeMobile',
        appSettingsPath: 'style.numbers.postListWidgetSliderArrowsSizeMobile',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'postListWidgetSliderArrowsColorMobile',
        appSettingsPath: 'style.colors.postListWidgetSliderArrowsColorMobile',
        defaultValue: 'color-5',
    },
};
