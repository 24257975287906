import type { ComponentDatabindingApiFactory } from './types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import type { AdaptedComponent } from '../inverted-dependencies/components'
import type { UoUFilterConnectionConfig } from '../types'
import { handleUserInputFilterOnChange } from './utils'

export const userInputFilterCheckboxBindingApi: ComponentDatabindingApiFactory<
  AdaptedComponent,
  UoUFilterConnectionConfig
> = (component, connectionConfig, context) => {
  const setInitialComponentValue = () => {
    component.setValue(null, { propPath: 'checked' })
  }

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),
    bind() {
      handleUserInputFilterOnChange(component, connectionConfig, context)

      setInitialComponentValue()
      return
    },

    resetUserFilter() {
      setInitialComponentValue()
    },
  }
}
