import { get, flowRight } from 'lodash';
import { CARD_BORDER_WIDTH_PATH } from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';

import { getAppSettings } from '../selectors/app-settings-base-selectors';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import withFeedBorderWidth from './with-feed-border-width';
import withHocName from './with-hoc-name';
import withIsFeedDesignEnabled from './with-is-feed-design-enabled';

const DEFAULT_WIDTH = 1;

const getPostCardBorderWidth = (state) =>
  getIsMobile(state)
    ? DEFAULT_WIDTH
    : get(getAppSettings(state), CARD_BORDER_WIDTH_PATH, DEFAULT_WIDTH);

const mapRuntimeToProps = (
  state,
  { borderWidth: feedBorderWidth, applyFeedDesign },
) => ({
  borderWidth: applyFeedDesign
    ? feedBorderWidth
    : getPostCardBorderWidth(state),
});

export default flowRight(
  withFeedBorderWidth,
  withIsFeedDesignEnabled,
  connect(mapRuntimeToProps),
  withHocName('WithPostBorderWidth'),
);
