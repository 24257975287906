import React, { Component } from 'react';
import AppLoaded from '../../components/app-loaded';
import { FullHeightLayout, SimpleLayout } from '../../components/layout';
import NotFound from '../../components/not-found';
import Page from '../../components/page';
import forDevice from '../../hoc/for-device';

const Layout = forDevice(FullHeightLayout, SimpleLayout);

export class NotFoundPage extends Component {
  render() {
    return (
      <Page noSpacing data-hook="not-found-page">
        <Layout>
          <NotFound />
        </Layout>
        <AppLoaded />
      </Page>
    );
  }
}

export default NotFoundPage;
