import React from 'react';
import classNames from 'classnames';
import { get, isObject } from 'lodash';
import PropTypes from 'prop-types';
import { getBannerStyle } from '../../services/paywall-theme';
import styles from './paywall-banner.scss';
var getTheme = function () { return ({
    headerThree: styles.title,
    text: styles.text,
    button_container: styles.button,
}); };
var none = function () { return ({}); };
var PaywallBanner = function (_a) {
    var appSettings = _a.appSettings, section = _a.section, RichContentViewer = _a.viewer, banner = _a.banner, onSubscribe = _a.onSubscribe, className = _a.className;
    var bannerStyle = getBannerStyle({ appSettings: appSettings, section: section });
    var design = get(banner, 'entityMap[0].data.button.design');
    if (isObject(design)) {
        design.background = bannerStyle['--paywall-button-color'];
        design.color = bannerStyle['--paywall-button-text-color'];
    }
    return (React.createElement("div", { className: classNames(styles.container, className), style: bannerStyle },
        React.createElement(RichContentViewer, { initialRawState: banner, parentClass: className, disableTheme: false, getTheme: getTheme, getStyle: none, onButtonClick: onSubscribe })));
};
PaywallBanner.propTypes = {
    appSettings: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    viewer: PropTypes.element.isRequired,
    banner: PropTypes.object.isRequired,
    onSubscribe: PropTypes.func,
    className: PropTypes.string,
};
export default PaywallBanner;
